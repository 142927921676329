import * as moment from 'moment';
import 'moment/locale/de'  // without this line it didn't work
moment.locale('de');

export class CaspardoDate {

	year_component_id: string = "";
	dateFormat: string = "DoMM.YYYY";
	dateValueFormat: string = "DD.MM.YYYY";
	dateLanguage: string = "de";
	currentDate: Date = null;
	numberOfYears: number = 0;
	arrDate: any = [];

	template: any = {
		year_head: '<select class="form-control" id="' + this.year_component_id + '" name="seminar_calendarweekyear">',
		year_row: '<option value="<name>"><name></option>',
		year_foot: '</select>',
		calendarweek_msgChoose: 'Kalenderwochen <year> ...',
		calendarweek_head: '<select class="form-control" name="seminar_calendarweek" id="caspardo_seminarcalendarweek_selector">',
		calendarweek_row: '<option value="<caspardo_value>"><caspardo_name></option>',
		calendarweek_foot: '</select>'
	};

	constructor(year_component_id: string) {
		this.year_component_id = year_component_id;
	}


	public getFirstLastDateOfWeek(weekNumber: number, year: number) {
		var res: any = {
			firstRealDate: null,
			firstWeekDate: null,
			lastWeekDate: null,
			value: null,
			calendarWeek: weekNumber,
			year: year
		}

		var begin = moment();
		begin.year(year).isoWeek(weekNumber).startOf('isoWeek');
		begin.locale(this.dateLanguage);

		var end = moment();
		end.year(year).isoWeek(weekNumber).endOf('isoWeek');
		end.locale(this.dateLanguage);

		res.firstRealDate = begin.toDate();
		res.firstWeekDate = begin.format(this.dateFormat);
		res.lastWeekDate = end.format(this.dateFormat);
		res.value = begin.format(this.dateValueFormat) + "-" + end.format(this.dateValueFormat);

		return res;
	}

	/**
	 * 
	 * @param arrCalendarWeeks 
	 */
	public generateResultCWSortedObjectList(arrCalendarWeeks: any) {
		var res: any = {
			firstWeekDate: [],
			lastWeekDate: [],
			calendarWeek: []
		}

		for (var i = 0; i < arrCalendarWeeks.length; i++) {
			let calendarWeekStr: string = arrCalendarWeeks[i];
			let calVals: any = calendarWeekStr.split("-");
			let startStopDate: any = this.getFirstLastDateOfWeek(calVals[0], calVals[1]);
			this.arrDate.push(startStopDate);
		}

		this.arrDate.sort(function (a, b) {
			if (a.firstRealDate > b.firstRealDate)
				return 1;
			else if (a.firstRealDate < b.firstRealDate)
				return -1;
		});

		for (var i = 0; i < this.arrDate.length; i++) {
			let startStopDate: any = this.arrDate[i];
			res.firstWeekDate.push(startStopDate.firstWeekDate);
			res.lastWeekDate.push(startStopDate.lastWeekDate);
			res.calendarWeek.push(startStopDate.calendarWeek);
		}

		return res;

	}

	/**
	 * 
	 * @param arrCalendarWeeks 
	 */
	public generateResulrArrayValueList(arrCalendarWeeks: any) {
		var res: any = [];
		var resArr: any = [];

		for (var i = 0; i < arrCalendarWeeks.length; i++) {
			let calendarWeekStr: string = arrCalendarWeeks[i];
			let calVals: any = calendarWeekStr.split("-");
			let startStopDate: any = this.getFirstLastDateOfWeek(calVals[0], calVals[1]);
			res.push(startStopDate);
		}

		res.sort(function (a, b) {
			if (a.firstRealDate > b.firstRealDate)
				return 1;
			else if (a.firstRealDate < b.firstRealDate)
				return -1;
		});

		for (var i = 0; i < res.length; i++) {
			let startStopDate: any = res[i];
			resArr.push(startStopDate.calendarWeek + "-" + startStopDate.year);

		}

		return resArr;

	}

	/**
	 * 
	 * @param arrCalendarWeeks 
	 */
	public generateResultCalendarWeeks(arrCalendarWeeks: any) {
		let res: string = this.template.calendarweek_head;
		for (var i = 0; i < arrCalendarWeeks.length; i++) {
			let calendarWeekStr: string = arrCalendarWeeks[i];
			let calVals: any = calendarWeekStr.split("-");
			let startStopDate: any = this.getFirstLastDateOfWeek(calVals[0], calVals[1]);
			this.arrDate.push(startStopDate);
		}

		this.arrDate.sort(function (a, b) {
			if (a.firstRealDate > b.firstRealDate)
				return 1;
			else if (a.firstRealDate < b.firstRealDate)
				return -1;
		});

		for (var i = 0; i < this.arrDate.length; i++) {
			let startStopDate: any = this.arrDate[i];
			let row_str: string = this.template.calendarweek_row.replace(new RegExp('<caspardo_name>', 'g'), "KW-" + startStopDate.calendarWeek + ": " + startStopDate.firstWeekDate + " - " + startStopDate.lastWeekDate);
			row_str = row_str.replace(new RegExp('<caspardo_value>', 'g'), startStopDate.value);
			res += row_str;
		}
		res += this.template.calendarweek_foot;
		return res;
	}

}