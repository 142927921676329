import { CaspardoAPI } from "../common/CaspardoAPI";

export class CaspardoCheckboxFilter {
    private _selecteditems: any = [];

    private _parent_div: string;
    private _class_name: string;  // unique id of the drop down
    private _headline: string; // Displayname of the drop down

    private _html_template: string = `<div class="container {class_name}">
    <div class="row">
        <div class="col-xs-12">
            <div>
                <button type="button" class="btn btn-block caspardo-filter-btn" data-toggle="collapse" data-target=".{class_name}-body">
                <i class="caspardo-collapseicon-{filter_key} fa fa-minus pull-right"></i> 
                <span class="caspardo-filter-btn-name pull-left">{headline}</span>
                </button>
             </div>
        </div>
  </div>
    <div class="row {class_name}-body collapse show" data-collapseicon="caspardo-collapseicon-{filter_key}">
     {items}
    </div>
  </div>`;

    // HTML-Snippet
    private _template_head_topfilter: string = `
          <div class="col-xs-12 {class_name}-groupheadline">
              <span class="{class_name}-topfilter-headline">{topname}</span>
          </div>`;

    private _template_head_filter: string = `
          <div class="col-xs-12 {class_name}-groupheadline">
              <span class="{class_name}-headline">{name}</span>
          </div>`;

    /**
     * Constructor: CaspardoCheckboxFilter     
     */
    constructor() {

    }

    public buildFilter(filters: any, filter_key: string, parent_div: string, class_name: string, headline: string, itemgroup_name: string, itemgroup_topname: string) {
        // General Data
        this._class_name = class_name;
        this._headline = headline;
        this._parent_div = parent_div;
        // Add the headline
        let insertHTML: string = this._html_template.replace(new RegExp('{class_name}', 'g'), this._class_name);
        insertHTML = insertHTML.replace(new RegExp('{headline}', 'g'), this._headline);
        insertHTML = insertHTML.replace(new RegExp('{filter_key}', 'g'), filter_key);

        // Clear the filters
        $('#' + parent_div).empty();

        let insertText: string = "";
        let insertedFiltes: Array<String> = [];

        // Process the filters
        if (filters) {
            // Get the top-filters for the filter.name
            let top_filters = CaspardoAPI.getTopFilterByKey(filter_key);
            if (top_filters.topfilters) {
                if (top_filters.topfilters.length > 0) // Top-Filters found
                {
                    let insertTopFilters=[];
                    for (var i = 0; i < top_filters.topfilters.length; i++) {
                        // Get the filter key
                        let filterName: string = top_filters.topfilters[i].key;
                        if (filters.value.length > 0) // Filters found
                        {
                            let findTopItem=filters.value.find(({name}) => name === filterName);
                            if (findTopItem) {
                                insertTopFilters.push(findTopItem);
                            }
                        }
                    }
                    if (insertTopFilters.length > 0) {
                        // Add the filter-head for the top filtes
                        let insertTopfilter: string = this._template_head_topfilter.replace(new RegExp('{topname}', 'g'), itemgroup_topname);
                        insertTopfilter = insertTopfilter.replace(new RegExp('{class_name}', 'g'), class_name);
                        insertText += insertTopfilter;

                        // Build the top-filters
                        insertText += `<div class="row"><ul class="${class_name}-ul">`;
                        for (var i = 0; i < insertTopFilters.length; i++) {
                            // Get the filter key
                            let filterName: string = insertTopFilters[i].name;
                            // Get the filtername
                            let translateName: string = CaspardoAPI.getIN18FilterName(filter_key, filterName);
                            // Create the filter
                            insertText += `<li class="col-xs-6 ${class_name}-item ${class_name}-topitem">
					        <input type="checkbox" name="${filterName}" value="${filterName}">
					        ${translateName}</li>`;
                            // Push the inserted filter
                            insertedFiltes.push(filterName);
                        }
                        insertText += '<ul></div>';
                    }
                }

                let item_filters = filters.value;
                if (item_filters.length > 0) // Filters found
                {

                    // Add the filter-head for the top filtes
                    let insertFilterHead: string = this._template_head_filter.replace(new RegExp('{name}', 'g'), itemgroup_name);
                    insertFilterHead = insertFilterHead.replace(new RegExp('{class_name}', 'g'), class_name);
                    insertText += insertFilterHead;

                    // Build the filters
                    insertText += `<div class="row"><ul class="${class_name}-ul">`;
                    for (let filter = 0; filter < item_filters.length; filter++) {
                        let filterName: string = item_filters[filter].name;
                        if (insertedFiltes.indexOf(filterName) === -1) {
                            let translateName = CaspardoAPI.getIN18FilterName(filter_key, filterName);
                            insertText += `<li class="col-xs-6 ${class_name}-item">
						<input type="checkbox" name="${item_filters[filter].type}" value="${item_filters[filter].name}">
						${translateName}</li>`;
                            insertedFiltes.push(filterName);
                        }
                    }
                    insertText += '</ul></div>';
                }
            }

            insertHTML = insertHTML.replace(new RegExp('{items}', 'g'), insertText);


            $('#' + parent_div).html(insertHTML);

            var classname = document.getElementsByClassName(class_name + "-item");
            for (var i = 0; i < classname.length; i++) {
                classname[i].addEventListener('click', evt => this.executeClick(evt));
            }

            $("." + class_name + "-body").on("hidden.bs.collapse", function () {
                let dataVal = $(this).data('collapseicon');
                $("." + dataVal).removeClass('fa-minus').addClass('fa-plus');
            });
            $("." + class_name + "-body").on("shown.bs.collapse", function () {
                let dataVal = $(this).data('collapseicon');
                $("." + dataVal).removeClass('fa-plus').addClass('fa-minus')
            });

            //$('.caspardo-filter-btn').collapse();

        }
        else {
            $('#' + parent_div).html(`<span class="col-xs-6">No Tag filter Available !!!</span>`);
        }

    }


    public executeClick(evt): void {
        if (typeof evt.target.checked != "undefined") {
            if (evt.target.checked) {
                this._selecteditems.push(evt.target.value);
            } else {
                this._selecteditems.splice(this._selecteditems.indexOf(evt.target.value), 1);
            }
            CaspardoAPI.selectedBrands = this._selecteditems;
        }
    }
}