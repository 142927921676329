import { CaspardoSeminarResults } from "../searchresults/seminar/CaspardoSeminarResult";
import { CaspardoContentResults } from "../searchresults/content/CaspardoContentResults";
import { CaspardoSelectedFilter } from "../template/CaspardoSelectedFilter";
import { CaspardoTemplate } from "../template/CaspardoTemplate";
import { CaspardoLogger } from "../common/CaspardoLogger";
import { CaspardoSeminarFinder } from "../seminarfinder/CaspardoSeminarFinder";
import { CaspardoSearchSlot } from "../searchslot/CaspardoSearchSlot";
import { IN18 } from "../IN18Translation";
import { CaspardoPriceRangeSlider } from "../components/CaspardoPriceRangeSlider";
import {CaspardoCheckboxFilter} from "../components/CaspardoCheckboxFilter";

import ionrangeslider from 'ion-rangeslider';

var data = require("../caspardoconfig.json");

export class CaspardoAPI {
	static config: any = null;
	static api_url: string;
	static api_sid: string;
	static defaultLanguage: string;
	static current_seminarid: string;
	static seminar_redirect: boolean = false;
	static seminar_redirect_query: string = "";
	static allseminarhash: string = "";
	static selectedBrands: Array<1> = [];
	static productMinPrice: string;
	static productMaxPrice: string;
	static productData: any;
	static totalProd: any = [];
	static listMoreProducts: any = [];


	static slider: CaspardoPriceRangeSlider = null;


	constructor(api_url: string, api_sid: string) {
		CaspardoAPI.config = data;
		CaspardoAPI.api_url = api_url;
		CaspardoAPI.api_sid = api_sid;
		CaspardoAPI.defaultLanguage = data.lang_default;
	}

	/**
	 * Content Query Generators
	 * @param query: Searched word, 
	 * @param page: Page number for API,
	 * If query type = 1
	 */
	public static generateContentQuery(query: string, page: number) {
		
		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=content&type=content&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&version=2.0&language=' + CaspardoAPI.defaultLanguage;
		if (query==="")
			api_call+="&all=true"
		return api_call;
	}

	/**
	 * Content Filtered Query Generators
	 * @param query: Searched word, 
	 * @param page: Page number for API,
	 * If query type = 2
	 */
	public static generateContentFilterQuery(query: string, page: number) {
		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=content&type=content&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&version=2.0&language=' + CaspardoAPI.defaultLanguage;
		if (query==="")
			api_call+="&all=true"
		for (var i = 0; i < CaspardoSelectedFilter.content_domains.length; i++) {
			api_call += "&domain=" + CaspardoSelectedFilter.content_domains[i];
		}
		return api_call;
	}

	/**
	 * Content Query Generators
	 * @param query: Searched word, 
	 * @param page: Page number for API,
	 * If query type = 3
	 */
	public static generateContentConfFilterQuery(query: string, page: number) {
		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=content&type=content&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&version=2.0&language=' + CaspardoAPI.defaultLanguage;
		if (query==="")
			api_call+="&all=true"
		let domains = [];
		jQuery(".caspardo_content_filtercheckbox").each(function (index, value) {
			let current = $(this);
			if (current.is(":checked")) {
				let filter_id = current.val();
				for (let filter of CaspardoAPI.config.searchresult_content.filterconf) {
					if (filter.id === filter_id) {
						domains = jQuery.merge(domains, filter.domains);
					}
				}
			}
		});
		for (var i = 0; i < domains.length; i++) {
			api_call += "&domain=" + domains[i];
		}
		return api_call;
	}

	/**
	 * Content search (Information) for following attributes,
	 * @param query: Searched word from autocomplete search-slot,
	 * @param type: Query type
	 * @param page: Page number for API,
	 */
	public static searchContent(query: string, type: number, page: number) {
		jQuery("#caspardo_results").html('<div class="row caspardo_loader"></div>');
		let api_call: string = "";
		if (type === 1)
			api_call = CaspardoAPI.generateContentQuery(query, page);
		if (type === 2)
			api_call = CaspardoAPI.generateContentFilterQuery(query, page);
		if (type === 3)
			api_call = CaspardoAPI.generateContentConfFilterQuery(query, page);
		var jqxhr = jQuery.getJSON(api_call, function () {
			CaspardoLogger.log("success");
		})
			.done(function (data) {
				CaspardoLogger.log(data);
				//CaspardoAPI.buildTabs(data.searchresult.header.tabs.tab);
				let apiTabData = data.searchresult.header.tabs.tab;
				for (var i = 0; i < apiTabData.length; i++) {
				if (apiTabData[i].tab === "shop") {
				 		jQuery("#caspardo-tab-shop-hits").html(apiTabData[i].hits);
				// 		if (CaspardoAPI.config.layout == "www_nabtesco_de") {
				// 			if (apiTabData[i].hits === 0) {
				// 				jQuery("#shop-hit-div").hide();
				// 				jQuery(".shop-filter-wrap").hide();
				// 				jQuery("#shop-nohit-div").show();
				// 			} else {
				// 				jQuery("#shop-hit-div").show();
				// 				jQuery(".shop-filter-wrap").show();
				// 				jQuery("#shop-nohit-div").hide();
				// 			}
				// 		}
				}
				if (apiTabData[i].tab === "information") {
						jQuery("#caspardo-tab-information-hits").html(apiTabData[i].hits);
						
							if (apiTabData[i].hits === 0) {
								jQuery("#information-hit-div").hide();
								jQuery(".info-tab-wrap").hide();
								jQuery("#information-nohit-div").show();
							} else {
								jQuery("#information-hit-div").show();
								jQuery(".info-tab-wrap").show();
								jQuery("#information-nohit-div").hide();
							}
						
				}
				if (apiTabData[i].tab === "seminar") {
						jQuery("#caspardo-tab-seminar-hits").html(apiTabData[i].hits);
				// 		if (CaspardoAPI.config.layout == "www_nabtesco_de") {
				// 			if (apiTabData[i].hits === 0) {
				// 				jQuery("#seminar-hit-div").hide();
				// 				jQuery("#seminar-nohit-div").show();
				// 			} else {
				// 				jQuery("#seminar-hit-div").show();
				// 				jQuery("#seminar-nohit-div").hide();
				// 			}
				// 		}
				}
				}

				let resContent = new CaspardoContentResults(data);
				resContent.generateContentList(type);
				jQuery("#caspardo_content_load").click((evt) => {
					let query: string = <string>jQuery("#caspardo_searchfield_new").val();
					let nextPage: number = data.searchresult.header.page + 1;
					CaspardoAPI.loadContentPage(query, type, nextPage);
				});
			})
			.fail(function () {
				CaspardoLogger.log("error");
			})
			.always(function () {
				CaspardoLogger.log("complete");
			});
		IN18.IN18Translation();
		return false;
	}

	/**
	 * Load Content(Information) page for following attributes
	 * @param query: Searched word from autocomplete search-slot,
	 * @param type: Query type
	 * @param page: Page number for API,
	 */
	public static loadContentPage(query: string, type: number, page: number) {
		let api_call: string = "";
		if (type === 1)
			api_call = CaspardoAPI.generateContentQuery(query, page);
		if (type === 2)
			api_call = CaspardoAPI.generateContentFilterQuery(query, page);
		if (type === 3)
			api_call = CaspardoAPI.generateContentConfFilterQuery(query, page);
		if (query==="")
			api_call+="&all=true"
		var jqxhr = jQuery.getJSON(api_call, function () {
			CaspardoLogger.log("success");
		})
			.done(function (data) {
				let resContent = new CaspardoContentResults(data);
				resContent.appendLoadPage();
				jQuery("#caspardo_content_load").click((evt) => {
					let query: string = <string>jQuery("#caspardo_searchfield_new").val();
					let nextPage: number = data.searchresult.header.page + 1;
					CaspardoAPI.loadContentPage(query, type, nextPage);
				});
			})
			.fail(function () {
				CaspardoLogger.log("error");
			})
			.always(function () {
				CaspardoLogger.log("complete");
			});
		return false;
	}

	/**
	 * Seminar Query Generators
	 * @param query: Searched word from autocomplete search-slot, 
	 * @param page: Page number for API, 
	 */
	public static generateSeminarQuery(query: string, page: number) {
		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=seminare&type=seminar&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&language=' + CaspardoAPI.defaultLanguage;
		if (CaspardoSelectedFilter.seminar_filter_available)
			api_call += "&bstnr=0&bstnr=1"
		return api_call;
	}

	/**
	 * Seminar No. Query Generators
	 * @param query: Searched word from autocomplete search-slot, 
	 * @param page: Page number for API, 
	 */
	public static generateSeminarNrQuery(query: string, page: number) {
		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=seminare&type=seminar&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&language=' + CaspardoAPI.defaultLanguage;
		return api_call;
	}

	/**
	 * Seminar Webcode Query Generators
	 * @param query: Searched word from autocomplete search-slot, 
	 * @param page: Page number for API, 
	 */
	public static generateSeminarWebcodeQuery(query: string, page: number) {
		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?webcode=' + query + '&query=&tab=seminare&type=seminar&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&language=' + CaspardoAPI.defaultLanguage;
		return api_call;
	}

	/**
	 * Seminar Appointment Query Generators
	 * @param query: Searched word from autocomplete search-slot, 
	 * @param page: Page number for API,
	 */
	public static generateSeminarAppointmentQuery(seminarid: string, page: number) {
		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=&tab=seminare&type=seminar&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=3&seminartitlehash=' + seminarid + '&page=' + page + '&language=' + CaspardoAPI.defaultLanguage;
		if (CaspardoSelectedFilter.seminar_filter_available)
			api_call += "&bstnr=0&bstnr=1"
		return api_call;
	}

	/**
	 * Generate result directly using following attributes for seminar,
	 * @param page: Page number for API,
	 * @param seminartitlehash: String for seminar title hash or list of seminar title hash. 
	 * @param city: Searched city
	 * @param date_from: Selected date (date from)
	 * @param date_to: Selected date (date to)
	 */
	public static generateDirectEntry(page: number, seminartitlehash: string, city: string, date_from: string, date_to: string) {

		let filter_query: string = "";
		if (seminartitlehash) {
			if (seminartitlehash != "") {
				// filter_query += "&seminartitlehash=" + seminartitlehash;
				CaspardoAPI.allseminarhash = "";
				if ((typeof seminartitlehash) == 'string') {
					filter_query += "&seminartitlehash=" + seminartitlehash;
				} else {
					for (let seminar = 0; seminar < seminartitlehash.length; seminar++) {
						CaspardoAPI.allseminarhash += "&seminartitlehash=" + seminartitlehash[seminar];
					}
					filter_query += CaspardoAPI.allseminarhash;
				}
			}
		}

		if (city) {
			if (city != "")
				filter_query += "&city=" + city;
		}

		if (date_from) {
			if (date_from != "")
				filter_query += "&from=" + date_from;
		}

		if (date_to) {
			if (date_to != "")
				filter_query += "&to=" + date_to;
		}

		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=&tab=seminare&type=seminar&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=3&page=' + page + '&language=' + CaspardoAPI.defaultLanguage + filter_query;
		if (CaspardoSelectedFilter.seminar_filter_available)
			api_call += "&bstnr=0&bstnr=1"
		return api_call;
	}

	/**
	 * Seminar Filtered Query Generators
	 * @param query: Searched word from autocomplete search-slot, 
	 * @param page: Page number for API,
	 */
	public static generateSeminarFilterQuery(query: string, page: number) {
		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=seminare&type=seminar&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&language=' + CaspardoAPI.defaultLanguage;

		for (var i = 0; i < CaspardoSelectedFilter.seminar_themes.length; i++) {
			api_call += "&seminartitlehash=" + CaspardoSelectedFilter.seminar_themes[i];
		}

		for (var i = 0; i < CaspardoSelectedFilter.seminar_cities.length; i++) {
			api_call += "&city=" + CaspardoSelectedFilter.seminar_cities[i];
		}

		for (var i = 0; i < CaspardoSelectedFilter.seminar_kws.length; i++) {
			let kw: string = CaspardoSelectedFilter.seminar_kws[i];
			let pos: number = kw.indexOf("-");
			kw = kw.replace(new RegExp('-', 'g'), "");
			api_call += "&wnr=" + kw;
		}

		if (CaspardoSelectedFilter.seminar_date_to)
			api_call += "&to=" + CaspardoSelectedFilter.seminar_date_to;
		if (CaspardoSelectedFilter.seminar_date_from)
			api_call += "&from=" + CaspardoSelectedFilter.seminar_date_from;
		if (CaspardoSelectedFilter.seminar_option_arbeitsgericht)
			api_call += "&option=gerichtsverhandlung";
		if (CaspardoSelectedFilter.seminar_option_rollstuhlgerecht)
			api_call += "&option=behindertengerecht&option=behindertengerechtvoll";
		if (CaspardoSelectedFilter.seminar_option_barrierefrei)
			api_call += "&option=behindertengerechtbarrierefrei";
		if (CaspardoSelectedFilter.seminar_option_bedingtbehindert)
			api_call += "&option=behindertengerechtbedingt";
		if (CaspardoSelectedFilter.seminar_option_nonsmoke)
			api_call += "&option=nichtraucherseminar";
		if (CaspardoSelectedFilter.seminar_option_starterpaket)
			api_call += "&option=grundlagenstarterpaket";
		if (CaspardoSelectedFilter.semianr_option_rhethorik)
			api_call += "&option=rhetorikstudio";
		if (CaspardoSelectedFilter.seminar_filter_available)
			api_call += "&bstnr=0&bstnr=1";
		if (CaspardoTemplate.httpPara_seminarTitleHash) {
			CaspardoAPI.allseminarhash = "";
			if ((typeof CaspardoTemplate.httpPara_seminarTitleHash) == 'string') {
				api_call += "&seminartitlehash=" + CaspardoTemplate.httpPara_seminarTitleHash;
			} else {
				for (let seminar = 0; seminar < CaspardoTemplate.httpPara_seminarTitleHash.length; seminar++) {
					CaspardoAPI.allseminarhash += "&seminartitlehash=" + CaspardoTemplate.httpPara_seminarTitleHash[seminar];
				}
				api_call += CaspardoAPI.allseminarhash;
			}
		}

		return api_call;

	}

	/**
	 * Search Seminar Appointments,
	 * @param seminarid: Seminar ID,
	 * @param page: Page number for API,
	 */
	public static searchSeminarAppointments(seminarid: string, page: number) {
		let api_call: string = CaspardoAPI.generateSeminarAppointmentQuery(seminarid, page);
		var jqxhr = jQuery.getJSON(api_call, function () {
			CaspardoLogger.log("success");
		})
			.done(function (data) {
				let resSeminars = new CaspardoSeminarResults(data);
				let nextPage: number = data.searchresult.header.page + 1;
				resSeminars.generateAppointmentList("appointments");
				let seminarFilterHeight: number = 370;
				let jumpTo: number = jQuery("#caspardo_appointmentlist").offset().top - seminarFilterHeight;
				jQuery('html, body').animate({
					scrollTop: jumpTo
				}, 2000);
				jQuery("#caspardo_seminarappointment_load").click((evt) => {
					let nextPage: number = data.searchresult.header.page + 1;
					CaspardoAPI.searchSeminarAppointments(CaspardoAPI.current_seminarid, nextPage);
				});
			})
			.fail(function () {
				CaspardoLogger.log("error");
			})
			.always(function () {
				CaspardoLogger.log("complete");
			});
		return false;
	}

	/**
	 * Search Seminar
	 * @param query: Searched word from autocomplete search-slot,
	 * @param type: Query type,
	 * @param page: Page number for API,
	 * @param presearch: Holds boolean value,
	 */
	public static searchSeminar(query: string, type: number, page: number, presearch: boolean) {
		CaspardoAPI.seminar_redirect = false;
		CaspardoSelectedFilter.setSeminarFilterLoader(1, 0);
		CaspardoSelectedFilter.setEnable(true);
		if (presearch === false) {
			jQuery("#caspardo_results").html('');
			CaspardoAPI.displayAppointmentLoader();
		}
		let api_call: string = "";
		if (type === 1) // Normal Query
			api_call = CaspardoAPI.generateSeminarQuery(query, page);
		if (type === 2) // Filter Query
			api_call = CaspardoAPI.generateSeminarFilterQuery(query, page);
		if (type === 3) // Seminarnr Query
			api_call = CaspardoAPI.generateSeminarNrQuery(query, page);
		if (type === 4) // Webcode Query
		{
			let indexOfC: number = query.toLowerCase().indexOf("c");
			if (indexOfC > 0) {
				CaspardoAPI.seminar_redirect_query = query;
				query = query.substring(0, indexOfC);
				CaspardoAPI.seminar_redirect = true;
			}
			api_call = CaspardoAPI.generateSeminarWebcodeQuery(query, page);
		}
		if (type === 5) // Seminartitlehash
			api_call = CaspardoAPI.generateDirectEntry(page, CaspardoTemplate.httpPara_seminarTitleHash, CaspardoTemplate.httpPara_city, CaspardoTemplate.httpPara_date_from, CaspardoTemplate.httpPara_date_to);

		var jqxhr = jQuery.getJSON(api_call, function () {
			CaspardoLogger.log("success");
		})
			.done(function (data) {
				if (data.searchresult.header.translatetargeturl != "")
					window.location.href = data.searchresult.header.translatetargeturl;
				else {

					if (presearch) {
						CaspardoSelectedFilter.setSeminarFilterLoader(2, data.searchresult.header.hits);
						CaspardoSeminarFinder.setSearchBtnName(data.searchresult.header.tabs.tab[1].hits);
						if (CaspardoSelectedFilter.seminar_filter_refresh) {
							let resSeminars = new CaspardoSeminarResults(data);
							resSeminars.generateFilter();
							CaspardoSelectedFilter.seminar_filter_refresh = false;
						}
					}
					else {
						let addSeminarlist: boolean = true;

						CaspardoAPI.buildTabs(data.searchresult.header.tabs.tab);

						// jQuery("#caspardo-tab-seminar-hits").html(data.searchresult.header.tabs.tab[1].hits);
						// jQuery("#caspardo-tab-information-hits").html(data.searchresult.header.tabs.tab[2].hits);
						// jQuery("#caspardo-tab-shop-hits").html(data.searchresult.header.tabs.tab[7].hits);

						let resSeminars = new CaspardoSeminarResults(data);
						if (type === 1) // Normal Query
						{
							CaspardoSelectedFilter.resetFilters();
							resSeminars.generateFilter();
						}
						if (type === 2) // Filter Query
						{
							addSeminarlist = false;
							//CaspardoSelectedFilter.resetFilters();
							//resSeminars.generateFilter();
						}
						if ((type === 4) && (data.searchresult.header.hits > 0) && (CaspardoAPI.seminar_redirect === true)) {
							window.location.href = "https://www.ifb.de/" + CaspardoAPI.seminar_redirect_query;
							return;
						}
						if ((type === 3) || (type === 4)) {
							CaspardoSelectedFilter.resetFilters();
							resSeminars.generateFilter();
							CaspardoSelectedFilter.setEnable(false);
						}

						if (type === 5) {
							CaspardoSelectedFilter.resetFilters();
							resSeminars.generateFilter();
						}

						if (data.searchresult.header.tabs.tab[1].hits === 0) {
							resSeminars.generateNoHitsInfo();
						}
						else {
							resSeminars.generateSeminarList(addSeminarlist);
							jQuery("#caspardo_btn_seminarfilter").click((evt) => {
								let query: string = <string>jQuery("#caspardo_searchfield_new").val();
								CaspardoAPI.searchSeminar(query, 2, 1, presearch);
							});
							jQuery("#caspardo_seminarappointment_load").click((evt) => {
								var v = evt.target.attributes.getNamedItem("data-view");
								let query: string = <string>jQuery("#caspardo_searchfield_new").val();
								let nextPage: number = data.searchresult.header.page + 1;
								CaspardoAPI.loadSeminarPage(query, type, nextPage);
							});
							jQuery(".caspardo_seminar_click").click((evt) => {
								CaspardoAPI.displayAppointmentLoader();
								var v = evt.target.attributes.getNamedItem("data-seminarhash");
								let seminarId: any = v.value;
								CaspardoAPI.current_seminarid = seminarId;
								CaspardoAPI.searchSeminarAppointments(seminarId, 1);
							});
						}

						//(<any>$('.nav-tabs a[href="#home"]')).tab('show');

					}
				}
			})
			.fail(function () {
				CaspardoLogger.log("error");
			})
			.always(function () {
				CaspardoLogger.log("complete");
			});
		IN18.IN18Translation();
		return false;
	}

	/**
	 * Seminar loader
	 */
	public static displayAppointmentLoader() {
		jQuery("#caspardo_appointmentlist").html('<div class="row"><div class="caspardo_loader"></div></div>');
	}

	/**
	 * Load seminar page(tab)
	 * @param query: Searched word from autocomplete search-slot,
	 * @param type: Query type,
	 * @param page: Page number for API,
	 */
	public static loadSeminarPage(query: string, type: number, page: number) {

		let api_call: string = "";

		if (type === 1) // Seminar Query
			api_call = CaspardoAPI.generateSeminarQuery(query, page);

		if ((type === 2) || (type === 5)) // Seminar Filter Query
			api_call = CaspardoAPI.generateSeminarFilterQuery(query, page);

		if (type === 3) // Seminarnr Query
		{
			let querySeminarNr: string = <string>jQuery("#caspardo_btn_seminarnr").val();
			api_call = CaspardoAPI.generateSeminarNrQuery(querySeminarNr, page);
		}

		if (type === 4) // Webcode Query
		{
			let queryWebcode: string = <string>jQuery("#caspardo_btn_seminarwebcode").val();
			api_call = CaspardoAPI.generateSeminarWebcodeQuery(queryWebcode, page);
		}

		var jqxhr = jQuery.getJSON(api_call, function () {
			CaspardoLogger.log("success");
		})
			.done(function (data) {
				// Handle the response
				let resSeminars = new CaspardoSeminarResults(data);
				resSeminars.appendLoadPage("general");
				jQuery("#caspardo_seminarappointment_load").click((evt) => {
					let query: string = <string>jQuery("#caspardo_searchfield_new").val();
					let nextPage: number = data.searchresult.header.page + 1;
					CaspardoAPI.loadSeminarPage(query, type, nextPage);
				});
			})
			.fail(function () {
				CaspardoLogger.log("error");
			})
			.always(function () {
				CaspardoLogger.log("complete");
			});
		return false;
	}

	/**
	 * Generate seminar appointments
	 * @param seminarid: Seminar ID, 
	 * @param page: Page number for API, 
	 */
	public static loadSeminarAppointments(seminarid: string, page: number) {
		let api_call: string = CaspardoAPI.generateSeminarAppointmentQuery(seminarid, page);
	}

	/**
	 * Search for shop
	 * @param tabData: List of Tabs (tabs defination) 
	 * @param query: Searched word from autocomplete search-slot, 
	 */
	public static searchShop(tabData: any, query: string) {
		var tab = tabData.data_view;
		return CaspardoAPI.getShopFilters(tabData, query, tab, 1);
	}

	/**
	 * buildTabs
	 * @param tabs: Processes the tabs of the search result and init the tabs.
	 */

	public static buildTabs(tabs: any) {
		let apiTabData = tabs;
		for (var i = 0; i < apiTabData.length; i++) {
			// For shop
			if (apiTabData[i].type === "shop"){
				jQuery("#caspardo-tab-shop-hits").html(apiTabData[i].hits);
				// if (apiTabData[i].hits === 0) {
				// 	jQuery("#shop-hit-div").hide();
				// 	jQuery(".shop-filter-wrap").hide();
				// 	jQuery("#shop-nohit-div").show();
				// } else {
				// 	jQuery("#shop-hit-div").show();
				// 	jQuery(".shop-filter-wrap").show();
				// 	jQuery("#shop-nohit-div").hide();
				// }
			}
			// For Information
			if (apiTabData[i].type === "content") {
				jQuery("#caspardo-tab-information-hits").html(apiTabData[i].hits);
				// if (apiTabData[i].hits === 0) {
				// 	jQuery("#information-hit-div").hide();
				// 	jQuery(".info-tab-wrap").hide();
				// 	jQuery("#information-nohit-div").show();
				// } else {
				// 	jQuery("#information-hit-div").show();
				// 	jQuery(".info-tab-wrap").show();
				// 	jQuery("#information-nohit-div").hide();
				// }
			}
			// For Seminar
			if ((apiTabData[i].type === "seminar") && (apiTabData[i].selected)) {
				jQuery("#caspardo-tab-seminar-hits").html(apiTabData[i].hits);
				// if (apiTabData[i].hits === 0) {
				// 	jQuery("#seminar-hit-div").hide();
				// 	jQuery("#seminar-nohit-div").show();
				// } else {
				// 	jQuery("#seminar-hit-div").show();
				// 	jQuery("#seminar-nohit-div").hide();
				// }
			}
		}
	}

	/**
	 * Generate results and generate filters for shop tab,
	 * @param tabsArray: List of Tabs (tabs defination),
	 * @param query: Searched word from autocomplete search-slot,
	 * @param type: Page number for API,
	 * @param page: Query type,
	 */
	public static getShopFilters(tabsArray, query, type, page) {

		let caspardo_search_loader: string =`<div class="col-xs-12">
		<div class="container">
			<div class="row">
					<div class="col-xs-5"></div>
				<div class="col-xs-2">
					<div class="caspardo-search-loader text-center"></div>
				</div>
				<div class="col-xs-5"></div>
			</div>
		</div>`;

		$('#product-listing-div').html(caspardo_search_loader);


		//query = query == "" ? 'all' : query;

		let api_call: string = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=' + type + '&type=' + type + '&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&version=2.0&language=' + CaspardoAPI.defaultLanguage;;
		if (query==="")
			api_call+="&all=true"
		var allProductData;
		var jqxhr = jQuery.getJSON(api_call, function () {
			CaspardoLogger.log("success");
		})
			.done(function (data) {

				// Get template for shop tab,
				CaspardoAPI.getShopTemplate(data, tabsArray, query);
				CaspardoAPI.buildTabs(data.searchresult.header.tabs.tab);

				//if (tabsArray.default_shop_view === "grid") {
					jQuery("#shop_grid").addClass("active");
					jQuery("#shop_list").hide();
					jQuery(".shoptab-grid-view").show();
					jQuery(".shoptab-list-view").hide();
				//}
				// if (tabsArray.default_shop_view === "list") {
				// 	jQuery("#shop_grid").hide();
				// 	jQuery("#shop_list").addClass("active");
				// 	jQuery(".shoptab-list-view").show();
				// 	jQuery(".shoptab-grid-view").hide();
				// }
		
				jQuery("#caspardo-tab-shop-hits").html(data.searchresult.header.hits);
				CaspardoTemplate.setSearchResultHeadline(query);

				var elementHits = jQuery(".caspardo_searchresult_hits");
				elementHits.html("" + data.searchresult.header.hits);
		
				var elementSearchTime = jQuery("#caspardo_searchresult_time");
				elementSearchTime.html("" + data.searchresult.header.searchtime_in_seconds);

				let apiTabData = data.searchresult.header.tabs.tab;
				for (var i = 0; i < apiTabData.length; i++) {
					// For shop
					if (apiTabData[i].tab === "shop") {
						jQuery("#caspardo-tab-shop-hits").html(apiTabData[i].hits);
					}
					// For Information
					if (apiTabData[i].tab === "information") {
						jQuery("#caspardo-tab-information-hits").html(apiTabData[i].hits);
					}
					// For Seminar
					if (apiTabData[i].tab === "seminar") {
						jQuery("#caspardo-tab-seminar-hits").html(apiTabData[i].hits);
					}
				}

			})
			.fail(function () {
				CaspardoLogger.log("---error");
			})
			.always(function () {
				CaspardoLogger.log("complete");
			});
		IN18.IN18Translation();
	}

	/**
	 * Genarate Shop tab template,
	 * @param resData: Response from API, 
	 * @param tabsArray: List of Tabs (tabs defination),
	 * @param query: Searched word from autocomplete search-slot,
	 */
	public static getShopTemplate(resData, tabsArray, query) {

		CaspardoSelectedFilter.shop_price_min = resData.searchresult.results.domain.filters.price.pricemin;
		CaspardoSelectedFilter.shop_price_max = resData.searchresult.results.domain.filters.price.pricemax;
		CaspardoSelectedFilter.printSeletedShopFilters();	// Console output in debug mode

		var productHtml: string = '';
		var products: any = resData.searchresult.results.domain.resultlist.item; 	// Get Product Row
		if (products) {
			if (!(products instanceof Array)) {
				var tempArray = [];
				tempArray.push(products);
				products = tempArray;
			}

			if (products) {
				for (var i = 0; i < products.length; i++) {
					let productRow: string = CaspardoAPI.config.searchresult_content.searchresult_product_views;
					productRow = productRow.replace(new RegExp('{products_picture}', 'g'), products[i].picture);
					productRow = productRow.replace(new RegExp('{products_url_to_basket}', 'g'), products[i].url_to_basket);
					productRow = productRow.replace(new RegExp('{products_title}', 'g'), products[i].title);
					productRow = productRow.replace(new RegExp('{products_price}', 'g'), products[i].price);
					productRow = productRow.replace(new RegExp('{article_number}', 'g'), products[i].articlenumber);
					productHtml += productRow;
				}
			}
		}

		let searchResultHead = CaspardoTemplate.config.searchresult_content.head;
		searchResultHead = searchResultHead.replace(new RegExp('{hits}', 'g'), resData.searchresult.header.hits);
		searchResultHead = searchResultHead.replace(new RegExp('{searchtime_in_seconds}', 'g'), resData.searchresult.header.searchtime_in_seconds);
		searchResultHead = searchResultHead.replace(new RegExp('{query}', 'g'), query);
		$("#caspardo_results_heading_div").replaceWith(`${searchResultHead}`);

		var res = $("#product-listing-div").html(`${productHtml}`);

		if (tabsArray.default_shop_view === "grid") {
			jQuery("#shop_grid").addClass("active");
			jQuery("#shop_list").hide();
			jQuery(".shoptab-grid-view").show();
			jQuery(".shoptab-list-view").hide();
		}
		if (tabsArray.default_shop_view === "list") {
			jQuery("#shop_grid").hide();
			jQuery("#shop_list").addClass("active");
			jQuery(".shoptab-list-view").show();
			jQuery(".shoptab-grid-view").hide();
		}

		jQuery("#caspardo-tab-shop-hits").html(resData.searchresult.header.hits);
	
		IN18.IN18Translation();

		CaspardoTemplate.setSearchResultHeadline(query);
		var elementHits = jQuery(".caspardo_searchresult_hits");
		elementHits.html("" + resData.searchresult.header.hits);
		var elementSearchTime = jQuery("#caspardo_searchresult_time");
		elementSearchTime.html("" + resData.searchresult.header.searchtime_in_seconds);

		// Init the shop events
		CaspardoAPI.initShopEvent(tabsArray, resData, query);

		// Process the filters
		let filters = resData.searchresult.results.domain.filters;
		if (filters.filter) {
			for (var i = 0; i < filters.filter.length; i++) {
				if (filters.filter[i].type === "attribute1") {
					let checkBoxFilter: CaspardoCheckboxFilter = new CaspardoCheckboxFilter();
					checkBoxFilter.buildFilter(filters.filter[i], "attr1", "caspardo-shop-filters", "caspardo-filter", IN18.IN18TranslateNameByKey("shop_filter_category1_headline"), IN18.IN18TranslateNameByKey("shop_filter_category1_name"), IN18.IN18TranslateNameByKey("shop_filter_category1_topname"));
				}
				if (filters.filter[i].type === "category1") {
					let checkBoxFilter: CaspardoCheckboxFilter = new CaspardoCheckboxFilter();
					checkBoxFilter.buildFilter(filters.filter[i], "category1", "caspardo-shop-filters", "caspardo-filter",  IN18.IN18TranslateNameByKey("shop_filter_category1_headline"), IN18.IN18TranslateNameByKey("shop_filter_category1_name"), IN18.IN18TranslateNameByKey("shop_filter_category1_topname"));
				}
			}
		}

		// Process the price range filter
		if (filters) {
			CaspardoAPI.getShopPriceFilter(filters);
		}

		// First serarch - no hits then redircet to content search
		if (resData.searchresult.header.hits === 0)
			jQuery('#caspardo_tab_content').click();

		return res;
	}

	/**
	 * Genarate Filtered Shop tab template. It will use the filter api call.
	 * @param tabsArray: List of Tabs (tabs defination),
	 * @param data: Response from API,
	 * @param query: Searched word from autocomplete search-slot,
	 */
	public static getShopTemplateFiltered(tabsArray, data, query) {
		CaspardoAPI.totalProd = [];

		// Process the search loader
		let caspardo_search_loader: string = `<div class="col-xs-12">
		<div class="container">
			<div class="row">
					<div class="col-xs-5"></div>
				<div class="col-xs-2">
					<div class="caspardo-search-loader text-center"></div>
				</div>
				<div class="col-xs-5"></div>
			</div>
		</div>`;
		$('#product-listing-div').html(caspardo_search_loader);

		let api_url_data :string=this.getApiCallFilter(query,1);

		var jqxhr = jQuery.getJSON(api_url_data, function () {
			CaspardoLogger.log("success");
		})
			.done(function (totalData) {

				// Process the products
				var productHtml: string = " ";
				if (totalData.searchresult.results.domain.hits > 0) {
					var products: any = totalData.searchresult.results.domain.resultlist.item;
					if (!(products instanceof Array)) {
						var tempArray = [];
						tempArray.push(products);
						products = tempArray;
					}
					if (products) {
						for (var i = 0; i < products.length; i++) {
							let productRow: string = CaspardoAPI.config.searchresult_content.searchresult_product_views;
							productRow = productRow.replace(new RegExp('{products_picture}', 'g'), products[i].picture);
							productRow = productRow.replace(new RegExp('{products_url_to_basket}', 'g'), products[i].url_to_basket);
							productRow = productRow.replace(new RegExp('{products_title}', 'g'), products[i].title);
							productRow = productRow.replace(new RegExp('{products_price}', 'g'), products[i].price);
							productRow = productRow.replace(new RegExp('{article_number}', 'g'), products[i].articlenumber);
							productHtml += productRow;
						}
					}
				}

				// Process the result head
				let searchResultHead = CaspardoTemplate.config.searchresult_content.head;
				searchResultHead = searchResultHead.replace(new RegExp('{hits}', 'g'), totalData.searchresult.header.hits);
				searchResultHead = searchResultHead.replace(new RegExp('{searchtime_in_seconds}', 'g'), totalData.searchresult.header.searchtime_in_seconds);
				searchResultHead = searchResultHead.replace(new RegExp('{query}', 'g'), query);
				$("#caspardo_results_heading_div").replaceWith(`${searchResultHead}`);

				// Add Product-Results
				var res: any = $('#product-listing-div').html(`${productHtml}`);

				// Process the result head
				CaspardoTemplate.setSearchResultHeadline(query);
				var elementHits = jQuery(".caspardo_searchresult_hits");
				elementHits.html("" + totalData.searchresult.header.hits);
				var elementSearchTime = jQuery("#caspardo_searchresult_time");
				elementSearchTime.html("" + totalData.searchresult.header.searchtime_in_seconds);

				// Init the Grid/List
				jQuery("#shop_grid").addClass("active");
				jQuery("#shop_list").hide();
				jQuery(".shoptab-grid-view").show();
				jQuery(".shoptab-list-view").hide();

				// Set Tab Hits
				jQuery("#caspardo-tab-shop-hits").html(totalData.searchresult.header.hits);

				let page = totalData.searchresult.results.domain.page;

				if (totalData.searchresult.results.domain.pages>1) {
					jQuery("#shopShowMoreButton").show();
					jQuery("#shopShowMoreButton").unbind('click').bind("click",(evt) => {
						let query: string = <string>jQuery("#caspardo_searchfield_new").val();
						//query = query == "" ? 'all' : query;
						if (page < totalData.searchresult.results.domain.pages) {
							page += 1;
							CaspardoAPI.getMoreProducts(tabsArray, query, page);
						} else {
							jQuery("#shopShowMoreButton").hide();
						}
					});
				}
			
				IN18.IN18Translation();

				return res;
			});
	}

	public static getApiCallFilter(query:string, page:number){
		let api_url_data = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=shop&type=shop&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page='+page+'&version=2.0&language=' + CaspardoAPI.defaultLanguage;
		if (query === "")
			api_url_data += "&all=true"
		
		// Price Range Filter
		let price_min = $("#caspardo_filter_price_min").val();
		let price_max = $("#caspardo_filter_price_max").val();
		if (price_max > 0) {
			api_url_data += "&price_greater_than=" + price_min;
			api_url_data += "&price_lesser_than=" + price_max;
		}
		
		// Process the categories
		for (let index = 0; index < CaspardoAPI.selectedBrands.length; index++) {
			api_url_data += "&category1=" + CaspardoAPI.selectedBrands[index];
		}
		return api_url_data;
	}

	/**
	 * Generate Price filter slider
	 * @param filterData: Shop tab filter data
	 */
	public static getShopPriceFilter(filterData: any) {

		let priceRangeSlider: CaspardoPriceRangeSlider=new CaspardoPriceRangeSlider();

		let minPrice: any = CaspardoSelectedFilter.shop_price_min;
		if (minPrice > 0)
			minPrice = minPrice / 100;
		let maxPrice: any = CaspardoSelectedFilter.shop_price_max;
		if (maxPrice > 0) {
			maxPrice = maxPrice / 100;

			priceRangeSlider.initSlider("example_id", minPrice, maxPrice);
		}
	}

	/**
	 * Set the values of price filter slider in shop tab,
	 * @param minPrice: Maximum value for price filter,
	 * @param maxPrice: Minimum value for price filter,
	 */
	public static setPricefilter(minPrice: any, maxPrice: any) {
		CaspardoAPI.productMinPrice = minPrice;
		CaspardoAPI.productMaxPrice = maxPrice;
	}

	/**
	 * Generate Tag filter for shop tab,
	 * @param filter_key: List of tag filters,
	 */
	public static getTopFilterByKey(filter_key:string){
		if (CaspardoAPI.config.searchresult_shop.topfilters)
		{
		let topFiltersArr: Array<string>;
		topFiltersArr=Object.keys(CaspardoAPI.config.searchresult_shop.topfilters);
		let find:number=topFiltersArr.indexOf(filter_key);
		if (find>=0)
			return CaspardoAPI.config.searchresult_shop.topfilters[filter_key];
		else
			return [];
		}
		else
			return [];
	}

	public static getIN18FilterName(filter:string, filtername: string) {
		let translateName: string = "";
		if (filter === "category1")
			translateName = IN18.IN18TranslateShopCategory1(filtername);
		if (filter === "attr1")
			translateName = IN18.IN18TranslateShopFilterAttr1(filtername);
		return translateName;
	}

	/**
	 * Generate Tag filter for shop tab,
	 * @param tagfilter: List of tag filters,
	 */
	public static getShopTagFilter(tagfilter, idselektor:string, name:string, display_name:string,display_topname:string ) {
		
		let selecteditems: any = [];
		
		// HTML-Snippet
		let template_head_topfilter = `
		<div class="row">
			<div class="col-xs-12 caspardo-tagfilter-groupheadline">
				<span class="">${display_topname}</span>
			</div>
		</div>`;
		let template_head_filter = `
		<div class="row">
			<div class="col-xs-12 caspardo-tagfilter-groupheadline">
				<span class="">${display_name}</span>
			</div>
		</div>`;

		// Clear the filters
		$('#'+idselektor).empty();

		let insertText:string="";
		let insertedFiltes: Array<String> = [];

		// Process the filters
		if (tagfilter) 
		{
			// Get the top-filters for the filter.name
			let top_filters = CaspardoAPI.getTopFilterByKey(name);
			if (top_filters.topfilters)
			{
			if (top_filters.topfilters.length > 0) // Top-Filters found
			{

	

				// Add the filter-head for the top filtes
				insertText += template_head_topfilter;

				// Build the top-filters
				insertText += '<div class="row">';
				for (var i = 0; i < top_filters.topfilters.length; i++) {
					// Get the filter key
					let filterName: string = top_filters.topfilters[i].key;
					// Get the filtername
					let translateName :string=CaspardoAPI.getIN18FilterName(name,filterName);
					// Create the filter
					insertText += `<span class="col-xs-6 caspardo-shop-brand-filter-span caspardo-tagfilter-top-item">
					<input type="checkbox" name="${top_filters.topfilters[i].key}" value="${top_filters.topfilters[i].key}">
					${translateName}</span>`;
					// Push the inserted filter
					insertedFiltes.push(filterName);
				}
				insertText += '</div>';
			}
		}
			let filters = tagfilter.value;
			if (filters.length > 0) // Filters found
			{
				
				// Insert the filter head
				insertText += template_head_filter;
				// Build the filters
				insertText += '<div class="row">';
				for (let filter = 0; filter < filters.length; filter++) {
					let filterName: string = filters[filter].name;
					if (insertedFiltes.indexOf(filterName) === -1) 
					{
						let translateName = CaspardoAPI.getIN18FilterName(name,filterName);

						insertText += `<span class="col-xs-6 caspardo-shop-brand-filter-span">
						<input type="checkbox" name="${filters[filter].name}" value="${filters[filter].name}">
						${translateName}</span>`;
						
						insertedFiltes.push(filterName);
					}
				}
				insertText += '</div>';
			}

			$('#'+idselektor).html(insertText);

		} 
		else 
		{
			$('#'+idselektor).html(`<span class="col-xs-6">No Tag filter Available !!!</span>`);
		}

		$('#'+idselektor+' input[type=checkbox]').click(function () {
			// array total selected brands
			if ($(this).is(':checked')) {
				selecteditems.push($(this).val());
			} else {
				selecteditems.splice(selecteditems.indexOf($(this).val()), 1);
			}
			CaspardoAPI.selectedBrands = selecteditems;
		});


	}

	/**
	 * Init Events for Shop tab,
	 * @param tabsArray: List of Tabs (tabs defination),
	 * @param data: Response from API,
	 * @param query: Searched word from autocomplete search-slot,
	 */
	public static initShopEvent(tabsArray, data, query) {

		// jQuery(".shoptab-list-view").hide();
		// jQuery(".shoptab-grid-view").hide();

		jQuery(".shoptab-list-view").hide();
		jQuery(".shoptab-grid-view").show();
		jQuery(".grid-system p").hide();
		/**
		 * Conditions for list and grid view,
		 */
		// if (tabsArray.default_shop_view === "grid") {
		// 	jQuery("#shop_grid").addClass("active");
		// 	jQuery("#shop_list").hide();
		// 	jQuery(".shoptab-grid-view").show();
		// 	jQuery(".grid-system p").hide();
		// }
		// if (tabsArray.default_shop_view === "list") {
		// 	jQuery("#shop_grid").hide();
		// 	jQuery("#shop_list").addClass("active");
		// 	jQuery(".shoptab-list-view").show();
		// 	jQuery(".grid-system p").hide();
		// }

		/**
		 * Event: Click
		 * Attach to: #shop_list
		 * List view open when clicked
		 */
		jQuery("#shop_list").click((evt) => {
			jQuery("#shop_list").addClass("active");
			jQuery("#shop_grid").removeClass("active");
			jQuery(".shoptab-list-view").show();
			jQuery(".shoptab-grid-view").hide();
		});

		/**
		 * Event: Click
		 * Attach to: #
		 * Grid view open when clicked
		 */
		jQuery("#shop_grid").click((evt) => {
			jQuery("#shop_grid").addClass("active");
			jQuery("#shop_list").removeClass("active");
			jQuery(".shoptab-list-view").hide();
			jQuery(".shoptab-grid-view").show();
		});

		/**
		 * Conditions for pagination(next and previous buuton),
		 * Button disable/enable according to page number API Data, 
		 */
		let page = data.searchresult.results.domain.page;
		if (page == 1) {
			$('#shopNextButton').removeAttr("disabled");
			$("#shopPreviousButton").attr("disabled", "true");
		}
		if (page >= 2) {
			$('#shopNextButton').removeAttr("disabled");
			$('#shopPreviousButton').removeAttr("disabled");
		}
		if (data.searchresult.results.domain.pages === page) {
			$("#shopNextButton").attr("disabled", "true");
			$('#shopPreviousButton').removeAttr("disabled");
			$("#shopShowMoreButton").hide();
		}

		/**
		 * Condition for hide/show price filter,
		 */
		let filters = data.searchresult.results.domain.filters;
		if (filters.price.pricemax == 0)
			$('#shop_price_filter').hide();
		else
			$('#shop_price_filter').show();

		/**
		 * Initially hide both type of pagination,
		 */
		$("#caspardo_pagination_v1").hide();
		$("#caspardo_pagination_v2").hide();

		/**
		 * Conditionally show both type of pagination,
		 */
		if (CaspardoAPI.config.pagination_type === 1) {
			$("#caspardo_pagination_v1").show();
		}
		if (CaspardoAPI.config.pagination_type === 2) {
			$("#caspardo_pagination_v2").show();
		}

		/**
		 * Event: Click
		 * Attach to: #caspardo_tab_shop
		 * Open shop tab when click on it and get results.
		 */
		jQuery("#caspardo_tab_shop").unbind('click').bind("click", (evt) => {
			let query: string = <string>jQuery("#caspardo_searchfield_new").val();
			//CaspardoTemplate.createDynamicTabs(query);
			let tabData:any={
				"data_view":"shop"
			};
			CaspardoAPI.searchShop(tabData, query);
			 jQuery("#caspardo_seminarfilter_panel").hide();
			 jQuery("#caspardo_contentfilter_panel").hide();
			 jQuery("#caspardo_shopfilter_panel").show();
			 CaspardoSelectedFilter.search_tab_autocomplete_contenttype = 'products';
			CaspardoSelectedFilter.search_tab_type = 'products';
			for (let index = 0; index < CaspardoAPI.config.tabs.length; index++) {
				let element = CaspardoAPI.config.tabs[index];
				if (element.id === "caspardo_tab_shop") {
				  CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType("products"), element.autocomplete_searchslot.dropdown_icon_class);
				}
			  }
			//CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType("products"), tabsArray.autocomplete_searchslot.dropdown_icon_class);
		});

		let type = tabsArray.data_view;
		/**
		 * Event: Click
		 * Attach to: #shopNextButton
		 * Bind / Unbind click event on pagination next button
		 */
		jQuery("#shopNextButton").unbind('click').bind("click", (evt) => {
			let query: string = <string>jQuery("#caspardo_searchfield_new").val();
			query = query == "" ? 'all' : query;
			let currentPage = data.searchresult.results.domain.page;
			let nextPage: number = currentPage;
			if (currentPage < data.searchresult.results.domain.pages) {
				nextPage = nextPage + 1;
			}
			CaspardoAPI.getShopFilters(tabsArray, query, type, nextPage);
		});

		/**
		 * Event: Click
		 * Attach to: #shopPreviousButton
		 * Bind / Unbind click event on pagination previous button
		 */
		jQuery("#shopPreviousButton").unbind('click').bind("click", (evt) => {
			let query: string = <string>jQuery("#caspardo_searchfield_new").val();
			query = query == "" ? 'all' : query;
			let currentPage = data.searchresult.results.domain.page;
			let previousPage: number = currentPage;
			if (currentPage >= 2) {
				previousPage = previousPage - 1;
			}
			CaspardoAPI.getShopFilters(tabsArray, query, type, previousPage);
		});

		/**
		 * Event: Click
		 * Attach to: #shopShowMoreButton
		 * Show more products when click on button with this id
		 */
		jQuery("#shopShowMoreButton").unbind('click').bind("click",(evt) => {
			let query: string = <string>jQuery("#caspardo_searchfield_new").val();
			//query = query == "" ? 'all' : query;
			if (page < data.searchresult.results.domain.pages) {
				page += 1;
				CaspardoAPI.getMoreProducts(tabsArray, query, page);
			} else {
				jQuery("#shopShowMoreButton").hide();
			}
		});

		// Add minus icon for collapse element which is open by default
		$(".collapse.show").each(function () {
			$(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
		});

		// Toggle plus minus icon on show hide of collapse element
		$(".collapse").on('show.bs.collapse', function () {
			$(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
		}).on('hide.bs.collapse', function () {
			$(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
		});

		/**
		 * Event: Click
		 * Attach to: #shopApplyButton
		 * Bind / Unbind click event on apply filter button in shop tab
		 */
		jQuery("#shopApplyButton").unbind('click').bind("click", (evt) => {
			let query: string = <string>jQuery("#caspardo_searchfield_new").val();
			//query = query == "" ? 'all' : query;
			$("#shopShowMoreButton").hide();
			page = 1;
			CaspardoAPI.getShopTemplateFiltered(tabsArray, data, query);
			// if (data.searchresult.results.domain.filters.price.pricemax != 0) {
			// 	$("#slider-container")['slider']("values", 0, CaspardoAPI.productMinPrice);
			// 	$("#slider-container")['slider']("values", 1, CaspardoAPI.productMaxPrice);
			// }
		});

		/**
		 * Event: Click
		 * Attach to: #
		 * Bind / Unbind click event Reset filter button in shop tab
		 */
		jQuery("#shopResetButton").unbind('click').bind("click", (evt) => {
			CaspardoAPI.productMaxPrice = (data.searchresult.results.domain.filters.price.pricemax / 100).toString().split('.').join(',');
			CaspardoAPI.productMinPrice = (data.searchresult.results.domain.filters.price.pricemin / 100).toString().split('.').join(',');
			CaspardoAPI.selectedBrands = [];
			page = 1;
			jQuery("#shopShowMoreButton").show();
			CaspardoAPI.getShopFilters(tabsArray, query, type, page);
			$('#caspardo-shop-brand-filter input[type=checkbox]').removeAttr('checked');
			if (data.searchresult.results.domain.filters.price.pricemax != 0) {
				$("#slider-container")['slider']("values", 0, CaspardoAPI.productMinPrice);
				$("#slider-container")['slider']("values", 1, CaspardoAPI.productMaxPrice);
			}
			$("#amount").val(`${CaspardoAPI.productMinPrice} € - ${CaspardoAPI.productMaxPrice} €`);
		});

	}

	/**
	 * Get more product when you click on the "Show More Product" button
	 * @param tabsArray: List of Tabs (tabs defination),
	 * @param query: Response from API,
	 * @param page: Searched word from autocomplete search-slot,
	 */
	public static getMoreProducts(tabsArray, query, page) {

		CaspardoAPI.totalProd = [];
		
		let api_url_data :string=this.getApiCallFilter(query, page);

		// let api_url_data = CaspardoAPI.api_url + '/servlet/findIt?query=' + query + '&tab=shop&type=shop&searchengineid=' + CaspardoAPI.api_sid + '&output=json&pagetype=2&page=' + page + '&language=' + CaspardoAPI.defaultLanguage;
		
		// if (query==="")
		// 	api_url_data+="&all=true"
	
		var products;
		var jqxhr = jQuery.getJSON(api_url_data, function () {
			CaspardoLogger.log("success");
		})
			.done(function (data) {
				CaspardoAPI.listMoreProducts = data.searchresult.results.domain.resultlist.item;
				var productHtml = " ";

				products = CaspardoAPI.listMoreProducts;
				if (products) {
					for (var i = 0; i < products.length; i++) {
						let productRow: string = CaspardoAPI.config.searchresult_content.searchresult_product_views;
						productRow = productRow.replace(new RegExp('{products_picture}', 'g'), products[i].picture);
						productRow = productRow.replace(new RegExp('{products_url_to_basket}', 'g'), products[i].url_to_basket);
						productRow = productRow.replace(new RegExp('{products_title}', 'g'), products[i].title);
						productRow = productRow.replace(new RegExp('{products_price}', 'g'), products[i].price);
						productRow = productRow.replace(new RegExp('{article_number}', 'g'), products[i].articlenumber);
						productHtml += productRow;
					}
				}

				if (page === data.searchresult.results.domain.pages) {
					jQuery("#shopShowMoreButton").hide();
				}

				var res = $('#product-listing-div').append(`${productHtml}`);
				
				IN18.IN18Translation();

				//if (tabsArray.default_shop_view === "grid") {
					jQuery("#shop_grid").addClass("active");
					jQuery("#shop_list").hide();
					jQuery(".shoptab-grid-view").show();
					jQuery(".shoptab-list-view").hide();
				//}
				// if (tabsArray.default_shop_view === "list") {
				// 	jQuery("#shop_grid").hide();
				// 	jQuery("#shop_list").addClass("active");
				// 	jQuery(".shoptab-list-view").show();
				// 	jQuery(".shoptab-grid-view").hide();
				// }

				return res;
			});

	}

}