import { CaspardoAPI } from "../common/CaspardoAPI";
import { CaspardoTemplate } from "./CaspardoTemplate";

var moment = require('moment');
var caspardoConfig = require("../caspardoconfig.json");

export class CaspardoSelectedFilter {

    // Seminar Filters
    // Seminar General-Filters
    static initFilter: boolean = true;
    static seminar_filter_refresh: boolean = false;
    static seminar_titlehash: string = "";
    static search_tab_type: string = "";
    static search_tab_autocomplete_contenttype: string = "all";
    static seminar_filter_available: boolean = true;
    // Seminar-Dates
    static seminar_date_from: any = null;
    static seminar_date_to: any = null;
    // Seminar Arrays
    static seminar_kws: Array<any> = []; // selected calendar weeks
    static seminar_cities: Array<any> = []; // selected cities
    static seminar_themes: Array<any> = []; // selected seminars
    // Seminar Options
    static semianr_option_rhethorik_display: boolean = false;
    static semianr_option_rhethorik: boolean = false;
    static seminar_option_arbeitsgericht: boolean = false;
    static seminar_option_arbeitsgericht_display: boolean = false;
    static seminar_option_starterpaket: boolean = false;
    static seminar_option_starterpaket_display: boolean = false;
    static seminar_option_nonsmoke: boolean = false;
    static seminar_option_nonsmoke_display = false;
    static seminar_option_barrierefrei: boolean = false;
    static seminar_option_barrierefrei_display: boolean = false;
    static seminar_option_rollstuhlgerecht: boolean = false;
    static seminar_option_rollstuhlgericht_display: boolean = false;
    static seminar_option_bedingtbehindert: boolean = false;
    static seminar_option_bedingtbehindert_display: boolean = false;
    static grundlagenstarterpaket_display: boolean = false;
    static grundlagenstarterpaket: boolean = false;

    // Content Filter
    static content_domains: Array<any> = [];

    // Shop Filter
    static shop_price_min:number=0;
    static shop_price_max:number=0;

    static attribute_filters:any[][] = [ [], [] ];  

    public static addShopAttributeFilter(type:string, filter:any){
        CaspardoSelectedFilter.attribute_filters[type].push(filter);
    }

    /**
     * Clears the Dateinput-fields (from + to) and resets the selected filters.
     */
    public static clearDateInputFields() {
        CaspardoSelectedFilter.seminar_date_from = null;
        CaspardoSelectedFilter.seminar_date_to = null;
        jQuery("#caspardo_seminardate_toval").val('');
        jQuery("#caspardo_seminardate_fromval").val('');
    }
    /**
     * Creates the date-filters and appends it to the document. 
     * After html is inserted, the events will be defined.
     */
    public static createDateFilters() {

        // Remove at first the selected date filter area.
        jQuery("#caspardo_filterdate_id").remove();

        // Only if a Date is set then do things!
        if (CaspardoSelectedFilter.seminar_date_from != null) {
            if (CaspardoSelectedFilter.seminar_date_to != null) // Set Date (from -> to)
            {
                let insertTxt: string = CaspardoTemplate.config.searchresult_seminar.selectedfilter_datefromtorow.replace(new RegExp('{value}', 'g'), "");
                insertTxt = insertTxt.replace(new RegExp('{date_from}', 'g'), CaspardoSelectedFilter.seminar_date_from);
                insertTxt = insertTxt.replace(new RegExp('{date_to}', 'g'), CaspardoSelectedFilter.seminar_date_to);
                jQuery("#caspardo_filter_selected_panel").append(insertTxt);
            }
            else // Set Date (from)
            {
                let insertTxt: string = CaspardoTemplate.config.searchresult_seminar.selectedfilter_datefromrow.replace(new RegExp('{value}', 'g'), "");
                insertTxt = insertTxt.replace(new RegExp('{date_from}', 'g'), CaspardoSelectedFilter.seminar_date_from);
                jQuery("#caspardo_filter_selected_panel").append(insertTxt);
            }
        }

        // Add the event to the date-filter-panel.
        jQuery("#caspardo_filterdate_id").click((evt) => {
            CaspardoSelectedFilter.clearDateInputFields();
            CaspardoSelectedFilter.seminar_filter_refresh = true;
            jQuery("#caspardo_filterdate_id").remove();
            let query: string = <string>jQuery("#caspardo_searchfield_new").val();
            CaspardoAPI.searchSeminar(query, 2, 1, true);
        });
    }

    /**
     * Initialize the date-filter (from-to)
     */
    public static setDateFilter() {
        let doIt: boolean = (CaspardoSelectedFilter.seminar_date_from != null) && (CaspardoSelectedFilter.seminar_date_to != null);
        if (doIt) {
            let date_compare_from = moment(CaspardoSelectedFilter.seminar_date_from, "DD.MM.YYYY");
            let date_compare_to = moment(CaspardoSelectedFilter.seminar_date_to, "DD.MM.YYYY");
            let startDateSeminare = new Date();
            startDateSeminare.setDate(startDateSeminare.getDate());
            if (date_compare_to.isBefore(date_compare_from)) {
                (<any>jQuery("#caspardo_seminardate_to")).datepicker({
                    format: 'dd.mm.yyyy',
                    autoclose: true,
                    language: 'de',
                    startDate: startDateSeminare
                }).datepicker('update', CaspardoSelectedFilter.seminar_date_from);
                CaspardoSelectedFilter.seminar_date_to = <string>jQuery("#caspardo_seminardate_toval").val();
            }
            if (date_compare_from.isAfter(date_compare_to)) {
                (<any>jQuery("#caspardo_seminardate_fromval")).datepicker({
                    format: 'dd.mm.yyyy',
                    autoclose: true,
                    language: 'de',
                    startDate: startDateSeminare
                }).datepicker('update', CaspardoSelectedFilter.seminar_date_to);
                CaspardoSelectedFilter.seminar_date_from = <string>jQuery("#caspardo_seminardate_fromval").val();
            }
        }
        doIt = (CaspardoSelectedFilter.seminar_date_from != null) && (CaspardoSelectedFilter.seminar_date_to === null);
        if (doIt) {
            let startDateSeminare = new Date();
            startDateSeminare.setDate(startDateSeminare.getDate());
            let date_compare_from = moment(CaspardoSelectedFilter.seminar_date_from, "DD.MM.YYYY");
            (<any>jQuery("#caspardo_seminardate_to")).datepicker({
                format: 'dd.mm.yyyy',
                autoclose: true,
                language: 'de',
                startDate: startDateSeminare
            }).datepicker('update', CaspardoSelectedFilter.seminar_date_from);
        }
    }

    /**
     * Adds one domain to the domain-filter.
     * @param domain 
     */
    public static addDomain(domain: any) {
        CaspardoSelectedFilter.content_domains.push(domain);
    }

    /**
     * Deletes one domain of the domain-filter.
     * @param domain 
     */
    public static deleteDomain(domain: any) {
        let pos: number = <number>CaspardoSelectedFilter.content_domains.indexOf(domain);
        CaspardoSelectedFilter.content_domains.splice(pos, 1);
    }

    /**
     * Sets the filter-loader.
     * @param type 
     * @param hits 
     */
    public static setSeminarFilterLoader(type: number, hits: number) {
        if (type === 1)
            jQuery("#caspardo_seminarfilter_counterinfo").html('<div class="caspardo_loader_filter"></div>  <span class="caspardo_filter_countertxt"> Termine</span>');
        else
            jQuery("#caspardo_seminarfilter_counterinfo").html('<div><span id="caspardo_filter_counter">' + hits + '</span> <span class="caspardo_filter_countertxt">&nbsp;Termine</span></div>');
    }

    /**
     * Adds one city to the selected elements.
     * @param city 
     */
    public addSeminarCity(city: any) {
        CaspardoSelectedFilter.seminar_cities.push(city);
    }
    /**
     * Sets the selected date-from.
     * @param from 
     */
    public setSeminarDateFrom(from: any) {
        CaspardoSelectedFilter.seminar_date_from = from;
    }

    /**
     * Sets the selected date-to.
     * @param to 
     */
    public setSeminarDatoTo(to: any) {
        CaspardoSelectedFilter.seminar_date_to = to;
    }

    /**
     * Add Seminar-Calender-Week.
     * @param cw 
     */
    public addSeminarCalendarWeek(cw: any) {
        CaspardoSelectedFilter.seminar_kws.push(cw);
    }


    /**
     * Resetting the seminar filters.
     */

    public static resetFilters() {

        // Reset the seminars.
        CaspardoSelectedFilter.seminar_themes = [];
        // Ensure do it only on initial first call.
        let initStatus: boolean = CaspardoSelectedFilter.initFilter;
        if ((CaspardoTemplate.parsed.seminartitlehash) && (initStatus)) {
            CaspardoSelectedFilter.seminar_themes.push(CaspardoTemplate.parsed.seminartitlehash);
            CaspardoSelectedFilter.initFilter = false;
        }
        // Reset the cities
        CaspardoSelectedFilter.seminar_cities = [];
        if ((CaspardoTemplate.httpPara_city) && (initStatus)) {
            CaspardoSelectedFilter.seminar_cities.push(CaspardoTemplate.httpPara_city);
            CaspardoSelectedFilter.initFilter = false;
        }
        //Reset the dates
        CaspardoSelectedFilter.seminar_date_from = null;
        if (initStatus)
            CaspardoSelectedFilter.seminar_date_from = CaspardoTemplate.httpPara_date_from;

        CaspardoSelectedFilter.seminar_date_to = null;
        if (initStatus)
            CaspardoSelectedFilter.seminar_date_to = CaspardoTemplate.httpPara_date_to;

        CaspardoSelectedFilter.seminar_kws = [];
        CaspardoSelectedFilter.seminar_titlehash = "";
        CaspardoSelectedFilter.seminar_option_arbeitsgericht = false;
        CaspardoSelectedFilter.seminar_option_starterpaket = false;
        CaspardoSelectedFilter.seminar_option_nonsmoke = false;
        CaspardoSelectedFilter.semianr_option_rhethorik = false;
        CaspardoSelectedFilter.seminar_option_barrierefrei = false;
        CaspardoSelectedFilter.seminar_option_rollstuhlgerecht = false;
        CaspardoSelectedFilter.seminar_option_bedingtbehindert = false;
        jQuery("#caspardo_seminar_option_arbeitsgericht").removeClass("caspardo-option-selected");
        jQuery("#caspardo_seminar_option_nichtraucher").removeClass("caspardo-option-selected");
        jQuery("#caspardo_seminar_option_starterpaket").removeClass("caspardo-option-selected");
        jQuery("#caspardo_seminar_option_rhethorik").removeClass("caspardo-option-selected");
        jQuery("#caspardo_seminar_option_bedingtbehindert").removeClass("caspardo-option-selected");
        jQuery("#caspardo_seminar_option_rollstuhlgerecht").removeClass("caspardo-option-selected");
        jQuery("#caspardo_seminar_option_barrierefrei").removeClass("caspardo-option-selected");
        jQuery("#caspardo_seminardate_fromval").val("");
        jQuery("#caspardo_seminardate_toval").val("");
        jQuery("#caspardo_btn_seminarnr").val("");
        jQuery("#caspardo_btn_seminarwebcode").val("");
        var date_input: any = (<any>$('input[name="caspardo_seminardate"]')); //our date input has the name "date"
        let startDateSeminare = new Date();
        startDateSeminare.setDate(startDateSeminare.getDate());
        var options = {
            format: 'dd.mm.yyyy',
            todayHighlight: true,
            autoclose: true,
            language: 'de',
            startDate: startDateSeminare
        };
        date_input.datepicker(options);
        (<any>jQuery("#caspardo_seminardate_to")).datepicker(options);
        (<any>jQuery("#caspardo_seminardate")).datepicker(options);
        jQuery("#caspardo_filter_selected_panel").html("");
    }

    public static setEnable(enable: boolean) {
        if (enable) {
            $("#caspardo_select_seminarcities_search_dropdownid").prop("disabled", false);
            $("#caspardo_seminardate_fromval").prop("disabled", false);
            $("#caspardo_seminardate_toval").prop("disabled", false);
            $("#caspardo_select_seminarkws1_search_dropdownid").prop("disabled", false);
            $("#caspardo_btn_seminarfilter").show();
            $("#caspardo_seminar_option_starterpaket").show();
            $("#caspardo_seminar_option_nichtraucher").show();
            $("#caspardo_seminar_option_arbeitsgericht").show();
            $("#caspardo_seminar_option_rhethorik").show();
            $("#caspardo_seminar_option_bedingtbehindert").show();
            $("#caspardo_seminar_option_rollstuhlgerecht").show();
            $("#caspardo_seminar_option_barrierefrei").show();
        } else {
            $("#caspardo_select_seminarcities_search_dropdownid").prop("disabled", true);
            $("#caspardo_seminardate_fromval").prop("disabled", true);
            $("#caspardo_seminardate_toval").prop("disabled", true);
            $("#caspardo_select_seminarkws1_search_dropdownid").prop("disabled", true);
            $("#caspardo_btn_seminarfilter").hide();
            $("#caspardo_seminar_option_starterpaket").hide();
            $("#caspardo_seminar_option_nichtraucher").hide();
            $("#caspardo_seminar_option_arbeitsgericht").hide();
            $("#caspardo_seminar_option_rhethorik").hide();
            $("#caspardo_seminar_option_bedingtbehindert").hide();
            $("#caspardo_seminar_option_rollstuhlgerecht").hide();
            $("#caspardo_seminar_option_barrierefrei").hide();
        }
    }

    public preSearch() {
    }

    public static printSeletedShopFilters(){
        if (caspardoConfig.debug_mode)
        {
            console.log("PriceRange-Min:"+CaspardoSelectedFilter.shop_price_min);
            console.log("PriceRange-Max:"+CaspardoSelectedFilter.shop_price_max);
        }
    }
}