import { CaspardoDropDown } from "../../components/CaspardoDropDown";
import { CaspardoTemplate } from "../../template/CaspardoTemplate";
import { CaspardoDate } from "../../common/CaspardoDate";
import { CaspardoAPI } from "../../common/CaspardoAPI";
import { CaspardoSelectedFilter } from "../../template/CaspardoSelectedFilter";
export class CaspardoSeminarResults {

  jsonResult: any;
  arSeminarThemes: Array<any> = [];
  static filter_seminardate_from: string;
  static filter_seminardate_to: string;
  static filter_seminarcity = [];
  static filter_seminarcw = [];

  /**
   * Constructor for the SeminarResults
   * @param jsonResult Current serach result
   */
  constructor(jsonResult: any) {
    this.jsonResult = jsonResult;
  }

  /**
   * Generates the Filter.
   */
  public generateFilter() {
    let arrCalendarWeeks: Array<string> = [];
    let arCityIds: Array<string> = [];
    let arCityNames: Array<string> = [];
    let arCityQueries: Array<string> = [];
    let arSeminarIds: Array<string> = [];
    let arSeminarNames: Array<string> = [];
    let arSeminarQueries: Array<string> = [];
    let arSeminarTopFlag: Array<string> = [];

    for (var i = 0; i < this.jsonResult.searchresult.header.filters.filter.length; i++) {
      // Loop throught the filters.
      if (this.jsonResult.searchresult.header.filters.filter[i].name === "city") {
        if (typeof this.jsonResult.searchresult.header.filters.filter[i].value != 'undefined') {
          if (!(this.jsonResult.searchresult.header.filters.filter[i].value instanceof Array)) {
            var tempArray = [];
            tempArray.push(this.jsonResult.searchresult.header.filters.filter[i].value);
            this.jsonResult.searchresult.header.filters.filter[i].value = tempArray;
          }
          for (var j = 0; j < this.jsonResult.searchresult.header.filters.filter[i].value.length; j++) {
            arCityIds.push(this.jsonResult.searchresult.header.filters.filter[i].value[j].id);
            arCityNames.push(this.jsonResult.searchresult.header.filters.filter[i].value[j].content);
            arCityQueries.push(this.jsonResult.searchresult.header.filters.filter[i].value[j].query);
          }
          CaspardoTemplate.caspardoSeminarCityDropDown = new CaspardoDropDown("caspardo_select_seminarcities", "seminar_cities", "Stadt", arCityIds, arCityNames, arCityQueries);
          if (CaspardoTemplate.parsed.city) {
            CaspardoTemplate.caspardoSeminarCityDropDown.addSelectedObj(CaspardoTemplate.parsed.city);
          }
          CaspardoTemplate.caspardoSeminarCityDropDown.toHTML(false);
        }
      }

      if (this.jsonResult.searchresult.header.filters.filter[i].name === "weeknumbers") {
        if (typeof this.jsonResult.searchresult.header.filters.filter[i].value != 'undefined') {
          if (!(this.jsonResult.searchresult.header.filters.filter[i].value instanceof Array)) {
            var tempArray = [];
            tempArray.push(this.jsonResult.searchresult.header.filters.filter[i].value);
            this.jsonResult.searchresult.header.filters.filter[i].value = tempArray;
          }
          for (var j = 0; j < this.jsonResult.searchresult.header.filters.filter[i].value.length; j++) {
            arrCalendarWeeks.push(this.jsonResult.searchresult.header.filters.filter[i].value[j].query);
          }
          let dcom: CaspardoDate = new CaspardoDate("caspardo_kw_id");
          let calenderWeekSortedList = dcom.generateResultCWSortedObjectList(arrCalendarWeeks);
          let resHTML = dcom.generateResultCalendarWeeks(arrCalendarWeeks);
          let resKWArray = dcom.generateResulrArrayValueList(arrCalendarWeeks);
          CaspardoTemplate.caspardoSeminarCalendarWeekDropDown = new CaspardoDropDown("caspardo_select_seminarkws1", "seminar_calweeks", "KW", resKWArray, resKWArray, resKWArray);
          CaspardoTemplate.caspardoSeminarCalendarWeekDropDown.toHTML(false);
        }
      }

      if (this.jsonResult.searchresult.header.filters.filter[i].name === "seminartitlehash") {

        if (typeof this.jsonResult.searchresult.header.filters.filter[i].value != 'undefined') {
          if (!(this.jsonResult.searchresult.header.filters.filter[i].value instanceof Array)) {
            var tempArray = [];
            tempArray.push(this.jsonResult.searchresult.header.filters.filter[i].value);
            this.jsonResult.searchresult.header.filters.filter[i].value = tempArray;
          }
          for (var j = 0; j < this.jsonResult.searchresult.header.filters.filter[i].value.length; j++) {
            if (typeof this.jsonResult.searchresult.header.filters.filter[i].value[j].content != 'undefined') {
              this.arSeminarThemes.push(this.jsonResult.searchresult.header.filters.filter[i].value[j]);
              arSeminarIds.push(this.jsonResult.searchresult.header.filters.filter[i].value[j].id);
              arSeminarNames.push(this.jsonResult.searchresult.header.filters.filter[i].value[j].content);
              arSeminarQueries.push(this.jsonResult.searchresult.header.filters.filter[i].value[j].query);

              // find the seminar with the hashtitle
              var t = CaspardoTemplate.seminarData.find(seminar => seminar.seminar_search_hash === this.jsonResult.searchresult.header.filters.filter[i].value[j].query);
              let seminarTopFlag: string = "false"; // Default value
              if (t)
                arSeminarTopFlag.push(t.topseminar);
              else
                arSeminarTopFlag.push(seminarTopFlag);
            }
          }
        }
        CaspardoTemplate.caspardoSeminarDropDown = new CaspardoDropDown("caspardo_select_seminartheme", "seminar_themes", "Seminare", arSeminarIds, arSeminarNames, arSeminarQueries);
        if (CaspardoTemplate.parsed.seminartitlehash) {
          CaspardoTemplate.caspardoSeminarDropDown.addSelectedObj(CaspardoTemplate.parsed.seminartitlehash);
        }
        CaspardoTemplate.caspardoSeminarDropDown.setTopFlagArray(arSeminarTopFlag);
        CaspardoTemplate.caspardoSeminarDropDown.sortByValues();
        //CaspardoTemplate.caspardoSeminarDropDown.sortCurrentValues();
        CaspardoTemplate.caspardoSeminarDropDown.toHTML(true);
      }

      if (this.jsonResult.searchresult.header.filters.filter[i].name === "option") {
        if (typeof this.jsonResult.searchresult.header.filters.filter[i].value != 'undefined') {
          if (!(this.jsonResult.searchresult.header.filters.filter[i].value instanceof Array)) {
            var tempArray = [];
            tempArray.push(this.jsonResult.searchresult.header.filters.filter[i].value);
            this.jsonResult.searchresult.header.filters.filter[i].value = tempArray;
          }
          for (var j = 0; j < this.jsonResult.searchresult.header.filters.filter[i].value.length; j++) {
            if (typeof this.jsonResult.searchresult.header.filters.filter[i].value[j].query != 'undefined') {
              if (this.jsonResult.searchresult.header.filters.filter[i].value[j].query === "behindertengerechtbedingt") {
                CaspardoSelectedFilter.seminar_option_bedingtbehindert_display = true;
              }
              if (this.jsonResult.searchresult.header.filters.filter[i].value[j].query === "grundlagenstarterpaket") {
                CaspardoSelectedFilter.grundlagenstarterpaket_display = true;
              }
              if (this.jsonResult.searchresult.header.filters.filter[i].value[j].query === "behindertengerecht") {
                CaspardoSelectedFilter.seminar_option_bedingtbehindert_display = true;
              }
              if (this.jsonResult.searchresult.header.filters.filter[i].value[j].query === "behindertengerechtvoll") {
                CaspardoSelectedFilter.seminar_option_rollstuhlgericht_display = true;
              }
              if (this.jsonResult.searchresult.header.filters.filter[i].value[j].query === "behindertengerechtbarrierefrei") {
                CaspardoSelectedFilter.seminar_option_barrierefrei_display = true;
              }
            }
          }
        }
      }

      if (this.jsonResult.searchresult.header.filters.filter[i].type === "rhetorikseminar") {
        CaspardoSelectedFilter.semianr_option_rhethorik_display = this.jsonResult.searchresult.header.filters.filter[i].display;
      }

      if (this.jsonResult.searchresult.header.filters.filter[i].type === "grundlagenstarterpaket") {
        CaspardoSelectedFilter.seminar_option_starterpaket_display = this.jsonResult.searchresult.header.filters.filter[i].display;
      }

      if (this.jsonResult.searchresult.header.filters.filter[i].type === "nichtraucherseminar") {
        CaspardoSelectedFilter.seminar_option_nonsmoke_display = this.jsonResult.searchresult.header.filters.filter[i].display;
      }

      if (this.jsonResult.searchresult.header.filters.filter[i].type === "gerichtsverhandlung") {
        CaspardoSelectedFilter.seminar_option_arbeitsgericht_display = this.jsonResult.searchresult.header.filters.filter[i].display;
      }
      

    }

    CaspardoSelectedFilter.createDateFilters();

    // Set the hits
    jQuery("#caspardo_filter_counter").html(this.jsonResult.searchresult.header.hits);

    // Remove the options
    jQuery(".caspardo_seminar_option").each(function () {
      $(this).remove();
    });

    // Add Option Starterpackage
   
    if (CaspardoSelectedFilter.grundlagenstarterpaket_display)
    //  $("#caspardo_filter_seminaroptions").append('<td class="caspardo_filter_eigenschaften_padding caspardo_seminar_option"><i id="caspardo_seminar_option_starterpaket" class="ifb-icon-rocket caspardo_seminar_filter_iconsize" data-toggle="tooltip" data-placement="top" title="Starterpaket" data-original-title="Starterpaket"></i></td>');
    // Add Option Behinderten Gerecht Bedingt  
    if (CaspardoSelectedFilter.seminar_option_bedingtbehindert_display) {
      $("#caspardo_filter_seminaroptions").append(' <td class="caspardo_filter_eigenschaften_padding caspardo_seminar_option"><i id="caspardo_seminar_option_bedingtbehindert" class="ifb-icon-conditioned-accessible caspardo_seminar_filter_iconsize" data-toggle="tooltip" data-placement="top" title="Bedingt Rollstuhlgerecht" data-original-title="Bedingt Rollstuhlgerecht"></i></td>');
    }
    if (CaspardoSelectedFilter.seminar_option_rollstuhlgericht_display) {
      $("#caspardo_filter_seminaroptions").append(' <td class="caspardo_filter_eigenschaften_padding caspardo_seminar_option"><i id="caspardo_seminar_option_rollstuhlgerecht" class="ifb-icon-disabled caspardo_seminar_filter_iconsize" data-toggle="tooltip" data-placement="top" title="Rollstuhlgerecht" data-original-title="Rollstuhlgerecht"></i></td>');
    }
    if (CaspardoSelectedFilter.seminar_option_barrierefrei_display) {
      $("#caspardo_filter_seminaroptions").append(' <td class="caspardo_filter_eigenschaften_padding caspardo_seminar_option"><i id="caspardo_seminar_option_barrierefrei" class="ifb-icon-accessible caspardo_seminar_filter_iconsize" data-toggle="tooltip" data-placement="top" title="Barrierefrei" data-original-title="Barrierefrei"></i></td>');
    }


    // Add Option Non Smoke  
    if (CaspardoSelectedFilter.seminar_option_nonsmoke_display)
      $("#caspardo_filter_seminaroptions").append('<td class="caspardo_filter_eigenschaften_padding caspardo_seminar_option"><i id="caspardo_seminar_option_nichtraucher" class="ifb-icon-nosmoke caspardo_seminar_filter_iconsize" data-toggle="tooltip" data-placement="top" title="Nichtraucher" data-original-title="Nichtraucher"></i></td>');

    if (CaspardoSelectedFilter.seminar_option_arbeitsgericht_display)
      $("#caspardo_filter_seminaroptions").append('<td class="caspardo_filter_eigenschaften_padding caspardo_seminar_option"><i id="caspardo_seminar_option_arbeitsgericht" class="ifb-icon-libra caspardo_seminar_filter_iconsize" data-toggle="tooltip" data-placement="top" title="Arbeitsgericht live" data-original-title="Arbeitsgericht live"></i></td>');

    if (CaspardoSelectedFilter.semianr_option_rhethorik_display)
      $("#caspardo_filter_seminaroptions").append('<td class="caspardo_filter_eigenschaften_padding caspardo_seminar_option"><i id="caspardo_seminar_option_rhethorik" class="ifb-icon-route caspardo_seminar_filter_iconsize" data-toggle="tooltip" data-placement="top" title="Rhetorikstudio" data-original-title="Rhetorikstudio"></i></td>');

    let query: string = <string>jQuery("#caspardo_searchfield_new").val();

    jQuery("#caspardo_seminar_option_arbeitsgericht").click((evt) => {
      if (jQuery("#caspardo_seminar_option_arbeitsgericht").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_arbeitsgericht").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_arbeitsgericht = false;
      } else {
        jQuery("#caspardo_seminar_option_arbeitsgericht").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_arbeitsgericht = true;
      }
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchSeminar(query, 2, 1, true);
    });

    jQuery("#caspardo_seminar_option_rollstuhlgerecht").click((evt) => {
      if (jQuery("#caspardo_seminar_option_rollstuhlgerecht").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_rollstuhlgerecht").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_rollstuhlgerecht = false;
      } else {
        jQuery("#caspardo_seminar_option_rollstuhlgerecht").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_rollstuhlgerecht = true;
      }
      CaspardoAPI.searchSeminar(query, 2, 1, false);
    });

    jQuery("#caspardo_seminar_option_bedingtbehindert").click((evt) => {
      if (jQuery("#caspardo_seminar_option_bedingtbehindert").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_bedingtbehindert").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_bedingtbehindert = false;
      } else {
        jQuery("#caspardo_seminar_option_bedingtbehindert").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_bedingtbehindert = true;
      }
      CaspardoSelectedFilter.seminar_filter_refresh = false;
      CaspardoAPI.searchSeminar(query, 2, 1, false);
    });

    jQuery("#caspardo_seminar_option_barrierefrei").click((evt) => {
      if (jQuery("#caspardo_seminar_option_barrierefrei").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_barrierefrei").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_barrierefrei = false;
      } else {
        jQuery("#caspardo_seminar_option_barrierefrei").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_barrierefrei = true;
      }
      CaspardoAPI.searchSeminar(query, 2, 1, false);
    });

    jQuery("#caspardo_seminar_option_nichtraucher").click((evt) => {
      if (jQuery("#caspardo_seminar_option_nichtraucher").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_nichtraucher").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_nonsmoke = false;
      } else {
        jQuery("#caspardo_seminar_option_nichtraucher").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_nonsmoke = true;
      }
      CaspardoAPI.searchSeminar(query, 2, 1, false);
    });

    jQuery("#caspardo_seminar_option_starterpaket").click((evt) => {
      if (jQuery("#caspardo_seminar_option_starterpaket").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_starterpaket").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_starterpaket = false;
      } else {
        jQuery("#caspardo_seminar_option_starterpaket").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_starterpaket = true;
      }
      CaspardoAPI.searchSeminar(query, 2, 1, false);
    });

    jQuery("#caspardo_seminar_option_rhethorik").click((evt) => {
      if (jQuery("#caspardo_seminar_option_rhethorik").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_rhethorik").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.semianr_option_rhethorik = false;
      } else {
        jQuery("#caspardo_seminar_option_rhethorik").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.semianr_option_rhethorik = true;
      }
      CaspardoAPI.searchSeminar(query, 2, 1, false);
    });

  }

  /**
   * Generates the no-hits-info.
   */
  public generateNoHitsInfo() {
    let msg: string = "<strong>Leider konnten wir keine Seminartermine für Ihre Suche finden.</strong>";
    jQuery("#caspardo_results").html(msg);
    jQuery("#caspardo_appointmentlist").html("");
    CaspardoSelectedFilter.setSeminarFilterLoader(2, 0);
  }

  /**
   * Creates the general serminar view.
   */
  public generateSeminarList(addSemainarlist: boolean) {
    jQuery("#caspardo_results").html("");
    if (addSemainarlist) {
      let arTop = [];
      let arContains = [];
      let arRest = [];
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      if (!query)
        query = "";
      // Top Seminars
      for (var i = 0; i < this.arSeminarThemes.length; i++) {
        if (this.arSeminarThemes[i].topseminar_rank > 0)
          arTop.push(this.arSeminarThemes[i]);
        else {
          let contain = (this.arSeminarThemes[i].content.toLowerCase().indexOf(query.toLowerCase()) >= 0)
          if (contain)
            arContains.push(this.arSeminarThemes[i]);
          else
            arRest.push(this.arSeminarThemes[i]);
        }
      }
      let resHTML = "";
      for (var i = 0; i < arTop.length; i++) {
        let insertRow = CaspardoAPI.config.searchresult_seminar.row.replace(new RegExp('<caspardo_seminartheme>', 'g'), arTop[i].content);
        insertRow = insertRow.replace(new RegExp('<caspardo_theme_id>', 'g'), arTop[i].themeid);
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarhash>', 'g'), arTop[i].query);
        resHTML += insertRow;
      }
      for (var i = 0; i < arContains.length; i++) {
        let insertRow = CaspardoAPI.config.searchresult_seminar.row.replace(new RegExp('<caspardo_seminartheme>', 'g'), arContains[i].content);
        insertRow = insertRow.replace(new RegExp('<caspardo_theme_id>', 'g'), arContains[i].themeid);
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarhash>', 'g'), arContains[i].query);
        resHTML += insertRow;
      }
      for (var i = 0; i < arRest.length; i++) {
        let insertRow = CaspardoAPI.config.searchresult_seminar.row.replace(new RegExp('<caspardo_seminartheme>', 'g'), arRest[i].content);
        insertRow = insertRow.replace(new RegExp('<caspardo_theme_id>', 'g'), arRest[i].themeid);
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarhash>', 'g'), arRest[i].query);
        resHTML += insertRow;
      }

      jQuery("#caspardo_results").html(resHTML);

    }

    this.generateAppointmentList("general");
  }

  /** 
   * Appends one appointment page.
   * @param view 
   */
  public appendLoadPage(view: string) {
    jQuery("#caspardo_seminar_nextpage").remove();
    if (!(this.jsonResult.searchresult.results.appointmentlist.appointment instanceof Array)) {
      var tempArray = [];
      tempArray.push(this.jsonResult.searchresult.results.appointmentlist.appointment);
      this.jsonResult.searchresult.results.appointmentlist.appointment = tempArray;
    }
    let resHTML: string = "";
    for (var i = 0; i < this.jsonResult.searchresult.results.appointmentlist.appointment.length; i++) {

      let insertRow = CaspardoAPI.config.searchresult_seminar.appointment_row.replace(new RegExp('<caspardo_seminardate>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].from + " - " + this.jsonResult.searchresult.results.appointmentlist.appointment[i].to);
      insertRow = insertRow.replace(new RegExp('<caspardo_seminarname>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].title);
      insertRow = insertRow.replace(new RegExp('<caspardo_seminarcity>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].city);
      insertRow = insertRow.replace(new RegExp('<caspardo_seminarurl>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].appointmenturl);
      insertRow = insertRow.replace(new RegExp('<caspardo_seminardauer>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].duration);
      insertRow = insertRow.replace(new RegExp('<caspardo_seminarnr>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].number);
      insertRow = insertRow.replace(new RegExp('<caspardo_seminarnapi_id>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].seminarapiid);
      if (this.jsonResult.searchresult.results.appointmentlist.appointment[i].hotel != "")
        insertRow = insertRow.replace(new RegExp('<appointment_option_hotel>', 'g'), CaspardoAPI.config.searchresult_seminar.appointment_option_hotel);
      else
        insertRow = insertRow.replace(new RegExp('<appointment_option_hotel>', 'g'), "");
      insertRow = insertRow.replace(new RegExp('<caspardo_seminarhotel>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].hotel);
      insertRow = insertRow.replace(new RegExp('<caspardo_hotellink>', 'g'), this.jsonResult.searchresult.results.appointmentlist.appointment[i].hotellink);
      
      // handicapped, both options: behindertengerecht + behindertengerechtvoll should dispaly the icon
      let find = this.jsonResult.searchresult.results.appointmentlist.appointment[i].options.indexOf("behindertengerecht ");
      if (find < 0)
        find = this.jsonResult.searchresult.results.appointmentlist.appointment[i].options.indexOf("behindertengerechtVoll ");
      if (find >= 0)
        insertRow = insertRow.replace(new RegExp('<appointment_option_handicapped>', 'g'), CaspardoAPI.config.searchresult_seminar.appointment_option_handicapped);
      else
        insertRow = insertRow.replace(new RegExp('<appointment_option_handicapped>', 'g'), "");

        // Option: behindertengerechtbedingt
      find = this.jsonResult.searchresult.results.appointmentlist.appointment[i].options.indexOf("behindertengerechtBedingt");
      if (find >= 0)
        insertRow = insertRow.replace(new RegExp('<appointment_option_parthandicapped>', 'g'), CaspardoAPI.config.searchresult_seminar.appointment_option_parthandicapped);
      else
        insertRow = insertRow.replace(new RegExp('<appointment_option_parthandicapped>', 'g'), "");

        // Option: behindertengerechtbarrierefrei
      find = this.jsonResult.searchresult.results.appointmentlist.appointment[i].options.indexOf("behindertengerechtBarrierefrei");
      if (find >= 0)
        insertRow = insertRow.replace(new RegExp('<appointment_option_accessible>', 'g'), CaspardoAPI.config.searchresult_seminar.appointment_option_accessible);
      else
        insertRow = insertRow.replace(new RegExp('<appointment_option_accessible>', 'g'), "");

        // Option: Law
      if (this.jsonResult.searchresult.results.appointmentlist.appointment[i].law)
        insertRow = insertRow.replace(new RegExp('<appointment_option_court>', 'g'), CaspardoAPI.config.searchresult_seminar.appointment_option_court);
      else
        insertRow = insertRow.replace(new RegExp('<appointment_option_court>', 'g'), "");

        // Option: Grundlagenstarterpaket
      find = this.jsonResult.searchresult.results.appointmentlist.appointment[i].options.indexOf("grundlagenstarterpaket");
      if (find >= 0)
        insertRow = insertRow.replace(new RegExp('<appointment_option_startpackage>', 'g'), CaspardoAPI.config.searchresult_seminar.appointment_option_startpackage);
      else
        insertRow = insertRow.replace(new RegExp('<appointment_option_startpackage>', 'g'), "");

        // Option: Speechseminar
      if (this.jsonResult.searchresult.results.appointmentlist.appointment[i].speechseminar)
        insertRow = insertRow.replace(new RegExp('<appointment_option_rhetoricstudio>', 'g'), CaspardoAPI.config.searchresult_seminar.appointment_option_rhetoricstudio);
      else
        insertRow = insertRow.replace(new RegExp('<appointment_option_rhetoricstudio>', 'g'), "");

        // Option: Nonsmoke
      if (this.jsonResult.searchresult.results.appointmentlist.appointment[i].nonsmoker)
        insertRow = insertRow.replace(new RegExp('<appointment_option_nonsmoke>', 'g'), CaspardoAPI.config.searchresult_seminar.appointment_option_nonsmoke);
      else
        insertRow = insertRow.replace(new RegExp('<appointment_option_nonsmoke>', 'g'), "");

        // Color Row
      if (i % 2 == 1)
        insertRow = insertRow.replace(new RegExp('<caspardo_rowclass>', 'g'), "bg-l-grey");
      else
        insertRow = insertRow.replace(new RegExp('<caspardo_rowclass>', 'g'), "");

        // Bookingstatus
      if (this.jsonResult.searchresult.results.appointmentlist.appointment[i].bookingstatus === "frei") {
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarampel>', 'g'), "ifb-tl-green");
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarampeltxt>', 'g'), "Dieses Seminar hat nur noch Plätze frei");
      }
      else if (this.jsonResult.searchresult.results.appointmentlist.appointment[i].bookingstatus === "ausgebucht") {
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarampel>', 'g'), "ifb-tl-red");
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarampeltxt>', 'g'), "Dieses Seminar ist ausgebucht");
      }
      else {
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarampel>', 'g'), "ifb-tl-yellow");
        insertRow = insertRow.replace(new RegExp('<caspardo_seminarampeltxt>', 'g'), "Dieses Seminar hat nur noch wenige Plätze frei");
      }

      // Add the row
      resHTML += insertRow;


    }
    if (this.jsonResult.searchresult.header.page > 1)
      jQuery("#caspardo_appointmentlist").append(resHTML);
    else
      jQuery("#caspardo_appointmentlist").html(resHTML);


    let doIt: boolean = ((this.jsonResult.searchresult.header.page + 1) <= this.jsonResult.searchresult.header.pages);
    if (doIt) {
      jQuery("#caspardo_appointmentlist").append(CaspardoAPI.config.searchresult_seminar.appointment_nextpage);
    }
    if (CaspardoTemplate.config.searchengine_mode != "dev") {
      for (var i = 0; i < this.jsonResult.searchresult.results.appointmentlist.appointment.length; i++) {
        let api_call: string = "https://api.ifb.de/odata/public/TermineBuchungsstatus(" + this.jsonResult.searchresult.results.appointmentlist.appointment[i].seminarapiid + ")";
        var jqxhr = jQuery.getJSON(api_call, function () {
          console.log("success");
        })
          .done(function (data) {
            console.log(data);
            let resHTML: string = '<i class="<caspardo_seminarampel> fs-24 va-m caspardo-ifb-loadstatus" data-appointment-apiid="<caspardo_seminarnapi_id>" title="<caspardo_seminarampeltxt>"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>';
            let insertRow: string = "";
            if (data.frei > 0) {
              if (data.status === 0)
                insertRow = resHTML.replace(new RegExp('<caspardo_seminarampel>', 'g'), "ifb-tl-green");
              else
                insertRow = resHTML.replace(new RegExp('<caspardo_seminarampel>', 'g'), "ifb-tl-yellow");
              insertRow = insertRow.replace(new RegExp('<caspardo_seminarampeltxt>', 'g'), "Dieses Seminar hat noch " + data.frei + " Plätze frei");
            } else {
              insertRow = resHTML.replace(new RegExp('<caspardo_seminarampel>', 'g'), "ifb-tl-red");
              insertRow = insertRow.replace(new RegExp('<caspardo_seminarampeltxt>', 'g'), "Dieses Seminar ist ausgebucht");
            }
            jQuery("#caspardo-status-" + data.id).html(insertRow);
          })
          .fail(function () {
            console.log("error");
          })
          .always(function () {
            console.log("complete");
          });
      }
    }
  }

  /**
   * Generate the appointmentlist.
   * @param view 
   */
  public generateAppointmentList(view: string) {
    if (this.jsonResult.searchresult.header.page === 1) {
      jQuery("#caspardo_appointmentlist").html("");
      jQuery("#caspardo_appointmentlist").append(CaspardoAPI.config.searchresult_seminar.appointment_tablehead);
      CaspardoSelectedFilter.setSeminarFilterLoader(2, this.jsonResult.searchresult.header.hits);
    }
    this.appendLoadPage(view);
  }

}