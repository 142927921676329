import { CaspardoSelectedFilter } from "../template/CaspardoSelectedFilter";
import { CaspardoTemplate } from "../template/CaspardoTemplate";
import { CaspardoAPI } from "../common/CaspardoAPI";

export class CaspardoDropDown {

    // Attribute-Group: General 
    id: string;  // unique id of the drop down
    name: string; // Displayname of the drop down
    selectName: string; // select input name
    checkboxes: string; // html-classes (#html-element-id1, #html-element-id2, #html-element-id3)
    checkboxRow: string = CaspardoTemplate.config.searchresult_seminar.dropdown_checkbox_row;

    // Attribute-Group:  Data Model for the values 
    // Arrays represents the datastructure of the drop down. 
    ids: Array<string> = []; // unique ids of the entries
    values: Array<string> = []; // values of the entries
    queries: Array<string> = []; // queryvalue of the entries
    topflag: Array<string> = []; // topflag=[true|false] If this array.length>0 then the group (ids, values, queries, topflag )

    // Attribute-Group: Current Values used when the search bos will be displayed. 
    // Current selected values stored in arrays
    currentID: Array<string> = [];
    currentValues: Array<string> = [];
    currentQueries: Array<string> = [];
    currentTopflag: Array<string> = [];

    // Current select values of the drop down. This array respresents the selected values of the drop down.
    selectedValues: Array<string> = [];


    /**
     * Constructor: CaspardoDropDown
     * The drop down consists of four daat groups: 
     * - general: General Dropdown attributes:  
     * @param id  HTML-Element: ID Selector
     * @param selectName HTML-Selector-ID: The drop down will be rendered into this html element.
     * @param ids : Datastructure: Array of text for ids. Text can represent a json string for obejects.
     * @param values: Datastructrue: Array of text for values. Text can represent a json string for objects. 
     * @param queries: Datastructure: Array of text for queries. Text can represent a json string jor object. 
     */
    constructor(id: string, selectName: string, name: string, ids: Array<string>, values: Array<string>, queries: Array<string>) {
        // General Data
        this.id = id;
        this.selectName = selectName;
        this.name = name;

        // Drop-Down-Data
        this.ids = ids;
        this.values = values;
        this.queries = queries;
        this.topflag = [];
        this.queries.forEach(element => {
            let insert: string = "false";
            this.topflag.push(insert);
        });

        // Init current with all
        this.currentID = JSON.parse(JSON.stringify(ids));
        this.currentValues = JSON.parse(JSON.stringify(values));
        this.currentQueries = JSON.parse(JSON.stringify(queries));
        this.currentTopflag = [];
        this.currentQueries.forEach(element => {
            let insert: string = "false";
            this.currentTopflag.push(insert);
        });
        // this.sortByValues();

    }


    // Section: Datamodel

    /**
     * Adds the top-flag array to the datasctructure.
     * @param topflagarray 
     */
    public setTopFlagArray(topflagarray: any) {
        this.topflag = topflagarray;
        this.currentTopflag = topflagarray;
    }

    /**
     * Sorts only the data-model for the drop-down and not the current selected and the current values.
     */
    public sortByValues() {
        var hasTopFlags: boolean = this.topflag.length > 0;
        var tempSortArr: Array<any> = [];
        for (var i = 0; i < this.ids.length; i++) {

            let insertTopFlag: string = "false";
            if (hasTopFlags)
                insertTopFlag = this.topflag[i];
            let entry = {
                "id": this.ids[i],
                "value": this.values[i],
                "query": this.queries[i],
                "flag_top": insertTopFlag
            }
            tempSortArr.push(entry);
        }

        tempSortArr.sort(function (a, b) {
            var x = a.value.toLowerCase();
            var y = b.value.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });

        this.clearCurrentDataModel();

        // Insert first the Top-Entries
        for (var i = 0; i < tempSortArr.length; i++) {
            if (tempSortArr[i].flag_top) {
                let insert_id = tempSortArr[i].id;
                let insert_value = tempSortArr[i].value;
                let insert_query = tempSortArr[i].query;
                let insert_topflag = tempSortArr[i].flag_top;
                this.addCurrentDataObj(insert_id, insert_value, insert_query, insert_topflag);
            }
        }

        // Insert the normal Entries
        for (var i = 0; i < tempSortArr.length; i++) {
            if (tempSortArr[i].flag_top === false) {
                let insert_id = tempSortArr[i].id;
                let insert_value = tempSortArr[i].value;
                let insert_query = tempSortArr[i].query;
                let insert_topflag = tempSortArr[i].flag_top;
                this.addCurrentDataObj(insert_id, insert_value, insert_query, insert_topflag);
            }
        }



    }

    // Section: Search CurrentValues

    /**
     * Searches in the data and puts them into the current-values.
     * @param query 
     */
    public search(query: string) {
        query = query.toUpperCase(); // toUpperCase to ensure the comparision of the names in the search.
        // Reset the current-values.
        this.clearCurrentDataModel();
        // Remove the current checkboxes from the drop-down.
        jQuery('.caspardo_filter_dropdown').filter('[data-checkbox-item="' + this.selectName + '"]').remove();
        // Why loop through the selected values? Because the selected values should be at the top, when the search will be executed.
        for (var i = 0; i < this.selectedValues.length; i++) {
            let pos: number = <number>this.values.indexOf(this.selectedValues[i]);
            if (pos >= 0) {
                this.addCurrentDataObj(this.ids[pos], this.values[pos], this.queries[pos], this.topflag[pos]);
            }
        }// Now the selected data of the drop-down is added to the current data!

        // Loop through the data-model and push the entry to the current data-structure when the name matches!
        for (var i = 0; i < this.values.length; i++) {
            var txt: string = this.values[i].toUpperCase(); // toUpperCase because to ensure the match!
            var contains = (txt.indexOf(query) >= 0); // Comparision of the query in uppercase!
            var isIN = jQuery.inArray(this.values[i], this.currentValues); // Ensure that no duplication can occur becaus of the first selected inserted above.
            if ((contains === true) && (isIN === -1)) // Comparison true and not duplicated
            {
                this.addCurrentDataObj(this.ids[i], this.values[i], this.queries[i], this.topflag[i]);
            }
        }

        // Sort the values by top-flag and alphabet.
        this.sortCurrentValues();

        // Append the checkboxes of the drop-down.
        let resCheckboxes: string = this.createCheckboxes();

        // Append the generated checkboxes after the "searchdivider"
        let searchinputname = this.id + '_searchdivider';
        jQuery('.' + searchinputname).append(resCheckboxes);

        jQuery('.caspardo_filter_dropdown').filter('[data-checkbox-item="' + this.selectName + '"]').click((evt) => {
            this.executeCheckboxClick(evt);
        });


    }

    /**
     * Adds one data-object to the current value group.
     * @param id 
     * @param value 
     * @param query 
     * @param flag_top
     */
    public addCurrentDataObj(id: string, value: string, query: string, flag_top: string) {
        this.currentID.push(id);
        this.currentValues.push(value);
        this.currentQueries.push(query);
        this.currentTopflag.push(flag_top);
    }
    /**
     * clear Current Data Model
     */
    public clearCurrentDataModel() {
        this.currentID = [];
        this.currentQueries = [];
        this.currentValues = [];
        this.currentTopflag = [];
    }


    /**
     * Sorts the current data model by value adn top flag. For example after search.
     */
    public sortCurrentValues() {
        var tempSortArr: Array<any> = [];
        for (var i = 0; i < this.currentID.length; i++) {
            let entry = {
                "id": this.currentID[i],
                "value": this.currentValues[i],
                "query": this.queries[i],
                "topflag": this.topflag[i]
            }
            tempSortArr.push(entry);
        }

        tempSortArr.sort(function (a, b) {
            var x = a.value.toLowerCase();
            var y = b.value.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });

        // Clear the current data model
        this.clearCurrentDataModel();

        // Rebuild the new sorted current data model
        for (var i = 0; i < tempSortArr.length; i++) {
            this.addCurrentDataObj(tempSortArr[i].id, tempSortArr[i].value, tempSortArr[i].query, tempSortArr[i].topflag);
        }

    }

    // Section: Selected Filters

    /**
     * returns the selected values for the current dropdown.
     */
    public getSelectedValues() {
        return this.selectedValues;
    }

    /**
     * create Selected Filters
     */
    public createSelectedFilters() {
        let row = ""
        let resHTML = "";

        let selectedId: string = this.id + "selectedItems";
        jQuery("#caspardo_filter_selected_panel").html("");

        let selected: string = "";
        let counter = 0;
        // CaspardoSelectedFilter.createSeminarFilter();
        selected += this.generateSelectedBootstrapBlockRows(12);

        jQuery("#caspardo_filter_selected_panel").append(selected);

        // Add the events to the appended selected filters
        jQuery(".caspardo_filter_ahref").click((evt) => {
            let element = evt.target;

            // Get the type and the value of the filter which was clicked.
            let valueType: string = <string>evt.currentTarget.getAttribute("data-checkbox-item");
            let value: string = <string>evt.currentTarget.getAttribute("data-checkbox-value");

            // Delete the selected value in the Drop-Down.
            let pos: number = <number>this.selectedValues.indexOf(value);
            this.selectedValues.splice(pos, 1);

            // Handle the Template Elements for the type.
            if (valueType === "seminar_cities") {
                let pos: number = <number>CaspardoSelectedFilter.seminar_cities.indexOf(value);
                CaspardoSelectedFilter.seminar_cities.splice(pos, 1);
                CaspardoTemplate.caspardoSeminarCityDropDown.toHTML(true);
            }

            if (valueType === "seminar_calweeks") {
                let pos = <number>CaspardoSelectedFilter.seminar_kws.indexOf(value);
                CaspardoSelectedFilter.seminar_kws.splice(pos, 1);
                CaspardoTemplate.caspardoSeminarCalendarWeekDropDown.toHTML(false);
            }

            if (valueType === "seminar_themes") {
                let pos = <number>CaspardoSelectedFilter.seminar_themes.indexOf(value);
                CaspardoSelectedFilter.seminar_themes.splice(pos, 1);
                CaspardoTemplate.caspardoSeminarDropDown.toHTML(false);
            }

            // Execute the new query.
            CaspardoSelectedFilter.seminar_filter_refresh = true;
            this.toHTML(false);
            this.createSelectedFilters();
            let query: string = <string>jQuery("#caspardo_searchfield_new").val();
            CaspardoAPI.searchSeminar(query, 2, 1, false);

        });



    }

    /**
     * generate selected Bootstrap Block Rows
     * @param columnsPerRow 
     */
    public generateSelectedBootstrapBlockRows(columnsPerRow: number) {

        let resHTML = "";

        var tempSelectedFilterArray = [];

        // Insert the cities
        for (var i = 0; i < CaspardoSelectedFilter.seminar_cities.length; i++) {
            // push to temparrary
            var filterObj = {
                "type": "city",
                "value": CaspardoSelectedFilter.seminar_cities[i]
            };
            tempSelectedFilterArray.push(filterObj);
        }
        // Insert the calendarweeks
        for (var i = 0; i < CaspardoSelectedFilter.seminar_kws.length; i++) {
            // push to temparrary
            var filterObj = {
                "type": "kw",
                "value": CaspardoSelectedFilter.seminar_kws[i]
            };
            tempSelectedFilterArray.push(filterObj);
        }

        // Insert the themes
        for (var i = 0; i < CaspardoSelectedFilter.seminar_themes.length; i++) {

            let currentSeminarName: string = "";
            var posQueries = jQuery.inArray(CaspardoSelectedFilter.seminar_themes[i], CaspardoTemplate.caspardoSeminarDropDown.queries);
            if (posQueries >= 0)
                currentSeminarName = CaspardoTemplate.caspardoSeminarDropDown.values[posQueries];

            var seminar: any = {
                "id": CaspardoSelectedFilter.seminar_themes[i],
                "name": currentSeminarName
            }
            // push to temparrary
            var filterObj = {
                "type": "themes",
                "value": seminar
            };
            tempSelectedFilterArray.push(filterObj);
        }

        resHTML += this.generateSelectedFilterBlockRow(tempSelectedFilterArray);

        return resHTML;
    }

    /**
     * generate Selected Filter Block Row
     * @param selectedFilterArray 
     */
    public generateSelectedFilterBlockRow(selectedFilterArray: any) {
        let resHTML: string = "";
        let blockHead: string = "<div class=\"p-l-15\">";
        let cityHTMLRow: string = CaspardoTemplate.config.searchresult_seminar.seletedfilter_cityrow;
        let kwHTMLRow = CaspardoTemplate.config.searchresult_seminar.selectedfilter_cwrow;
        let themeHTMLRow = CaspardoTemplate.config.searchresult_seminar.selectedfilter_themerow;
        let blockFooter: string = "</div>";

        var arrCity = [];
        var arrCWs = [];
        var arrThemes = [];

        let resCityBlock: string = "";
        let resCWBlock: string = "";
        let resThemeBlock: string = "";

        // Das muss ersetzt werden!

        for (var i = 0; i < selectedFilterArray.length; i++) {
            let entry = selectedFilterArray[i];
            let row: string = "";
            switch (entry.type) {
                case "city": {
                    arrCity.push(entry);
                    break;
                }
                case "kw": {
                    arrCWs.push(entry);
                    break;
                }
                case "themes": {
                    arrThemes.push(entry);
                    break;
                }
            }
        }

        resHTML += blockHead;

        if (arrThemes.length > 0) {
            resThemeBlock = '<div class="row caspardo_selected_themesblock"><div class="p-l-15">Ausgewählte Seminarthemen:</div></div><div class="row">';
            for (var i = 0; i < arrThemes.length; i++) {
                let row = themeHTMLRow.replace(new RegExp('{value}', 'g'), arrThemes[i].value.id);
                resThemeBlock += row.replace(new RegExp('{name}', 'g'), arrThemes[i].value.name);
            }
            resThemeBlock += '</div>';
        }


        if (arrCity.length > 0) {
            resCityBlock = '<div class="row caspardo_selected_cicyblock"><div class="p-l-15">Ausgewählte Städte:</div></div><div class="row">';
            for (var i = 0; i < arrCity.length; i++) {
                resCityBlock += cityHTMLRow.replace(new RegExp('{value}', 'g'), arrCity[i].value);
            }
            resCityBlock += '</div>';
        }

        if (arrCWs.length > 0) {
            resCWBlock = '<div class="row caspardo_selected_calendarblock"><div class="p-l-15">Ausgewählte Kalendarwochen:</div></div><div class="row">';
            for (var i = 0; i < arrCWs.length; i++) {
                resCWBlock += kwHTMLRow.replace(new RegExp('{value}', 'g'), arrCWs[i].value);
            }
            resCWBlock += '</div>';
        }


        resHTML += resCityBlock + resCWBlock + resThemeBlock;
        resHTML += blockFooter;
        return resHTML;
    }

    /**
     * Adds one object to the this.selectedValues array
     * @param obj Selected entry object
     */
    public addSelectedObj(obj: any) {
        this.selectedValues.push(obj);
        // Lösche einen selektierten Eintrag! 
        // Suche nach dem Eintrag, wenn Treffer, dann im Array löschen.
    }

    /**
     * Removes the object from the selected objects, if it is in the this.selectedValues array.
     * @param obj Selected entry object
     */
    public removeSelectedObj(obj: any) {
        let pos: number = <number>jQuery.inArray(obj, this.selectedValues);
        if (pos >= 0)
            this.selectedValues.splice(pos, 1);
    }




    // Section: Checkboxes
    /**
     * Generates the checkbox-list for the drop-down.
     * @returns HTML-Block of checkboxes. First Block: Top-Entries, Second-Block: Normal Entires.
     */
    public createCheckboxes() {
        // Reset the checkboxes
        this.checkboxes = "";
        let resCheckboxes: string = this.createCheckBoxBlock("true");
        resCheckboxes += this.createCheckBoxBlock("false");

        return resCheckboxes;

    }

    /**
     * Generates for the one checkbox-list-block in the drop-down. 
     * @param type ["true"|"false"] true=only top entries.
     * @returns HTML-Block of checkboxes for the drop-down.
     */
    public createCheckBoxBlock(compare_flagtop: string) {
        let resCheckboxes: string = "";
        // Loop through the current checkbox-values
        for (var i = 0; i < this.currentValues.length; i++) {

            // Find the position in the current values array in the datamodel (this.values is part of the datamodel)
            let posID: number = <number>jQuery.inArray(this.currentValues[i], this.values);

            // Generate the unique id-element (selectName+id)
            let id_element = this.selectName + this.ids[posID];

            // Get the top-flag status for the entry from the datamodel (topflag is part of the datamodel)
            let topFlag = this.currentTopflag[i];

            if (topFlag === compare_flagtop) {
                // Replace the placeholders
                let entry = this.checkboxRow.replace(new RegExp('{caspardo_value}', 'g'), this.currentValues[i]);
                entry = entry.replace(new RegExp('{caspardo_query}', 'g'), this.currentQueries[i]);
                entry = entry.replace(new RegExp('{caspardo_entry}', 'g'), id_element);
                entry = entry.replace(new RegExp('{caspardo_item}', 'g'), this.selectName);
                let posFind: number = -1;
                posFind = <number>this.selectedValues.indexOf(this.currentQueries[i]);
                if (posFind != -1)
                    entry = entry.replace(new RegExp('{caspardo_checked}', 'g'), "checked");
                else
                    entry = entry.replace(new RegExp('{caspardo_checked}', 'g'), "");
                // Add the entry to the checkboxes html
                resCheckboxes += entry;


            }
        }
        return resCheckboxes;

    }

    /**
     * execute Checkbox Click
     * @param evt 
     */
    public executeCheckboxClick(evt) {
        var element = evt.target;
        let valueStr: string = <string>jQuery(element).val();
        let valueType: string = <string>evt.currentTarget.getAttribute("data-checkbox-item");

        let currentValue: string = "";
        var posQueries = jQuery.inArray(valueStr, this.queries);
        if (posQueries >= 0)
            currentValue = this.values[posQueries];

        let filterObj = {
            "filter_type": valueType,
            "filter_value": valueStr,
            "filter_id": valueType + "_" + valueStr,
            "filter_current": currentValue
        }

        var isIN = jQuery.inArray(valueStr, this.selectedValues);
        if (isIN === -1) {
            this.selectedValues.push(valueStr);
            if (valueType === "seminar_cities")
                CaspardoSelectedFilter.seminar_cities.push(valueStr);
            if (valueType === "seminar_calweeks")
                CaspardoSelectedFilter.seminar_kws.push(valueStr);
            if (valueType === "seminar_themes")
                CaspardoSelectedFilter.seminar_themes.push(valueStr);
        }


        else {
            let pos: number = <number>this.selectedValues.indexOf(valueStr);
            this.selectedValues.splice(pos, 1);
            if (valueType === "seminar_cities") {
                pos = <number>CaspardoSelectedFilter.seminar_cities.indexOf(valueStr);
                if (pos >= 0)
                    CaspardoSelectedFilter.seminar_cities.splice(pos, 1);
            }
            if (valueType === "seminar_calweeks") {
                pos = <number>CaspardoSelectedFilter.seminar_kws.indexOf(valueStr);
                if (pos >= 0)
                    CaspardoSelectedFilter.seminar_kws.splice(pos, 1);
            }
            if (valueType === "seminar_themes") {
                pos = <number>CaspardoSelectedFilter.seminar_themes.indexOf(valueStr);
                if (pos >= 0)
                    CaspardoSelectedFilter.seminar_themes.splice(pos, 1);
            }

        }

        this.toHTML(false);
        this.createSelectedFilters();

        let query: string = <string>jQuery("#caspardo_searchfield_new").val();
        CaspardoAPI.searchSeminar(query, 2, 1, false);



    }

    /**
     *  Section: toHTML
     * @param displaySearchBox 
     */
    public toHTML(displaySearchBox: boolean) {
        let res: string = '';
        let searchinputname = this.id + '_search';
        let searchdropdown_id = this.id + '_search_dropdownid';
        res += '<button class="btn btn-default dropdown-toggle caspardo_filter_dropdownbtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="' + searchdropdown_id + '"><caspardo_name> <i class="ifb-caret-down caspardo_filter_dropdownbtn_span pull-right"></i></button>';
        res += '<div class="dropdown-menu caspardo_filter_dropdown_panel" aria-labelledby="' + searchdropdown_id + '">';
        if (displaySearchBox)
            res += '<p><input class="caspardo_filter_dropwdown_inputtxt" type="text" id="' + searchinputname + '" name="' + searchinputname + '" value=""/></p>';
        res += '<div class="' + searchinputname + 'divider"></div>';

        let resCheckboxes: string = this.createCheckboxes();
        res += resCheckboxes;
        res += ' </div>';


        res = res.replace('<caspardo_name>', this.name);

        jQuery("#" + this.id).html(res);

        this.createSelectedFilters();

        jQuery("#" + searchinputname).keyup((evt) => {

            let query: string = <string>jQuery("#" + searchinputname).val();
            this.search(query);

        });

        jQuery('.caspardo_filter_checkbox').filter('[data-checkbox-item="' + this.selectName + '"]').click((evt) => {
            this.executeCheckboxClick(evt);
        });

    }
}