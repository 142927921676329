import { CaspardoTemplate } from "./template/CaspardoTemplate";
import { CaspardoSeminarFinder } from "./seminarfinder/CaspardoSeminarFinder";
import { CaspardoSeminarFinderV2 } from "./seminarfinder/CaspardoSeminarFinderV2";
import { CaspardoAPI } from "./common/CaspardoAPI";

const queryString = require('query-string');

/**
 * Import styles
 */
import './styles/style.less';

var webparas = queryString.parse(location.search);

var caspardoConfig = require("./caspardoconfig.json");

let tab_seminar_active: boolean = false;
let tab_products_active: boolean = false;
let tab_information_active: boolean = false;

caspardoConfig.tabs.forEach(tab => {
	if (tab.name === "Seminar")
		tab_seminar_active = (tab.status === "active");
	if (tab.name === "Information")
		tab_information_active = (tab.status === "active");
	if (tab.name === "Products")
		tab_products_active = (tab.status === "active");
});


/**
 * Init the developer mode vs. live  
 */
if (caspardoConfig.searchengine_mode == "dev") {
	caspardoConfig.searchengine_url = caspardoConfig.searchengine_url_dev;
	caspardoConfig.searchengine_template = caspardoConfig.searchengine_template_dev;
	caspardoConfig.searchengine_url_target = caspardoConfig.searchengine_url_target_dev;
	caspardoConfig.seminarfinder.default_data_source = caspardoConfig.seminarfinder.default_data_source_dev;
}

// Load the search engine
let caspardoSearchEngine = new CaspardoTemplate(caspardoConfig);


if (tab_seminar_active) {

	var caspardoSeminarFinderJSON = require("./seminars.json");

	caspardoSearchEngine.setSeminarData(caspardoSeminarFinderJSON);
	/**
	 * Check the seminar finder version and load that functionality according to seminar filter type,
	 */
	if (caspardoConfig.seminarfinder.seminarfiltertype === 1) {
		var caspardSeminarFinder = new CaspardoSeminarFinder(caspardoConfig, caspardoSeminarFinderJSON);
		$('#caspardo-seminar-finder-v2').addClass('hide');
		$('#caspardo-seminarfinder-row').addClass('hide');
		$('#caspardo-seminar-finder-v1').removeClass('hide');
	}
	if (caspardoConfig.seminarfinder.seminarfiltertype === 2) {
		var caspardSeminarFinder = new CaspardoSeminarFinderV2(caspardoConfig, caspardoSeminarFinderJSON);
		$('#caspardo-seminar-finder-v1').addClass('hide');
		$('#caspardo-seminar-finder-v2').removeClass('hide');
		$('#caspardo-seminarfinder-row').removeClass('hide');
	}
}



/**
 * Whole site Execution start from here,
 */
jQuery(document).ready(function () {

    /**
     * Polyfill `Element.prototype.closest` for IE 11
     *
     */
    if (typeof Element.prototype.closest === 'undefined') {
        /**
         * `Element.prototype.closest` is needed for this polyfill.
         * On some browsers that method exists with a different name,
         * so we have to make sure tu use the correct one
         */
        Element.prototype.matches = (function () {
            if(typeof Element.prototype.matches === 'function') {
                return Element.prototype.matches
            }
            if (typeof Element.prototype.msMatchesSelector === 'function') {
                return Element.prototype.msMatchesSelector
            }
            if (typeof Element.prototype.webkitMatchesSelector === 'function') {
                return Element.prototype.webkitMatchesSelector
            }
        })()

        if (typeof Element.prototype.matches === 'undefined') {
            throw new Error(`Need to polyfill 'Element.prototype.closest', but 'Element.prototype.matches', which is needed for that, does not exist either with or without vendor-prefix.`)
        }

        Element.prototype.closest = function (selector: string): Element {
            let element = this
            do {
                if (element.matches(selector) === true) {
                    return element
                }
                element = element.parentElement || element.parentNode
            } while (element !== null && element.nodeType === Node.ELEMENT_NODE)
            return null
        }
    }


	/**
	 * Initially load the searchresults and template.
	 */
	let caspardoSearchEngineDiV: any = jQuery("#caspardo_searchengine");
	if (caspardoSearchEngineDiV.length) {
		caspardoSearchEngine.loadTemplate();
	}
	
});

