import { CaspardoAPI } from "../common/CaspardoAPI";
import { CaspardoDropDown } from "../components/CaspardoDropDown";
import { CaspardoSeminarResults } from "../searchresults/seminar/CaspardoSeminarResult";
import { CaspardoSelectedFilter } from "./CaspardoSelectedFilter";
import { CaspardoRecommendation } from "./CaspardoRecommendation";
import { CaspardoSearchSlot } from "../searchslot/CaspardoSearchSlot";
import { CaspardoLogger } from "../common/CaspardoLogger";
import { IN18 } from "../IN18Translation";


const queryString = require('query-string');
var moment = require('moment');
var configurations = require('../caspardoconfig.json');


export class CaspardoTemplate {

  // General Attributes
  template_url: string;
  static config: any;
  static currentPage: number = 0;
  static sticky: number = 0;
  static sticky_mini: number = 60;
  static previousScroll: number = 0;
  static compareStickyHeight: number = 0;
  static parsed = queryString.parse(location.search);
  static caspardoAPI: CaspardoAPI;
  static default_selected_tab_id: string;
  static allKeywords: Array<1> = [];

  // Seminar Section: All Seminar Elements
  static seminarData: any = null;

  // Seminar Dropdowns
  static caspardoSeminarCityDropDown: CaspardoDropDown;
  static caspardoSeminarDropDown: CaspardoDropDown;
  static caspardoSeminarCalendarWeekDropDown: CaspardoDropDown;
  // Seminar Date
  static caspardoSeminarDateFrom: string;
  static caspardoSeminarDateTo: string;
  // Seminar WebParameter
  static httpPara_seminarTitleHash: string;
  static httpPara_city: string;
  static httpPara_date_from: string;
  static httpPara_date_to: string;

  /**
  * Init the events of the CASPARDO Template
  * @param config  CASPARDO JSON Configuration
  */

  constructor(config: any) {

    CaspardoTemplate.config = config;
    this.template_url = config.template_url;
    // Init the API
    let caspardoSearch = new CaspardoAPI(config.searchengine_url, config.searchengine_id);
    
    // Set the language
    let webparas = queryString.parse(location.search);
    if (config.lang_default != null) {
      CaspardoAPI.defaultLanguage = config.lang_default;
    } else {
      CaspardoAPI.defaultLanguage = document.documentElement.lang;
      if (webparas.lang)
        CaspardoAPI.defaultLanguage = webparas.lang;
    }
    CaspardoAPI.api_sid=CaspardoAPI.config.searchengine_lang_id[CaspardoAPI.defaultLanguage];

    // Init the recommendation
    var recommendation = new CaspardoRecommendation("Recommendation");

    // Init the searchslot
    let searchSlot = new CaspardoSearchSlot("Search");
    searchSlot.init();
    configurations.tabs.some(item => {
      if (item.selected === true) {
        CaspardoSelectedFilter.search_tab_type = item.data_view;
        if (item.id == 'caspardo_tab_shop')
          CaspardoSelectedFilter.search_tab_type = 'products';
        $("#caspardo_tab_shop").click();
      }
    });

  }

  /**
   * Method: sets the seminar-data
   * @param data: json data from seminar.json file,
   * data.seminars[0]: All seminar information,
   */
  public setSeminarData(data: any) {
    CaspardoTemplate.seminarData = data.seminars[0];
  }

  /**
   * Method: loadJS
   * loads a JS-Source and appends it to the site
   * @param src: source url for script of type javascript, 
   */
  public loadJs(src: string) {
    let jsLink = $("<script async type='text/javascript' src='" + src + "'>");
    $("head").append(jsLink);
  }

  /**
   * Method: loadCSS
   * loads a CSS-Source and appends it to the site
   * @param src: source url for script of type css, 
   */
  public loadCSS(src: string) {
    let cssLink = $("<link rel='stylesheet' type='text/css' href='" + src + "'>");
    $("head").append(cssLink);
  }

  /*
    Method: executeSearch
    This is the central search executer
  */
  public static executeSearch() {
    // Get the search-input
    let query: string = <string>jQuery("#caspardo_searchfield_new").val();
    // Initialize the query word
    CaspardoTemplate.setSearchResultHeadline(query);
    // Reset buttons
    jQuery("#caspardo_btn_seminarwebcode").val('');
    jQuery("#caspardo_btn_seminarnr").val('');
    // Event: Click
    // Attach to: #caspardo_searchresult_reset
    // Reset the searchresult
    jQuery("#caspardo_searchresult_reset").click((evt) => {
      // Reset the query in the tempalte
      jQuery("#caspardo_searchfield_new").val('');
      CaspardoTemplate.setSearchResultHeadline("");
      jQuery("#caspardo_searchfield_new").focus();
      // Default search for all seminars
      CaspardoAPI.searchSeminar("", 1, 1, false);
      // Reset seminar filters
      CaspardoSelectedFilter.resetFilters();

      // Reset the browser push state
      if (history.pushState) {
        var stateObj = {};
        history.pushState(stateObj, "Search", CaspardoTemplate.config.searchengine_url_target + "?query=");
      }
    });

    if (history.pushState) {
      var stateObj = {};
      history.pushState(stateObj, "seite 2", CaspardoTemplate.config.searchengine_url_target + "?query=" + query);
    }

    // Execute the seminar tab
    if (CaspardoSelectedFilter.search_tab_type === 'seminar') {
      $("#caspardo_tab_seminar").trigger("click");
      $("#caspardo_seminar_filter_reset").trigger("click");
    }
    // Execute the content tab
    if (CaspardoSelectedFilter.search_tab_type === 'content') {
      $("#caspardo_tab_content").trigger("click");
    }
    // Execute the shop tab
    if (CaspardoSelectedFilter.search_tab_type === 'shop') {
      $("#caspardo_tab_shop").trigger("click");
    }

  }

  public static setSearchResultHeadline(query: string) {
    // Initialize the query word
    jQuery(".caspardo_searchresult_infotext").html(query);
    jQuery(".caspardo_searchresult_infotext1").html(query);

  }

  /**
   * Loading of the html-template for site,
   */
  public loadTemplate() {

    // Initialize the searchfield
    if (CaspardoTemplate.parsed.query)
      jQuery("#caspardo_searchfield").val(CaspardoTemplate.parsed.query);

    // Init the web-parameters
    CaspardoTemplate.httpPara_seminarTitleHash = CaspardoTemplate.parsed.seminartitlehash;
    CaspardoTemplate.httpPara_city = CaspardoTemplate.parsed.city;
    CaspardoTemplate.httpPara_date_from = CaspardoTemplate.parsed.from;
    CaspardoTemplate.httpPara_date_to = CaspardoTemplate.parsed.to;
    let query: string = "";
    if (CaspardoTemplate.parsed.query)
      query = CaspardoTemplate.parsed.query;
    query = encodeURI(query);

    // Set the html-template to the div with the id-selector: caspardo_searchengine
    var data = CaspardoTemplate.config.searchengine_template;
    jQuery("#caspardo_searchengine").append(data);

    // Hide the filter-fake-panel -> it is needed for the sticky header.
    jQuery('#caspardo_filterpanel_fake').hide();

    // This method create the dynamic tab in the search page
    CaspardoTemplate.createDynamicTabs(query);
    //CaspardoTemplate.loadKeywordDiv(query);

    CaspardoTemplate.registerEvents();
    // Register all Template Elements Events.


    // Init the elements with the query value
    CaspardoTemplate.setSearchResultHeadline(CaspardoTemplate.parsed.query);
    jQuery("#caspardo_searchfield_new").val(CaspardoTemplate.parsed.query);

    // Init the sticky header
    let filterPanelElement = jQuery(".caspardo_filterpanel").length;
    if (filterPanelElement)
      CaspardoTemplate.sticky = jQuery(".caspardo_filterpanel").offset().top;
    else
      CaspardoTemplate.sticky = 110;

    // IN18 init

    IN18.IN18Translation();

    // Switch Case Handler!!! for all Tabs!
    // Handle the content Tab
    if ((CaspardoSelectedFilter.search_tab_type === 'content') || (CaspardoTemplate.parsed.tab === 'content')) {
      $("#caspardo_tab_content").trigger("click");
    } else {

      // Seminar Tab View
      if (CaspardoSelectedFilter.search_tab_type === 'seminar') {
        CaspardoTemplate.loadSeminarTab(query);
        (<any>$('.nav-tabs a[href="#home"]')).tab('show');
        for (let index = 0; index < configurations.tabs.length; index++) {
          let element = configurations.tabs[index];
          if (element.id === "caspardo_tab_seminar") {
            CaspardoSearchSlot.setSelectInfo(element.name, element.autocomplete_searchslot.dropdown_icon_class);
          }
        }
      }
      // Shop tab view,
      if (CaspardoSelectedFilter.search_tab_type === 'products') {
        jQuery("#caspardo_seminarfilter_panel").hide();
        jQuery("#caspardo_contentfilter_panel").hide();
        $("caspardo_tab_shop").click();
      }
    }

  }

  /**
   * This methods creates the dynamic tabs which is defined in the tabs defination in the configuration file,
   * @param query: Searched word from search-slot(autocomplete),
   */
  public static createDynamicTabs(query) {
    // Get the tabs array, 
    var tabsArray = CaspardoTemplate.config.tabs
    // tabsArray = CaspardoTemplate.config.tabs;

    // Check if dynamic tabs available (then show dynamic tabs)
    if (tabsArray.length) {
      // Sort the array by their position
      var sortByProperty = function (property) {
        return function (x, y) {
          return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
        };
      };

      // Call sorting function by position attribute
      tabsArray.sort(sortByProperty('position'));

      // Loop over the array and add dynamic Tabs
      for (var item = 0; item < tabsArray.length; item++) {

        if (tabsArray[item].status == 'active' && tabsArray[item].type == 'dynamic') {
          $("#dynamicTab").append(`<li class="">
          <a 
              data-toggle="${tabsArray[item].data_toggle}" 
              href="${tabsArray[item].href}" 
              id="${tabsArray[item].id}" 
              data-view="${tabsArray[item].data_view}"><span class="tab-${tabsArray[item].data_view}-in18">${tabsArray[item].name}</span>(<span id="${tabsArray[item].hits.id}"></span>)</a>
              </li>`);
        }
        if (tabsArray[item].status == 'active') {
          if (tabsArray[item].selected) {
            CaspardoTemplate.default_selected_tab_id = tabsArray[item].id;
            $(`#${CaspardoTemplate.default_selected_tab_id}`).click();
          }
          if (tabsArray[item].data_view == 'seminar') {
            // console.log('tabsArray[item].data_view: ', tabsArray[item].data_view);
          } else if (tabsArray[item].data_view == 'content') {
            // console.log('tabsArray[item].data_view: ', tabsArray[item].data_view);
          } else if (tabsArray[item].data_view == 'shop') {
            // Search for shop results
            CaspardoAPI.searchShop(tabsArray[item], query);
            if (tabsArray[item].selected) // Set the selected Status.
            {
              CaspardoSelectedFilter.search_tab_type = 'products';
               $("#caspardo_tab_shop").trigger('click');
            }
          }
          $(`${tabsArray[item].id}`).show();
        } else {
          $(`#${tabsArray[item].id}`).hide();
        }
      }
    }
  }

  /**
   * This method gets the keyword from API's and put it in the list and displayed it in div,
   * @param query: Searched word from search-slot(autocomplete),
   */
  public static loadKeywordDiv(query) {
    let api_call: string = CaspardoAPI.config.searchengine_url + "/servlet/GetSuggestions?query=" + encodeURI(query) + "&searchengineid=" + CaspardoAPI.api_sid + "&output=json&language=" + CaspardoAPI.defaultLanguage;
    var jqxhr = jQuery.getJSON(api_call, function (data) {
      if (data.autocomplete.userkeywords.keyworditem) {
        var userKeyword = data.autocomplete.userkeywords.keyworditem
        for (let keyword in userKeyword) {
          CaspardoTemplate.allKeywords.push(userKeyword[keyword].queryword);
          $('#caspardo_keywords_panel').append(`<div class="col-sm-2">
          <input type="checkbox" class="caspardo_content_filtercheckbox" name="domain" value="${userKeyword[keyword].queryword}">${userKeyword[keyword].queryword}
          </div>`);
        }
      }
    });
  }

  /**
   * This method display or hide the developer panel button in seminar filter panel,
   */
  public static hideShowdeveloperPanel() {
    if (configurations.developerpanel_active)
      $('#caspardo_search_slot_show').show();
    else
      $('#caspardo_search_slot_show').hide();
  }

  public static isDirectEntry(seminartitlehash: string, city: string, date_from: string, date_to: string) {
    let res: boolean = false;
    let seminartitlehashExists: boolean = false;
    let cityExists: boolean = false;
    let datefromExists: boolean = false;
    let datetoExists: boolean = false;

    if (seminartitlehash)
      seminartitlehashExists = true;
    if (city)
      cityExists = true;
    if (date_from)
      datefromExists = true;
    if (date_to)
      datetoExists = true;

    res = ((seminartitlehashExists) || (cityExists) || (datefromExists) || (datefromExists));
    return res;
  }

  /**
   * loads the Resulting Initial Seminar Tab
   */
  public static loadSeminarTab(query: string) {

    // WebCode Direct-Search-Entry

    let reg_webcode: RegExp = new RegExp("/^\d{2,4}c$/i");
    var matchArr = query.match(reg_webcode);
    let doIt: boolean = query === "41c";
    if (doIt) {

      jQuery("#caspardo_btn_seminarwebcode").val(query);
      $("#caspardo_btn_seminarwebcode").trigger("click");

    }
    else {
      // Handling the direct entries
      if (CaspardoTemplate.isDirectEntry(CaspardoTemplate.httpPara_seminarTitleHash, CaspardoTemplate.httpPara_city, CaspardoTemplate.httpPara_date_from, CaspardoTemplate.httpPara_date_to))
        CaspardoAPI.searchSeminar(query, 5, 1, false);
      else
        CaspardoAPI.searchSeminar(query, 1, 1, false);
    }

    jQuery("#caspardo_seminarfilter_panel").show();
    jQuery("#caspardo_contentfilter_panel").hide();
    jQuery("#caspardo_shopfilter_panel").hide();


    var date_input: any = $('input[name="caspardo_seminardate"]'); //our date input has the name "date"
    let startDateSeminare = new Date();
    startDateSeminare.setDate(startDateSeminare.getDate());
    var options = {
      format: 'dd.mm.yyyy',
      todayHighlight: true,
      autoclose: true,
      language: 'de',
      startDate: startDateSeminare
    };
    date_input.datepicker(options);
    (<any>jQuery("#caspardo_seminardate_to")).datepicker(options);
    (<any>jQuery("#caspardo_seminardate_to")).datepicker().on('changeDate', function (e) {
      CaspardoSelectedFilter.seminar_filter_refresh = true;
      CaspardoSelectedFilter.seminar_kws = [];
      CaspardoSeminarResults.filter_seminardate_to = <string>jQuery("#caspardo_seminardate_toval").val();
      CaspardoSelectedFilter.seminar_date_to = CaspardoSeminarResults.filter_seminardate_to;
      CaspardoSelectedFilter.createDateFilters();
      CaspardoSelectedFilter.setDateFilter();
      CaspardoAPI.searchSeminar(query, 2, 1, true);
      //  $('a[data-checkbox-item="seminar_calweeks"]').remove();
    });;

    (<any>jQuery("#caspardo_seminardate")).datepicker(options);
    (<any>jQuery("#caspardo_seminardate")).datepicker().on('changeDate', function (e) {
      CaspardoSelectedFilter.seminar_filter_refresh = true;
      CaspardoSelectedFilter.seminar_kws = [];
      CaspardoSeminarResults.filter_seminardate_from = <string>jQuery("#caspardo_seminardate_fromval").val();
      CaspardoSelectedFilter.seminar_date_from = CaspardoSeminarResults.filter_seminardate_from;
      CaspardoSelectedFilter.setDateFilter();
      CaspardoSelectedFilter.createDateFilters();
      CaspardoAPI.searchSeminar(query, 2, 1, true);
    });;
    IN18.IN18Translation();

    return

  }

  /**
   * This methods register all the events in the for all tabs,
   */
  public static registerEvents() {
    /**
     * Add the template events
     * Event: Click
     * Attach to: #caspardo_searchresult_reset
     * Reset the searchresult
     */
    jQuery("#caspardo_searchresult_reset").click((evt) => {
      jQuery("#caspardo_searchfield_new").val('');
      CaspardoTemplate.setSearchResultHeadline("");
      jQuery("#caspardo_searchfield_new").focus();
      CaspardoSelectedFilter.setSeminarFilterLoader(1, 0);
      CaspardoAPI.searchSeminar("", 1, 1, false);
      CaspardoSelectedFilter.resetFilters();
      if (history.pushState) {
        var stateObj = {};
        history.pushState(stateObj, "Search", CaspardoTemplate.config.searchengine_url_target + "?query=");
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_btn_seminarfilter
     * Execute filter search
     */
    jQuery("#caspardo_btn_seminarfilter").click((evt) => {
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoSelectedFilter.seminar_filter_refresh = false;
      CaspardoAPI.searchSeminar(query, 2, 1, false);
    });
    /**
     * Event: Click
     * Attach to: #caspardo-filter-allseminars
     * Execute filter search
     */
    jQuery("#caspardo-filter-allseminars").click((evt) => {
      CaspardoSelectedFilter.seminar_filter_available = false;
      jQuery("#caspardo-filter-allseminars").removeClass("ifb-dot");
      jQuery("#caspardo-filter-allseminars").removeClass("ifb-icon-check");
      jQuery("#caspardo-filter-allseminars").addClass("ifb-icon-check");
      jQuery("#caspardo-filter-available").removeClass("ifb-dot");
      jQuery("#caspardo-filter-available").removeClass("ifb-icon-check");
      jQuery("#caspardo-filter-available").addClass("ifb-dot");
      $("#caspardo_btn_seminarfilter").trigger("click");
    });
    /**
     * Event: Click
     * Attach to: #caspardo-filter-available
     * Execute filter available search
     */
    jQuery("#caspardo-filter-available").click((evt) => {
      CaspardoSelectedFilter.seminar_filter_available = true;
      jQuery("#caspardo-filter-available").removeClass("ifb-dot");
      jQuery("#caspardo-filter-available").removeClass("ifb-icon-check");
      jQuery("#caspardo-filter-available").addClass("ifb-icon-check");
      jQuery("#caspardo-filter-allseminars").removeClass("ifb-dot");
      jQuery("#caspardo-filter-allseminars").removeClass("ifb-icon-check");
      jQuery("#caspardo-filter-allseminars").addClass("ifb-dot");
      $("#caspardo_btn_seminarfilter").trigger("click");
    });
    /**
     * Event: Click
     * Attach to: #caspardo_seminar_option_arbeitsgericht
     * Execute filter arbeitsgericht
     */
    jQuery("#caspardo_seminar_option_arbeitsgericht").click((evt) => {
      if (jQuery("#caspardo_seminar_option_arbeitsgericht").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_arbeitsgericht").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_arbeitsgericht = false;
      } else {
        jQuery("#caspardo_seminar_option_arbeitsgericht").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_arbeitsgericht = true;
      }
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchSeminar(query, 2, 1, true);
    });
    /**
     * Event: Click
     * Attach to: #caspardo_seminar_option_rollstuhlgerecht
     * Execute filter rollstuhlgerecht
     */
    jQuery("#caspardo_seminar_option_rollstuhlgerecht").click((evt) => {
      if (jQuery("#caspardo_seminar_option_rollstuhlgerecht").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_rollstuhlgerecht").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_rollstuhlgerecht = false;
      } else {
        jQuery("#caspardo_seminar_option_rollstuhlgerecht").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_rollstuhlgerecht = true;
      }
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchSeminar(query, 2, 1, true);
    });
    /**
     * Event: Click
     * Attach to: #caspardo_seminar_option_nichtraucher
     * Execute filter nichtraucher
     */
    jQuery("#caspardo_seminar_option_nichtraucher").click((evt) => {
      if (jQuery("#caspardo_seminar_option_nichtraucher").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_nichtraucher").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_nonsmoke = false;
      } else {
        jQuery("#caspardo_seminar_option_nichtraucher").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_nonsmoke = true;
      }
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchSeminar(query, 2, 1, true);
    });
    /**
     * Event: Click
     * Attach to: #caspardo_seminar_option_starterpaket
     * Execute filter starterpaket
     */
    jQuery("#caspardo_seminar_option_starterpaket").click((evt) => {
      if (jQuery("#caspardo_seminar_option_starterpaket").hasClass("caspardo-option-selected")) {
        jQuery("#caspardo_seminar_option_starterpaket").removeClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_starterpaket = false;
      } else {
        jQuery("#caspardo_seminar_option_starterpaket").addClass("caspardo-option-selected");
        CaspardoSelectedFilter.seminar_option_starterpaket = true;
      }
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchSeminar(query, 2, 1, true);
    });
    /**
     * Event: Click
     * Attach to: #caspardo_seminar_filter_reset
     * Reset seminar tab filters
     */
    jQuery("#caspardo_seminar_filter_reset").click((evt) => {
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchSeminar(query, 1, 1, false);
      CaspardoSelectedFilter.resetFilters();
      CaspardoTemplate.setSearchResultHeadline(query);
      CaspardoTemplate.caspardoSeminarDropDown.createSelectedFilters();
      CaspardoTemplate.caspardoSeminarCalendarWeekDropDown.createSelectedFilters();
      CaspardoTemplate.caspardoSeminarCityDropDown.createSelectedFilters();
      if (history.pushState) {
        var stateObj = {};
        history.pushState(stateObj, "Search", CaspardoTemplate.config.searchengine_url_target + "?query=");
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_seminarnrwebcode_filter_reset
     * Reset seminar tab filters for webcode
     */
    jQuery("#caspardo_seminarnrwebcode_filter_reset").click((evt) => {
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchSeminar(query, 1, 1, false);
      CaspardoSelectedFilter.resetFilters();
      CaspardoTemplate.setSearchResultHeadline(query);
      $('html, body').animate({
        scrollTop: 50
      }, 500);
    });
    /**
     * Event: Click
     * Attach to: #caspardo_btn_seminarwebcode
     * Reset seminar tab filters for webcode
     */
    jQuery("#caspardo_btn_seminarwebcode").click((evt) => {
      let query: string = <string>jQuery("#caspardo_btn_seminarwebcode").val();
      if (query.length > 0) {
        CaspardoAPI.searchSeminar(query, 4, 1, false);
        $('html, body').animate({
          scrollTop: 50
        }, 500);
        CaspardoTemplate.setSearchResultHeadline("Webcode: " + query);
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_btn_seminarnr
     * Reset seminar tab filters for webcode
     */
    jQuery("#caspardo_btn_seminarnr").click((evt) => {
      let query: string = <string>jQuery("#caspardo_btn_seminarnr").val();
      if (query.length > 0) {
        CaspardoTemplate.setSearchResultHeadline("Seminar-Nr.: " + query);
        CaspardoAPI.searchSeminar(query, 3, 1, false);
        $('html, body').animate({
          scrollTop: 50
        }, 500);
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_btn_seminarnr
     * Reset seminar tab filters for webcode
     */
    jQuery("#caspardo_btn_seminarnr").keyup((evt) => {
      if (evt.keyCode === 13) {
        let query: string = <string>jQuery("#caspardo_btn_seminarnr").val();
        CaspardoTemplate.setSearchResultHeadline("Seminar-Nr.: " + query);
        CaspardoAPI.searchSeminar(query, 3, 1, false);
        $('html, body').animate({
          scrollTop: 50
        }, 500);
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_btn_seminarnr
     * Reset seminar tab filters for webcode
     */
    jQuery("#caspardo_btn_seminarfiltersearchinput").keyup((evt) => {
      if (evt.keyCode === 13) {
        let query: string = <string>jQuery("#caspardo_btn_seminarfiltersearchinput").val();
        jQuery("#caspardo_searchfield_new").val(query);
        CaspardoAPI.searchSeminar(query, 1, 1, false);
        CaspardoTemplate.setSearchResultHeadline(query);
        $('html, body').animate({
          scrollTop: 50
        }, 500);
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_btn_seminarfiltersearchinput1
     * Reset seminar tab filters for webcode
     */
    jQuery("#caspardo_btn_seminarfiltersearchinput1").click((evt) => {
      let query: string = <string>jQuery("#caspardo_btn_seminarfiltersearchinput").val();
      jQuery("#caspardo_searchfield_new").val(query);
      CaspardoAPI.searchSeminar(query, 1, 1, false);
      CaspardoTemplate.setSearchResultHeadline(query);
      $('html, body').animate({
        scrollTop: 50
      }, 500);
    });
    /**
     * Event: keyup
     * Attach to: #caspardo_btn_seminarwebcode
     * Execute keyup event on seminar tab in webcode
     */
    jQuery("#caspardo_btn_seminarwebcode").keyup((evt) => {
      if (evt.keyCode === 13) {
        let query: string = <string>jQuery("#caspardo_btn_seminarwebcode").val();
        CaspardoTemplate.setSearchResultHeadline("Webcode: " + query);
        CaspardoAPI.searchSeminar(query, 4, 1, false);
        $('html, body').animate({
          scrollTop: 50
        }, 500);
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_tab_seminar
     * Open Seminar tab when click on it and get results.
     */
    jQuery("#caspardo_tab_seminar").click((evt) => {
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchSeminar(encodeURI(query), 1, 1, false);
      jQuery("#caspardo_seminarfilter_panel").show();
      jQuery("#caspardo_contentfilter_panel").hide();
      jQuery("#caspardo_shopfilter_panel").hide();
      CaspardoSelectedFilter.search_tab_autocomplete_contenttype = 'seminar';
      CaspardoSelectedFilter.search_tab_type = 'seminar';
      for (let index = 0; index < configurations.tabs.length; index++) {
        let element = configurations.tabs[index];
        if (element.id === "caspardo_tab_seminar") {
          CaspardoSearchSlot.setSelectInfo(element.name, element.autocomplete_searchslot.dropdown_icon_class);
        }
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_tab_content
     * Open Information tab when click on it and get results.
     */
    jQuery("#caspardo_tab_content").click((evt) => {
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchContent(encodeURI(query), 1, 1);
      jQuery("#caspardo_seminarfilter_panel").hide();
      jQuery("#caspardo_contentfilter_panel").show();
      jQuery("#caspardo_shopfilter_panel").hide();
      CaspardoSelectedFilter.search_tab_autocomplete_contenttype = 'content';
      CaspardoSelectedFilter.search_tab_type = 'content';
      for (let index = 0; index < configurations.tabs.length; index++) {
        let element = configurations.tabs[index];
        if (element.id === "caspardo_tab_content") {
          CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType("content"), element.autocomplete_searchslot.dropdown_icon_class);
        }
      }
    });

    /**
     * Event: Click
     * Attach to: #caspardo_tab_shop
     * Open shop tab when click on it and get results.
     */
    jQuery("#caspardo_tab_shop").unbind('click').bind("click",(evt) => {
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      // CaspardoTemplate.createDynamicTabs(query);
      CaspardoAPI.searchShop("products", query);
      jQuery("#caspardo_seminarfilter_panel").hide();
      jQuery("#caspardo_contentfilter_panel").hide();
      jQuery("#caspardo_shopfilter_panel").show();
      CaspardoSelectedFilter.search_tab_autocomplete_contenttype = 'products';
      CaspardoSelectedFilter.search_tab_type = 'products';
      for (let index = 0; index < configurations.tabs.length; index++) {
        let element = configurations.tabs[index];
        if (element.id === "caspardo_tab_shop") {
          CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType("products"), element.autocomplete_searchslot.dropdown_icon_class);
        }
      }
    });


    /**
     * Event: Click
     * Attach to: .caspardo-searchslot-dropdown-row
     * Autocomplete drop-down events
     */
    $(".caspardo-searchslot-dropdown-row").click((event) => {
      // Execute the seminar tab
      if (CaspardoSelectedFilter.search_tab_type === 'seminar') {
        $("#caspardo_tab_seminar").click();
      }
      // Execute the content tab
      if (CaspardoSelectedFilter.search_tab_type === 'content') {
        $("#caspardo_tab_content").click();
      }
      // Execute the shop tab
      if (CaspardoSelectedFilter.search_tab_type === 'products') {
        $("#caspardo_tab_shop").click();
      }
    });
    /**
     * Event: Click
     * Attach to: #caspardo_searchfield_new
     * Autocomplete search-slot event, whenever clicked then show search button and close icon and minimize the search-slot
     */
    $('#caspardo_searchfield_new').click(function () {
      $(this).parents('.cstm-keystone-search').addClass('expand');
    });
    /**
     * Event: Click
     * Attach to: .srch-close
     * Autocomplete close icon to hide the search button and close icon with search-slot
     */
    $('.srch-close').click(function () {
      $(this).parents('.cstm-keystone-search').removeClass('expand');
    });

    /**
     * Initially hide the No-hits div for all tabs
     */
    jQuery("#shop-nohit-div").hide();
    jQuery("#information-nohit-div").hide();
    jQuery("#seminar-nohit-div").hide();

    /**
     * Hide/show the developer panel
     */
    CaspardoTemplate.hideShowdeveloperPanel();

    /**
     * Call IN18 translation after event registered
     */
    IN18.IN18Translation();

  }

}