import { CaspardoAPI } from "../common/CaspardoAPI";
import { CaspardoSelectedFilter } from "../template/CaspardoSelectedFilter";
import { CaspardoAutocomplete } from "./CaspardoAutocomplete";
import { CaspardoTemplate } from "../template/CaspardoTemplate";
import { IN18 } from "../IN18Translation";

export class CaspardoSearchSlot {

  query: string;

  /**
   * Constructor the search slot with the query. 
   * @param config  CASPARDO JSON Configuration
   */

  constructor(query: string) {
    this.query = query;
  }

  /**
    * Initialize the search slot. 
    */
  public init() {

    // Initialize the HTML
    let resHTML = CaspardoAPI.config.searchslot.html_template + CaspardoAPI.config.searchslot.html_suggestion;

    resHTML = resHTML.replace(new RegExp('{searchengine_url_target}', 'g'), CaspardoTemplate.config.searchengine_url_target);
    resHTML = resHTML.replace(new RegExp('{language}', 'g'), CaspardoAPI.defaultLanguage);

    let tabsList = CaspardoAPI.config.tabs;

    if (CaspardoAPI.config.searchengine_mode === "dev") {
      resHTML = resHTML.replace(new RegExp('{searchengine_url_target}', 'g'), CaspardoTemplate.config.searchengine_url_target_dev);
    }
    if (CaspardoAPI.config.searchengine_mode === "live") {
      resHTML = resHTML.replace(new RegExp('{searchengine_url_target}', 'g'), CaspardoTemplate.config.searchengine_url_target);
    }

    jQuery("#caspardo_searchslot").html(resHTML);

    // Get dynaic dropdown in autocomplete,
    CaspardoSearchSlot.getDropDownAutocomplete(tabsList);

    // Register the events for the search slot
    this.registerEvents();
    // Add the autocomplete to the search slot.
    let autocomplete: CaspardoAutocomplete = new CaspardoAutocomplete();
    autocomplete.initEvent();

    for (let tab = 0; tab < tabsList.length; tab++) {
      if (tabsList[tab].selected)
        CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType(tabsList[tab].data_view), tabsList[tab].autocomplete_searchslot.dropdown_icon_class);
    }

    CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType("all"), "fa fa-search-plus");
    IN18.IN18Translation();
  }

  /**
   * set the title of the select dropn of the searchslot.
   * @param infoname Selected visible name for the tab-dropdown
   */

  public static setSelectInfo(infoname: string, iconInfo: any) {
    //jQuery("#caspardo_searchslot_selectinfo").html(infoname);
    // if (CaspardoAPI.config.layout === "www_caspardo_io")
    jQuery("#caspardo_searchslot_selectinfo").html(`<i class="${iconInfo}"></i> ` + infoname);
    CaspardoSearchSlot.getPlaceholderString(infoname);
  }

  /**
   * set the placeholder of the selected drop-down of the searchslot.
   * @param placeholder Selected visible placeholder for the tab-dropdown
   */

  public static getPlaceholderString(placeholder: string) {
    $("#caspardo_searchfield_new").attr("placeholder",IN18.IN18TranslateNameByKey("search-input-placeholder") + placeholder);
  }

  /**
  * Register the events. 
  */
  public registerEvents() {

    // Searchslot Drop Down
    jQuery(".caspardo-searchslot-dropdown-row").click((evt) => {
      let valueType: string = <string>evt.currentTarget.getAttribute("data-searchtype");
      let valueName: string = <string>evt.currentTarget.getAttribute("data-searchtype-name");
      let valueAutocompleteContentType: string = <string>evt.currentTarget.getAttribute("data-autocomplete-searchtype");
      CaspardoSelectedFilter.search_tab_autocomplete_contenttype = valueAutocompleteContentType;
      CaspardoSelectedFilter.search_tab_type = valueType;
      if (valueType === "all")
        CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType(valueType), "fa fa-search-plus");
      if (valueType === "content")
        CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType(valueType), "fa fa-info-circle");
      if (valueType === "products")
        CaspardoSearchSlot.setSelectInfo(IN18.IN18TranslateTabByType(valueType), "fa fa-globe");
      
      jQuery("#caspardo_suggestions").hide();
    });

    // Searchslot Drop Down on show hide the suggestions
    $(".dropdown").on("show.bs.dropdown", function (event) {
      jQuery("#caspardo_suggestions").hide();
    });
    // function calling for Developer panel
    this.hideShowSearchSlot();
  }

  // Function to hide-show pop-up for search-slot - Developer panel
  public hideShowSearchSlot() {
    // WebserverLogger.log('--WebserverLogger--data--');
    $(document).on("click", "#caspardo_search_slot_show", function () {
      document.getElementById("caspardo_search_slot_displayed").style.display = "block";
    });
    $(document).on("click", ".pop-up-close", function () {
      document.getElementById("caspardo_search_slot_displayed").style.display = "none";
    });
  }

  // Function for dynamic drop-down in the autocomplete
  public static getDropDownAutocomplete(tabsList: any) {
    let tabTemplateHTML: string = '';
    for (let item = 0; item < tabsList.length; item++) {
      if (tabsList[item].status == "active") {
        let row: string = CaspardoAPI.config.searchslot.html_suggestion_dropdown_row;
        const tab = tabsList[item].autocomplete_searchslot;
        row = row.replace(new RegExp('{id}', 'g'), tab.id);
        row = row.replace(new RegExp('{name}', 'g'), IN18.IN18TranslateTabByType(tab.data_searchtype));
        row = row.replace(new RegExp('{data_searchtype}', 'g'), tab.data_searchtype);
        row = row.replace(new RegExp('{data_autocomplete_searchtype}', 'g'), tab.data_autocomplete_searchtype);
        row = row.replace(new RegExp('{data_searchtype_name}', 'g'), tab.data_searchtype_name);
        // if (CaspardoAPI.config.layout === "www_caspardo_io")
        row = row.replace(new RegExp('{dropdown_icon_class}', 'g'), tab.dropdown_icon_class);
        tabTemplateHTML += row;
      }
    }
    jQuery('#caspardo-searchslot-dropdown-autocomplete').append(tabTemplateHTML);
  }

}