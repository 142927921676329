
import { CaspardoAPI } from "../../common/CaspardoAPI";
import { CaspardoSelectedFilter } from "../../template/CaspardoSelectedFilter";
import { CaspardoContentFilter } from "./CaspardoContentFilter";
import { IN18 } from "../../IN18Translation";
import { CaspardoTemplate } from "../../template/CaspardoTemplate";




export class CaspardoContentResults {

  jsonResult: any;
  arrDomains: Array<any> = [];
  doyoumean_query: string = "";
  keyword_query: string = "";

  constructor(jsonResult: any) {
    this.jsonResult = jsonResult;
  }

  /**
   * get Content Filter name
   * @param filtername 
   */
  public getContentFiltername(filtername: string) {

    for (let entry of CaspardoAPI.config.searchresult_content.filternames) {
      if (entry.name === filtername)
        return entry.text;
    }
    return filtername;
  }

  /**
   * generate Filter
   */
  public generateFilter() {

    for (var i = 0; i < this.jsonResult.searchresult.header.filters.filter.length; i++) {
      // Loop throught the filters.
      if (this.jsonResult.searchresult.header.filters.filter[i].name === "domain") {

        if (!(this.jsonResult.searchresult.header.filters.filter[i].value instanceof Array)) {
          var tempArray = [];
          tempArray.push(this.jsonResult.searchresult.header.filters.filter[i].value);
          this.jsonResult.searchresult.header.filters.filter[i].value = tempArray;
        }

        for (var j = 0; j < this.jsonResult.searchresult.header.filters.filter[i].value.length; j++) {
          var domain = {
            id: this.jsonResult.searchresult.header.filters.filter[i].value[j].id,
            content: this.jsonResult.searchresult.header.filters.filter[i].value[j].content
          };
          this.arrDomains.push(domain);
        }
      }
    }
    this.domainsToHtml();
  }

  /**
   * convert domains To Html
   */
  public domainsToHtml() {

    //TODO: HTML Snippet
    let resDomainRow: string = '<td class="caspardo_domain_filterinput"><input type="checkbox" id="<caspardo_id>" name="domain" value="<caspardo_filtervalue>"> <caspardo_filtername></td>';
    let resHTML: string = "<table><tr>";
    let resIDElements: string = "";
    CaspardoContentFilter.generateContentFilter(this.arrDomains);
    jQuery(".caspardo_content_filtercheckbox").click((evt) => {
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchContent(query, 3, 1);
      // $('html, body').animate({
      //   scrollTop: 50
      // }, 500);
    });

    jQuery(resIDElements).click((evt) => {

      let domain_value: string = evt.currentTarget.getAttribute("value");
      let domain_checked: boolean = (<any>evt.currentTarget).checked;
      if (domain_checked)
        CaspardoSelectedFilter.addDomain(domain_value);
      else
        CaspardoSelectedFilter.deleteDomain(domain_value);
      let query: string = <string>jQuery("#caspardo_searchfield_new").val();
      CaspardoAPI.searchContent(query, 2, 1);
      // $('html, body').animate({
      //   scrollTop: 50
      // }, 500);

    });
  }

  /**
   * generate No Hits Info
   */
  public generateNoHitsInfo() {
    // TODO: HTML-Snippet
    let msg: string = "<strong>Leider konnten wir keine Informationen für Ihre Suche finden.</strong>";
    jQuery("#caspardo_contentresults").html(msg);

  }

  /**
   * generate Content List
   * @param type 
   */
  public generateContentList(type: number) {

    if (this.jsonResult.searchresult.header.hits === 0)
      this.generateNoHitsInfo();
    else {

      // Get the current query
      let query: string = "" + $("#caspardo_searchfield_new").val();
      // Condition for generating the filters
      if (type === 1)
        this.generateFilter();
        
      // Clear the search result
      jQuery("#caspardo_contentresults").html("");

      let searchResultHead = CaspardoTemplate.config.searchresult_content.head;
      searchResultHead = searchResultHead.replace(new RegExp('{hits}', 'g'), this.jsonResult.searchresult.header.hits);
      searchResultHead = searchResultHead.replace(new RegExp('{searchtime_in_seconds}', 'g'), this.jsonResult.searchresult.header.searchtime_in_seconds);
      searchResultHead = searchResultHead.replace(new RegExp('{query}', 'g'), query);
      jQuery("#caspardo_contentresults").append(searchResultHead);
      

      // ****** DO YOU MEAN  ******
      // Do you mean heading in content tab:
      let doyoumean_domains = this.jsonResult.searchresult.header.doyoumean.domain
      let doyoumean_array = [];

      // To make a single array of unique keywords,
      for (var i = 0; i < doyoumean_domains.length; i++) {
        if (doyoumean_domains[i].keyword) {
          doyoumean_array.push(doyoumean_domains[i].keyword)
          doyoumean_array = doyoumean_array.reduce((acc, val) => acc.concat(val), []);
          doyoumean_array = doyoumean_array.filter((item, index) => doyoumean_array.indexOf(item) === index);
        }
      }

     
      // create dynamic html values for the "Do you mean" functionality, 
      let doyoumean_html = null;
      if (doyoumean_array.length >= 1) {
        doyoumean_html = "";
        doyoumean_array.forEach(element => {
          let keyword: string = "" + `${element}`;
          if (query != keyword)
            doyoumean_html += ` <a class="doyoumean_keyword" value="${element}">${element}</a>`;
        });
      }

      // register the events
      if (doyoumean_html) {
        let doyoumean_html_container:string=CaspardoTemplate.config.searchresult_content.doyoumean;
        doyoumean_html_container = doyoumean_html_container.replace(new RegExp('{doyoumean_html}', 'g'), doyoumean_html);
   
        jQuery("#caspardo_contentresults").append(doyoumean_html_container);
        // On click on keyword in doyoumean then, search for that keyword(query),
        jQuery(".doyoumean_keyword").unbind('click').bind("click", (evt) => {
          this.doyoumean_query = evt.target.innerHTML;
          CaspardoAPI.searchContent(this.doyoumean_query, 1, 1);
          jQuery("#caspardo_searchfield_new").val(this.doyoumean_query);
        });
      }

      // add content heading here
      let searchAIHead = `
      <div class="content-search-heading" id="caspardo_results_heading_div">
        <h5>Our Search KI Recommendations</h5>
      </div>
      `;

      let resultAdHTML = "";
        // TODO: HTML Snippet
        resultAdHTML = `<div class="search-add" id="caspardo_results_ads_div">
        <div class="col-12 search-hint">
          <!-- div class="add-baner" id="banner_ad_container">
              <img src="https://res.cloudinary.com/caspardo/image/upload/v1572114773/products/ki/avatar-suggestion.png">
          </div -->
          <div  class="srch-col">
            <div class="u-mean">
              <h5><i class="fa fa-question-circle-o"></i> Meinten Sie</h5>
              <div>${doyoumean_html}</div>
            </div>
            <!--- div class="recommendation">
            <a href="#">
              <h5><i class="fa fa-hand-o-right"></i> <span class="our-recommendation-in18">Our recommendation</span></h5>
              <ul id="text_ad_container">
                <li class="ad-title"><span>Zykloidgetriebe</span></li>
                <li><span>Zykloidgetriebe von Nabtesco</span></li>
                <li><span>Nabtesco Getriebekopf mit Hohlwelle • Axiallasten bis zu 9t</span></li>
              </ul>
              <div class="cal-to-action"><span>Click here</span></div>
            </a>
            </div -->
          </div>
        </div>
       
      </div>`;
    //  jQuery("#caspardo_contentresults").append(resultAdHTML);

      // Append the results in the information tabs result list,
      if (this.jsonResult.searchresult.resultlist.item) {
        if (!(this.jsonResult.searchresult.resultlist.item instanceof Array)) {
          var tempArray = [];
          tempArray.push(this.jsonResult.searchresult.resultlist.item);
          this.jsonResult.searchresult.resultlist.item = tempArray;
        }

        for (var i = 0; i < this.jsonResult.searchresult.resultlist.item.length; i++) {
          let insertRow: string = this.generateRow(this.jsonResult.searchresult.resultlist.item[i]);
          jQuery("#caspardo_contentresults").append(insertRow);
        }
      }

      // Add click event on every item of the information tab, For IFB
      if (CaspardoAPI.config.layout === "www_ifb_de") {
        jQuery(".caspardo-content-row").click((evt) => {
          let url = $(evt.currentTarget).data("url");
          if (url != undefined)
            window.open(url);
          return false;
        });
      }

      // TODO: HTML Snippet
      // Show more result button at the bottom of the page and events for the button to append the results,
      let doIt: boolean = ((this.jsonResult.searchresult.header.page + 1) < this.jsonResult.searchresult.header.pages);
      if (doIt) {
        // For Nabtesco
      
          let tablerownex: string = '<div id="caspardo_content_nextpage">';
          tablerownex += '<div class="text-center"><a class="button scroll-to-anchor" id="caspardo_content_load"><span class="load-more-result-in18">Weitere Ergebnisse laden...</span></a></div>';
          tablerownex += '</div>';
          jQuery("#caspardo_contentresults").append(tablerownex);
  
        
      }

    }
    IN18.IN18Translation();
  }

  /**
   * get Domain from url provided
   * @param url 
   */
  public getDomain(url: string) {
    let domain: string = url;
    let startPos: number = domain.indexOf("//") + 2;
    domain = domain.substring(startPos, domain.length);
    domain = domain.substring(0, domain.indexOf("/"));
    return domain;
  }

  /**
   * generate Row with item
   * @param item 
   */
  public generateRow(item: any) {

    let internaltype: string = <string>item.internaltype;
    let insertRow: string = "";
    if (internaltype === "video")
      insertRow = CaspardoAPI.config.searchresult_content.row_video;
    else if (internaltype === "lexicon")
      insertRow = CaspardoAPI.config.searchresult_content.row_lexicon;
    else if (item.picture === "")
      insertRow = CaspardoAPI.config.searchresult_content.row;
    else
      insertRow = CaspardoAPI.config.searchresult_content.row_picture;

    insertRow = insertRow.replace(new RegExp('<caspardo_title>', 'g'), item.title);
    insertRow = insertRow.replace(new RegExp('<caspardo_url>', 'g'), item.url);

    insertRow = insertRow.replace(new RegExp('{caspardo_title_score}', 'g'), item.score);
    insertRow = insertRow.replace(new RegExp('{caspardo_pictureurl}', 'g'), item.picture);
    insertRow = insertRow.replace(new RegExp('{caspardo_youtubeurl}', 'g'), item.url.split(":")[1]);
    insertRow = insertRow.replace(new RegExp('<caspardo_text>', 'g'), item.content);
    insertRow = insertRow.replace(new RegExp('{caspardo_description}', 'g'), item.description);

    let domain: string = "";
    if ((internaltype === "") || (internaltype === "lexicon") || (internaltype === "faq")) {
      if (internaltype === "lexicon") {
        domain = this.getDomain(item.url);
        domain = this.getContentFiltername(domain);
        insertRow = insertRow.replace(new RegExp('<caspardo_content>', 'g'), item.content);
      }
      if (internaltype === "faq") {
        domain = "FAQ";
        insertRow = insertRow.replace(new RegExp('<caspardo_content>', 'g'), item.description);
      }
      if (internaltype === "") {
        domain = this.getDomain(item.url);
        domain = this.getContentFiltername(domain);
        insertRow = insertRow.replace(new RegExp('<caspardo_content>', 'g'), item.description);
      }

    }
    else {
      domain = internaltype;
      if (internaltype === "video") {
        domain = "Video";
        var vframe = '<div class="vimeovideoPlayer"><iframe src="https://player.vimeo.com/video/<vimeoid>" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>';
        var vifb = '<div class="videoPlayer"><video width="100%" controls> <source src="<caspardo_url>" type="video/mp4"></video></div>';

        var video_insert = "";
        if (item.url.indexOf("vimeo.com") != -1) {
          let lastIndexOfSlash = item.url.lastIndexOf("/") + 1;
          let urlSize = item.url.length;
          let vimeoid = item.url.substring(lastIndexOfSlash, urlSize);
          video_insert = vframe.replace("<vimeoid>", vimeoid);
        } else {
          video_insert = vifb.replace("<caspardo_url>", item.url);
        }
        insertRow = insertRow.replace(new RegExp('<caspardo_video>', 'g'), video_insert);
        insertRow = insertRow.replace(new RegExp('<caspardo_content>', 'g'), item.description);
      }
    }
    insertRow = insertRow.replace(new RegExp('<caspardo_type>', 'g'), domain);

    let documentTypeIcon = '';
    // For html icon in the content link,
    if (item.documenttype = "html") {
      documentTypeIcon = '<i class="fa fa-file-text-o"></i>';
      insertRow = insertRow.replace(new RegExp('<caspardo_document_type>', 'g'), documentTypeIcon);
    }

    // For pdf icon in the content link, 
    if (item.documenttype = "pdf") {
      documentTypeIcon = '<i class="fa fa-file-pdf-o"></i>';
      insertRow = insertRow.replace(new RegExp('<caspardo_document_type>', 'g'), documentTypeIcon);
    }

    // create dynamic html values for the keyword functionality, 
    let keywords_array = [];
    let keywords_list = item.keywords.keyword;

    // To make a single array of unique keywords,
    for (var i = 0; i < keywords_list.length; i++) {
      if (keywords_list[i]) {
        keywords_array.push(keywords_list[i]);
      }
    }
    let keywords_html = '';
    if (keywords_array.length >= 1) {
      keywords_html += '<span class="related-search-keywords-in18">Related Search-Keywords:</span>';
      keywords_array.forEach(element => {
        keywords_html += ` <a class="result_keyword_value" value="${element}">${element}</a> `;
      });
    }

    // HTML for the keyword functionality,

      insertRow = insertRow.replace(new RegExp('<caspardo_keywords_html>', 'g'), keywords_html);
 

    // On click on keyword in result-list then, search for that keyword(query),
    jQuery(".result_keyword_value").unbind('click').bind("click", (evt) => {
      this.keyword_query = evt.target.innerHTML;
      jQuery("#caspardo_searchfield_new").val(this.keyword_query);
      CaspardoAPI.searchContent(this.keyword_query, 1, 1);
     
    });

    // On click on keyword in result-list then, search for that keyword(query),
    jQuery(".doyoumean_keyword").unbind('click').bind("click", (evt) => {
      this.keyword_query = evt.target.innerHTML;
      CaspardoAPI.searchContent(this.keyword_query, 1, 1);
      jQuery("#caspardo_searchfield_new").val(this.keyword_query);
    });

    IN18.IN18Translation();
    return insertRow;
  }

  /**
   * append more information Page
   */
  public appendLoadPage() {

    jQuery("#caspardo_content_nextpage").remove();

    for (var i = 0; i < this.jsonResult.searchresult.resultlist.item.length; i++) {
      let insertRow: string = this.generateRow(this.jsonResult.searchresult.resultlist.item[i]);
      jQuery("#caspardo_contentresults").append(insertRow);
    }

    // For IFB
    if (CaspardoAPI.config.layout === "www_ifb_de") {
      jQuery(".caspardo-content-row").click((evt) => {
        let url = $(evt.currentTarget).data("url");
        if (url != undefined)
          window.open(url);
        return false;
      });
    }

    // Show more result button at the bottom of the page and events for the button to append the results,
    let doIt: boolean = ((this.jsonResult.searchresult.header.page + 1) < this.jsonResult.searchresult.header.pages);
    if (doIt) {
      // For Nabtesco
      if (CaspardoAPI.config.layout === "www_nabtesco_de") {
        let tablerownex: string = '<div id="caspardo_content_nextpage">';
        tablerownex += '<div class="text-center"><a class="button scroll-to-anchor" id="caspardo_content_load"><span class="load-more-result-in18">Weitere Ergebnisse laden...</span></a></div>';
        tablerownex += '</div>';
        jQuery("#caspardo_contentresults").append(tablerownex);
      }
      // For IFB
      if (CaspardoAPI.config.layout === "www_ifb_de") {
        let tablerownex: string = '<div id="caspardo_content_nextpage" class="col-xs-12">';
        tablerownex += '<div class="row p-y-10 lh-26 flex-center" >';
        tablerownex += '<div class="col-sm-12 text-center"><a class="button scroll-to-anchor" id="caspardo_content_load"><i class="ifb-icon-arrow fa-rotate-90" ></i>Weitere Informationen laden...</a></div>';
        tablerownex += '</div>';
        tablerownex += '</div>';
        jQuery("#caspardo_contentresults").append(tablerownex);
      }
    }
    IN18.IN18Translation();
  }

}
