import { CaspardoSeminarFinderQuery } from "./CaspardoSeminarFinderQuery";
import { CaspardoAPI } from "../common/CaspardoAPI";

import { CaspardoSelectedFilter } from "../template/CaspardoSelectedFilter";
import { CaspardoTemplate } from "../template/CaspardoTemplate";


var caspardoConfig = require("../caspardoconfig.json");
var moment = require('moment');
export class CaspardoSeminarFinder {

    static refreshFilter: boolean = true;
    static config: any = "";
    static action: string = "";
    static dateValueFormat: string = caspardoConfig.searchengine_dateformat;
    static HTMLElement_dateFrom: JQuery = null;
    static HTMLElement_dateTo: JQuery = null;
    static HTMLElement_selectCity: JQuery = null;
    static HTMLElement_selectSeminar: JQuery = null;
    static HTMLElement_selectKWs: JQuery = null;
    static seminardata: any = null;

    // Init the seminar finder with the static file (Perfromance!)
    constructor(caspardoConfig1: any, caspardoSeminarFinderJSON: any) {
        CaspardoSeminarFinder.config = caspardoConfig1;
        CaspardoSeminarFinder.seminardata = caspardoSeminarFinderJSON;
        // Add the template to the seminarfinder div
        jQuery(".caspardo-seminarfinder").append(caspardoConfig.seminarfinder.template);
        jQuery(".caspardo-seminarfinder-mobile").append(caspardoConfig.seminarfinder.template_mobile);

        // Assign HTML ELements selected by the classes
        CaspardoSeminarFinder.HTMLElement_dateFrom = jQuery(".caspardo_seminardate_fromval");
        CaspardoSeminarFinder.HTMLElement_dateTo = jQuery(".caspardo_seminardate_toval");
        CaspardoSeminarFinder.HTMLElement_selectCity = jQuery(".caspardo-seminarfinder-cities");
        CaspardoSeminarFinder.HTMLElement_selectSeminar = jQuery(".caspardo-seminarfinder-seminars");
        CaspardoSeminarFinder.HTMLElement_selectKWs = jQuery(".caspardo-seminarfinder-kws");

        // Add the events
        this.initEvent(caspardoSeminarFinderJSON);
    }

    /**
    * Init the events of the CASPARDO Seminar Finder 
    * @param caspardoSeminarFinderJSON  CASPARDO Seminar Finder Data
    */
    public initEvent(caspardoSeminarFinderJSON: any) {
        // Get Startdate
        var startDateSeminare = new Date();
        startDateSeminare.setDate(startDateSeminare.getDate());
        var options = {
            format: CaspardoSeminarFinder.dateValueFormat,
            todayHighlight: true,
            autoclose: true,
            language: 'de',
            startDate: startDateSeminare
        };
        // Init the Datepicker
        (<any>CaspardoSeminarFinder.HTMLElement_dateFrom).datepicker(options);
        (<any>CaspardoSeminarFinder.HTMLElement_dateFrom).datepicker().on('changeDate', function (e) {
            var element = $(this);
            let filter_seminardate_from: string = <string>jQuery(element).val();

            // var data_checkbox_item = element.attr("data-checkbox-item").valueOf();
            var data_component_id = element.attr("data-component-id").valueOf();

            let finderQuery = new CaspardoSeminarFinderQuery(data_component_id);
            CaspardoSeminarFinder.search("seminartheme", finderQuery);


        });;

        // Init the Datepicker: DateTo
        (<any>CaspardoSeminarFinder.HTMLElement_dateTo).datepicker(options);
        (<any>CaspardoSeminarFinder.HTMLElement_dateTo).datepicker().on('changeDate', function (e) {
            var element = $(this);
            // Get the current date
            let filter_seminardate_to: string = <string>jQuery(element).val();

            var data_component_id = element.attr("data-component-id").valueOf();
            let finderQuery = new CaspardoSeminarFinderQuery(data_component_id);
            if (CaspardoSeminarFinder.refreshFilter)
                CaspardoSeminarFinder.search("seminartheme", finderQuery);

        });;

        // Search Button Click
        jQuery("#caspardo_seminarfinder_btn, .caspardo_seminarfinder_btn_mobile").click((evt) => {
            //let element = evt.target;
            let filter_query: string = "";

            var data_component_id: string = <string>evt.target.getAttribute("data-component-id")
            let finderQuery = new CaspardoSeminarFinderQuery(data_component_id);

            let seminartitlehash: string = finderQuery.seminartitlehash;
            if (seminartitlehash) {
                if (seminartitlehash != "")
                    filter_query += "&seminartitlehash=" + seminartitlehash;
            }

            let city: string = finderQuery.city;
            if (city) {
                if (city != "")
                    filter_query += "&city=" + encodeURI(city);
            }

            let date_from: string = finderQuery.date_from;
            if (date_from) {
                if (date_from != "")
                    filter_query += "&from=" + date_from;
            }

            let date_to: string = finderQuery.date_to;
            if (date_to) {
                if (date_to != "")
                    filter_query += "&to=" + date_to;
            }

            let target: string = CaspardoSeminarFinder.config.searchengine_url_target + "?query=" + filter_query;
            window.location.assign(target);
        });


        // Load Seminar Data
        var jqxhr = jQuery.getJSON(caspardoConfig.seminarfinder.default_data_source, function () {
        })
            .done(function (data) {
                CaspardoSeminarFinder.seminardata = data;
                CaspardoSeminarFinder.setSearchBtnName(data.seminar_appointment_count);
                CaspardoSeminarFinder.generateSeminars(data);
                CaspardoSeminarFinder.generateCities(data.cities[0]);
                CaspardoSeminarFinder.generateCalendarWeeks(null);
            })
            .fail(function () {
                //console.log("error");
            })
            .always(function () {
                //console.log("complete");
            });
    }

    /**
     * Sets the seminar serach button name.
     * @param hits  Number of appointment hits.
     */
    public static setSearchBtnName(hits: number) {
        jQuery("#caspardo_seminarfinder_btn, .caspardo_seminarfinder_btn_mobile").html(hits + " Seminartermine anzeigen");
        return false;
    }

    /**
    * Gets the frist and last date of a week.
    * @param weekNumber  weeknumber in year
    * @param year year
    * @returns   Returns JSONObject {firstRealDate:Date,firstWeekDate:Date,lastWeekDate:Date, value:string, calendarWeek:number, year:number}
    */
    public static getFirstLastDateOfWeek(weekNumber: number, year: number) {
        var res: any = {
            firstRealDate: null,
            firstWeekDate: null,
            lastWeekDate: null,
            value: null,
            calendarWeek: weekNumber,
            year: year
        }
        var begin = moment();
        begin.year(year).isoWeek(weekNumber).startOf('isoWeek');
        begin.locale("de");
        var end = moment();
        end.year(year).isoWeek(weekNumber).endOf('isoWeek');
        end.locale("de");
        res.firstRealDate = begin.toDate();
        res.firstWeekDate = begin.format("DD.MM.YYYY");
        res.lastWeekDate = end.format("DD.MM.YYYY");
        res.value = begin.format("DD.MM.YYYY") + "-" + end.format("DD.MM.YYYY");
        return res;
    }

    /**
    * Generates the seminar HTML-Select Element
    * @param seminar  CASPARDO Seminar Object
    */
    public static replaceSeminarRow(seminar: any) {
        let row: string = caspardoConfig.seminarfinder.html_seminar_select_row;
        row = row.replace(new RegExp('{id}', 'g'), seminar.seminar_search_hash);
        row = row.replace(new RegExp('{topseminar}', 'g'), seminar.topseminar);
        row = row.replace(new RegExp('{name}', 'g'), seminar.name);
        return row;
    }

    /**
    * Generates the seminar HTML-Select Element
    * @param caspardoSeminarFinderJSON  CASPARDO Seminar Finder Data
    */
    public static generateSeminars(caspardoSeminarFinderJSON: any) {
        // Init the first option row
        let resHTML: string = caspardoConfig.seminarfinder.html_seminar_select_default;
        let resTopSeminarHTML: string = "";
        let resSeminarHTML: string = "";

        // Sort by name
        if (caspardoSeminarFinderJSON.seminars[0]) {
            caspardoSeminarFinderJSON.seminars[0].sort(function (a, b) { return (a.name < b.name) ? -1 : 1 });
            // Loop through the seminars
            for (var i = 0; i < caspardoSeminarFinderJSON.seminars[0].length; i++) {
                if (caspardoSeminarFinderJSON.seminars[0][i].name != "") {
                    let row: string = caspardoConfig.seminarfinder.html_seminar_select_row;
                    if (caspardoSeminarFinderJSON.seminars[0][i].topseminar === "true") {
                        resTopSeminarHTML += CaspardoSeminarFinder.replaceSeminarRow(caspardoSeminarFinderJSON.seminars[0][i]);
                    }
                    else {
                        resSeminarHTML += CaspardoSeminarFinder.replaceSeminarRow(caspardoSeminarFinderJSON.seminars[0][i]);
                    }
                }
            }
        }

        // Build the seminares
        let fillUpLine: string = "";
        let nameGroupTopSeminar = fillUpLine + "Top Seminare" + fillUpLine;
        let nameSeminare = fillUpLine + "Seminare" + fillUpLine;
        resHTML += "<optgroup label=\"" + nameGroupTopSeminar + "\">" + resTopSeminarHTML + "</optgroup>";
        resHTML += "<optgroup label=\"" + nameSeminare + "\">" + resSeminarHTML + "</optgroup>";

        // Insert the component
        CaspardoSeminarFinder.HTMLElement_selectSeminar.html(resHTML);

        // Register the events for the HTML 
        CaspardoSeminarFinder.HTMLElement_selectSeminar.on('change', function () {
            var element = $(this);
            var data_component_id = element.attr("data-component-id").valueOf();
            CaspardoSeminarFinder.resetSeminarTheme();
            let finderQuery = new CaspardoSeminarFinderQuery(data_component_id);
            CaspardoSeminarFinder.search("seminartheme", finderQuery);
        });

    }


    /**
    * Generates the HTML-City-Select Element
    * @param caspardoSeminarCityJSONArr  CASPARDO Seminar Finder Array ("city1","city2",...)
    */
    public static generateCities(caspardoSeminarCityJSONArr: any) {
        // Init the first option row
        let resHTML: string = caspardoConfig.seminarfinder.html_city_select_header;
        // Sort by name
        caspardoSeminarCityJSONArr.sort(function (a, b) { return a < b ? -1 : 1 });
        // Loop thorgh the cities
        for (var i = 0; i < caspardoSeminarCityJSONArr.length; i++) {
            // Get the option html row
            let row: string = caspardoConfig.seminarfinder.html_city_select_row;
            // Replace the values
            resHTML += row.replace(new RegExp('{name}', 'g'), caspardoSeminarCityJSONArr[i]);
        }
        // Set the html to the div id=caspardo-seminarfinder-cities
        CaspardoSeminarFinder.HTMLElement_selectCity.html(resHTML);
        // Init the events
        CaspardoSeminarFinder.HTMLElement_selectCity.on('change', function () {
            var element = $(this);
            var data_component_id = element.attr("data-component-id").valueOf();

            CaspardoSeminarFinder.resetSeminarCity();

            let finderQuery = new CaspardoSeminarFinderQuery(data_component_id);
            CaspardoSeminarFinder.search("city", finderQuery);
        });
    }


    /**
    * Generates the calendar weeks HTML-Select Element
    * @param caspardoSeminarFinderJSON  CASPARDO Seminar Finder Data
    */
    public static generateCalendarWeeks(jsonArrCalendarWeeks: any) {
        // Init with today
        var startDateSeminare = new Date();
        var weeknumber = moment(startDateSeminare).isoWeek();
        var weekcount = moment().isoWeeksInYear();
        var year = moment().year();
        // Init the first option
        let resHTML: string = caspardoConfig.seminarfinder.html_weeknumber_select_header;
        if (jsonArrCalendarWeeks === null) {
            // Generate the default calendar weeks
            for (var i = weeknumber; i <= weekcount; i++) {
                let row: string = caspardoConfig.seminarfinder.html_weeknumber_select_row;
                resHTML += row.replace(new RegExp('\{name\}', 'g'), i + "-" + year);
            }
        } else {
            for (var z = 0; z < jsonArrCalendarWeeks.length; z++) {
                let row: string = caspardoConfig.seminarfinder.html_weeknumber_select_row;
                resHTML += row.replace(new RegExp('\{name\}', 'g'), jsonArrCalendarWeeks[z]);
            }
        }
        // Set the html to the id=caspardo-seminarfinder-kws 
        CaspardoSeminarFinder.HTMLElement_selectKWs.html(resHTML);
        // Append the events
        CaspardoSeminarFinder.HTMLElement_selectKWs.on('change', function () {

            var element = $(this);
            var data_component_id = element.attr("data-component-id").valueOf();

            let year_kw: string = (<any>this).value;

            let finderQuery = new CaspardoSeminarFinderQuery(data_component_id);

            if (year_kw != "") {
                let calVals: any = year_kw.split("-");
                var res = CaspardoSeminarFinder.getFirstLastDateOfWeek(calVals[0], calVals[1]);
                finderQuery.date_from = res.firstWeekDate;
                finderQuery.date_to = res.lastWeekDate;
                CaspardoSeminarFinder.refreshFilter = false;
                // (<any>CaspardoSeminarFinder.HTMLElement_dateFrom).val(res.firstWeekDate).datepicker("update");
                // (<any>CaspardoSeminarFinder.HTMLElement_dateTo).val(res.lastWeekDate).datepicker("update");

                (<any>CaspardoSeminarFinder.HTMLElement_dateFrom).val(res.firstWeekDate);
                (<any>CaspardoSeminarFinder.HTMLElement_dateTo).val(res.lastWeekDate);


            }
            else {
                (<any>CaspardoSeminarFinder.HTMLElement_dateFrom).val("");//.datepicker("update");
                (<any>CaspardoSeminarFinder.HTMLElement_dateTo).val("");//.datepicker("update");
                finderQuery.date_from = "";
                finderQuery.date_to = "";
            }
            CaspardoSeminarFinder.refreshFilter = true;
            CaspardoSeminarFinder.search("cws", finderQuery);

        });
    }

    public static resetSeminarCity() {
        // (<any>CaspardoSeminarFinder.HTMLElement_dateTo.val("")).datepicker("update");
        // (<any>CaspardoSeminarFinder.HTMLElement_dateFrom.val("")).datepicker("update");
        // CaspardoSeminarFinder.HTMLElement_selectKWs.val("");
    }


    public static resetSeminarTheme() {
        // (<any>CaspardoSeminarFinder.HTMLElement_dateTo.val("")).datepicker("update");
        // (<any>CaspardoSeminarFinder.HTMLElement_dateFrom.val("")).datepicker("update");
        // CaspardoSeminarFinder.HTMLElement_selectCity.val("");
        // CaspardoSeminarFinder.HTMLElement_selectCity.val("");
        // CaspardoSeminarFinder.HTMLElement_selectKWs.val("");
    }

    /**
     * Search for appointments.
     * @param type  "seminartheme" | "city" defines the search type
     */
    public static search(type: string, finderQuery: CaspardoSeminarFinderQuery) {

        let seminartitlehash: string = finderQuery.seminartitlehash;
        let city: string = finderQuery.city;
        let date_from: string = finderQuery.date_from;
        let date_to: string = finderQuery.date_to;


        let filterquery: string = "";
        if (!date_from)
            date_from = "";
        if (!date_to)
            date_to = "";
        if (!city)
            city = "";
        if (!seminartitlehash)
            seminartitlehash = "";
        let addDate: boolean = (date_from != "") && (date_to != "");
        if (addDate)
            filterquery += "&to=" + date_to + "&from=" + date_from;
        else if (date_from != "")
            filterquery += "&from=" + date_from + "&to=31.12.2025";

        if (seminartitlehash != "")
            filterquery += "&seminartitlehash=" + seminartitlehash;
        if (city != "")
            filterquery += "&city=" + encodeURI(city);
        if (filterquery === "") {

            CaspardoSeminarFinder.setSearchBtnName(CaspardoSeminarFinder.seminardata.seminar_appointment_count);
        }
        else {
            let apiURL: string = CaspardoSeminarFinder.config.searchengine_url + "/servlet/findIt?query=&tab=seminare&type=seminar&searchengineid=" + caspardoConfig.searchengine_id + "&output=json&pagetype=2&page=1&language=" + CaspardoAPI.defaultLanguage + filterquery;
            console.log(apiURL);
            var jqxhr = jQuery.getJSON(apiURL, function () {
                //console.log("success");
            })
                .done(function (data) {
                    let counter = data.searchresult.header.hits;
                    CaspardoSeminarFinder.setSearchBtnName(counter);
                    let doIt: boolean = false;
                    if ((type === "seminartheme") && (doIt)) {
                        for (var i = 0; i < data.searchresult.header.filters.filter.length; i++) {
                            let filter_name: string = data.searchresult.header.filters.filter[i].name;
                            // City Filter
                            if (filter_name === "city") {
                                var cityArr = new Array();
                                if (data.searchresult.header.hits > 0) {
                                    if (!(data.searchresult.header.filters.filter[i].value instanceof Array)) {
                                        var tempArray = [];
                                        tempArray.push(data.searchresult.header.filters.filter[i].value);
                                        data.searchresult.header.filters.filter[i].value = tempArray;
                                    }
                                    if (data.searchresult.header.filters.filter[i].value.length) {
                                        for (var j = 0; j < data.searchresult.header.filters.filter[i].value.length; j++) {
                                            if (data.searchresult.header.filters.filter[i].value[j].content) {
                                                let city_name: string = data.searchresult.header.filters.filter[i].value[j].content;
                                                console.log(city_name);
                                                var length = cityArr.push(city_name);
                                            }
                                        }
                                    }
                                }
                                CaspardoSeminarFinder.generateCities(cityArr);
                            }
                            // Weeknumber Filter
                            if (filter_name === "weeknumbers") {
                                var weeknumberArr = new Array();
                                if (data.searchresult.header.hits > 0) {
                                    if (!(data.searchresult.header.filters.filter[i].value instanceof Array)) {
                                        var tempArray = [];
                                        tempArray.push(data.searchresult.header.filters.filter[i].value);
                                        data.searchresult.header.filters.filter[i].value = tempArray;
                                    }
                                    if (data.searchresult.header.filters.filter[i].value.length) {
                                        for (var j = 0; j < data.searchresult.header.filters.filter[i].value.length; j++) {
                                            let weeknumber: string = data.searchresult.header.filters.filter[i].value[j].content;
                                            console.log("weeknumber:" + weeknumber);
                                            var length = weeknumberArr.push(weeknumber);
                                        }
                                    }
                                }
                                CaspardoSeminarFinder.generateCalendarWeeks(weeknumberArr);
                            }
                        }
                        //resHTML += row.replace(new RegExp('\{name\}', 'g'), i + "-" + year);
                    }
                    if ((type === "city") && (doIt)) {
                        for (var i = 0; i < data.searchresult.header.filters.filter.length; i++) {
                            let filter_name: string = data.searchresult.header.filters.filter[i].name;
                            // Weeknumber Filter
                            if (filter_name === "weeknumbers") {
                                var weeknumberArr = new Array();
                                if (data.searchresult.header.hits > 0) {
                                    if (!(data.searchresult.header.filters.filter[i].value instanceof Array)) {
                                        var tempArray = [];
                                        tempArray.push(data.searchresult.header.filters.filter[i].value);
                                        data.searchresult.header.filters.filter[i].value = tempArray;
                                    }
                                    if (data.searchresult.header.filters.filter[i].value.length) {
                                        for (var j = 0; j < data.searchresult.header.filters.filter[i].value.length; j++) {
                                            let weeknumber: string = data.searchresult.header.filters.filter[i].value[j].content;
                                            console.log("weeknumber:" + weeknumber);
                                            var length = weeknumberArr.push(weeknumber);
                                        }
                                    }
                                }
                                CaspardoSeminarFinder.generateCalendarWeeks(weeknumberArr);
                            }
                        }
                    }
                })
                .fail(function () {
                    //console.log("error");
                })
                .always(function () {
                    //console.log("complete");
                });
        }
        return false;
    }
}