export class CaspardoRecommendation {

  query: string;

  constructor(query: string) {
    this.query = query;
  }


  public toHTML() {
    return "Hello, " + this.query;
  }
}