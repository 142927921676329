
import { IN18 } from "../../IN18Translation";
import { CaspardoAPI } from "../../common/CaspardoAPI";

export class CaspardoContentFilter {

    /**
     * find Domain
     * @param arrDomains 
     * @param domain 
     */
    public static findDomain(arrDomains: Array<any>, domain: Array<any>) {
        let res: boolean = false;
        for (let domainObj of arrDomains) {
            for (let compareDomain of domain) {
                if (domainObj.content === compareDomain)
                    return true;
            }
        }
        return res;
    }

    /**
     * Generating the information filter
     * @param arrDomains 
     * @param filter 
     */
    public static generateInformationFilter(arrDomains: Array<any>, filter: any) {
        let resHTML = "";
        let doIt: boolean = this.findDomain(arrDomains, filter.domains);

        if (doIt) {
            
            // TODO: Implment html-snippets
            let res_filter_name=IN18.IN18TranslateContentFilter(filter.name);

            let content_filter: string = "<div class=\"col-12 caspardo-content-filter-row\"><input type=\"checkbox\" class=\"caspardo_content_filtercheckbox\" name=\"domain\" value=\"" + filter.id + "\"> {icon} " + res_filter_name + "</div>";
            let icon_info: string = '<i class=\"fa fa-info-circle caspardo-content-filtericon\"></i>';
            let icon_news: string = '<i class=\"fa fa-globe caspardo-content-filtericon\"></i>';
            let icon_videos: string = '<i class=\"fa fa-play-circle caspardo-content-filtericon\"></i> ';

            let resfilter: string = '';

            if (filter.name === "Informationen")
                resfilter = content_filter.replace(new RegExp('{icon}', 'g'), icon_info);
            if (filter.name === "News")
                resfilter = content_filter.replace(new RegExp('{icon}', 'g'), icon_news);
            if (filter.name === "Videos")
                resfilter = content_filter.replace(new RegExp('{icon}', 'g'), icon_videos);

            resHTML += resfilter;
        }
        return resHTML;
    }

    /**
     * generate Content Filter
     * @param arrDomains 
     */
    public static generateContentFilter(arrDomains: Array<any>) {
        let resHTML: string = "";
        var ar = CaspardoAPI.config;
        // resHTML += `<h4 class="content-filter-heading">Filter: Information</h4>`
        for (let filter of CaspardoAPI.config.searchresult_content.filterconf) {
            resHTML += CaspardoContentFilter.generateInformationFilter(arrDomains, filter);
        }
        resHTML += "";
        jQuery("#caspardo_contentfilter_domains").html(resHTML);

    }


}
