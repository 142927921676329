import { CaspardoAPI } from "./common/CaspardoAPI";

var caspardoConfig = require("./caspardoconfig.json");

var lanuageGerman = require("../dist/layout/" + caspardoConfig.layout + "/assets/in18/language_de.json");
var lanuageEnglish = require("../dist/layout/" + caspardoConfig.layout + "/assets/in18/language_en.json");

export class IN18 {

    static lang_de: any = lanuageGerman;
    static lang_en: any = lanuageEnglish;
    static defaultLanguage: string;

    constructor() {

    }

    public static IN18TranslateNameByKey(key: string) {
        let res: string = "Key: "+key+" not in language: "+CaspardoAPI.defaultLanguage+" defined.";
        let in18Language: any = IN18.getLanguage(CaspardoAPI.defaultLanguage);
        let keys: any = Object.keys(in18Language);
        let index = keys.indexOf(key);
        if (index >= 0)
            res = in18Language[key];
        return res;
    }

    public static IN18TranslateTabByType(type: string) {

        let res: string = "";
        let in18Language: any = IN18.getLanguage(CaspardoAPI.defaultLanguage);
        let keys: any = Object.keys(in18Language.tabnames);
        let index = keys.indexOf(type);
        if (index >= 0)
            res = in18Language.tabnames[type];
        return res;
    }

    public static IN18TranslateShopFilterAttr1(filter_keynane: string) {
        let res: string = filter_keynane;
        let in18Language: any = IN18.getLanguage(CaspardoAPI.defaultLanguage);
        let keys: any = Object.keys(in18Language.shop_filter_attr1_in18);
        let index = keys.indexOf(filter_keynane);
        if (index >= 0)
            res = in18Language.shop_filter_attr1_in18[filter_keynane];
        return res;
    }

    public static IN18TranslateShopCategory1(filter_keynane: string) {
        let res: string = filter_keynane;
        let in18Language: any = IN18.getLanguage(CaspardoAPI.defaultLanguage);
        let keys: any = Object.keys(in18Language.shop_filter_category1_in18);
        let index = keys.indexOf(filter_keynane);
        if (index >= 0)
            res = filter_keynane+": "+in18Language.shop_filter_category1_in18[filter_keynane];
        return res;
    }

    public static IN18TranslateContentFilter(filter_keynane: string) {
        let res: string = filter_keynane;
        let in18Language: any = IN18.getLanguage(CaspardoAPI.defaultLanguage);
        let keys: any = Object.keys(in18Language.content_filter_in18);
        let index = keys.indexOf(filter_keynane);
        if (index >= 0)
            res = in18Language.content_filter_in18[filter_keynane];
        return res;
    }

    public static getLanguage(lang: string) {
        let in18Language: any = null;

        if (lang === "de") {
            in18Language = lanuageGerman;
        }

        // For German Language
        if (lang === "en") {
            in18Language = lanuageEnglish;
        }
        return in18Language;
    }



    public static IN18Translation() {

        let keys = [];
        let in18Language: any = null;


        // get all key from json file and put it into key list,
        // For german language
        if (CaspardoAPI.defaultLanguage === "de") {
            keys = Object.keys(lanuageGerman);
            in18Language = lanuageGerman;
        }

        // For english language
        if (CaspardoAPI.defaultLanguage === "en") {
            keys = Object.keys(lanuageEnglish);
            in18Language = lanuageEnglish;
        }

        keys.forEach(key => {
                $('.' + key).text(in18Language[key]);
        });

    }

}
