var caspardoConfig = require("../caspardoconfig.json");

export class CaspardoLogger {

    /**
    * Writes the log information into the console.
    * @param msg  Log-Message in console of browser when searchengine mode is "dev" 
    */
    public static log(msg: string) {
        if (caspardoConfig.searchengine_mode == "dev") {
            console.log(msg);
        }
    }

}
