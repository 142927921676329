export class CaspardoSeminarFinderQuery {

    public seminartitlehash: string;
    public city: string;
    public date_from: string;
    public date_to: string;

    constructor(component_id: string) {
        this.seminartitlehash = <string>$(".caspardo-seminarfinder-seminars[data-component-id='" + component_id + "']").val();
        this.city = <string>$(".caspardo-seminarfinder-cities[data-component-id='" + component_id + "']").val();;
        this.date_from = <string>$(".caspardo_seminardate_fromval[data-component-id='" + component_id + "']").val();;
        this.date_to = <string>$(".caspardo_seminardate_toval[data-component-id='" + component_id + "']").val();;
    }

}
