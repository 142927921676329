import { CaspardoSelectedFilter } from "../Template/CaspardoSelectedFilter";

export class CaspardoPriceRangeSlider {
    
    private _html_template:string=`
    <div class="row">
    <div class="container caspardo-filter">
        <div class="row">
            <div class="col-xs-12">
                <div>
                    <button type="button" class="btn btn-block caspardo-filter-btn"
                        data-toggle="collapse" data-target=".caspardo-filter-pricerange">
                        <span
                            class="glyphicon glyphicon-collapse-down caspardo-filter-btn-status pull-right"></span>
                        <span class="caspardo-filter-btn-name pull-left">Preis Filter</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="caspardo-filter-pricerange row collapse in" aria-expanded="true">
            <div class="col-xs-12 caspardo-filter-groupheadline">
                <span class="caspardo-filter-headline">Preisfilter</span>
            </div>
            <div class="col-xs-12">
                <input type="text" class="example_id" name="example_name" value="" />
                <input type="hidden" id="caspardo_filter_price_min" value="" />
                <input type="hidden" id="caspardo_filter_price_max" value="" />
            </div>
        </div>
    </div>
</div>
    `;




    public initSlider(id: string, price_min: number,price_max: number) {
              
        var slider=$("."+id);

        // Init the range slider
        slider.ionRangeSlider({
            skin: "flat",
            type: "double",
            grid: true,
            min: price_min,
            max: price_max,
            from: price_min,
            to: price_max,
            prefix: "EUR ",
            step: 1,
            onChange: function (data) {
                console.dir("Change: "+data);
            },
            onFinish: function (data) {
                console.dir("Finish: ["+data.from+" -> "+data.to+"]");
                $("#caspardo_filter_price_min").val(data.from*100);
                $("#caspardo_filter_price_max").val(data.to*100);
               
                CaspardoSelectedFilter.printSeletedShopFilters();
            }
                   // all values
            });

       
        // Set the slider events
        slider.on("change", function () {
            var $inp = $(this);
            var v = $inp.prop("value");     // input value in format FROM;TO
            var from = $inp.data("from");   // input data-from attribute
            var to = $inp.data("to");       // input data-to attribute
        
            console.log(v, from, to);       // all values
        });

        



    }



}