import { CaspardoSeminarFinderV2 } from "./CaspardoSeminarFinderV2";

export class CaspardoSeminarFinderQueryV2 {

    public seminartitlehash: Array<1> = [];
    public city: string;
    public date_from: string;
    public date_to: string;
    public type: string;
    static seminarsList: any = {};

    constructor(component_id: string) {
        if (CaspardoSeminarFinderV2.tabType == 'top-seminar') {
            this.seminartitlehash = CaspardoSeminarFinderQueryV2.seminarsList['seminar'];
        }
        if (CaspardoSeminarFinderV2.tabType == 'all-seminar') {
            this.seminartitlehash = CaspardoSeminarFinderQueryV2.seminarsList['seminar'];
        }
        if (CaspardoSeminarFinderV2.tabType == 'categories') {
            this.seminartitlehash = CaspardoSeminarFinderQueryV2.seminarsList['category'];
        }
        CaspardoSeminarFinderQueryV2.getUniqueCategoryList();

        this.city = <string>$(".caspardo-seminarfinder-cities[data-component-id='" + component_id + "']").val();;
        this.date_from = <string>$(".caspardo_seminardate_fromval[data-component-id='" + component_id + "']").val();;
        this.date_to = <string>$(".caspardo_seminardate_toval[data-component-id='" + component_id + "']").val();;
    }

    public static getUniqueCategoryList() {
        let seminarsList = CaspardoSeminarFinderQueryV2.seminarsList;
        if (seminarsList['category'] != undefined) {
            seminarsList['category'] = seminarsList['category'].concat(seminarsList['functions']);
            var categoryList = seminarsList['category'].concat();
            for (var i = 0; i < categoryList.length; ++i) {
                for (var j = i + 1; j < categoryList.length; ++j) {
                    if (categoryList[i] === categoryList[j])
                        categoryList.splice(j--, 1);
                    if (categoryList[i] === undefined)
                        categoryList.splice(i, 1);
                }
            }
            seminarsList['category'] = categoryList;
            delete seminarsList['functions'];
        }
    }

}