import { CaspardoSeminarFinderQueryV2 } from "./CaspardoSeminarFinderQueryV2";
import { CaspardoAPI } from "../common/CaspardoAPI";

import { CaspardoSelectedFilter } from "../template/CaspardoSelectedFilter";
import { CaspardoTemplate } from "../template/CaspardoTemplate";


var caspardoConfig = require("../caspardoconfig.json");
var moment = require('moment');
export class CaspardoSeminarFinderV2 {
    // Refresh Filter Status
    static refreshFilter: boolean = true;
    // General Configuration
    static config: any = "";

    static action: string = "";

    static dateValueFormat: string = caspardoConfig.searchengine_dateformat;

    static HTMLElement_dateFrom: JQuery = null;
    static HTMLElement_dateTo: JQuery = null;
    static HTMLElement_selectCity: JQuery = null;
    static HTMLElement_selectTopSeminar: JQuery = null;
    static HTMLElement_selectAllSeminar: JQuery = null;
    static HTMLElement_selectCategories: JQuery = null;
    static HTMLElement_selectFunctions: JQuery = null;
    static HTMLElement_selectKWs: JQuery = null;
    static totalSelectedItems: number = 0;

    static selected_seminars: any = [];
    static selected_categories: any = [];
    static selected_functions: any = [];

    static seminardata: any = null;

    static tabType: string = '';

    static allseminarhash: string = "";

    static counterTopAll: number = 0;
    static counterCategories: number = 0;
    static counterfunctions: number = 0;


    // Init the seminar finder with the static file (Perfromance!)
    constructor(caspardoConfig1: any, caspardoSeminarFinderJSON: any) {
        // Init configuration + seminardata
        CaspardoSeminarFinderV2.config = caspardoConfig1;
        CaspardoSeminarFinderV2.seminardata = caspardoSeminarFinderJSON;

        // Add the templates to the seminarfinder div
        jQuery(".caspardo-seminarfinder").append(caspardoConfig.seminarfinder.templatev2);
        jQuery(".caspardo-seminarfinder-mobile").append(caspardoConfig.seminarfinder.template_mobilev2);

        // Assign HTML ELements selected by the classes
        CaspardoSeminarFinderV2.HTMLElement_dateFrom = jQuery(".caspardo_seminardate_fromval");
        CaspardoSeminarFinderV2.HTMLElement_dateTo = jQuery(".caspardo_seminardate_toval");
        CaspardoSeminarFinderV2.HTMLElement_selectCity = jQuery(".caspardo-seminarfinder-cities");
        CaspardoSeminarFinderV2.HTMLElement_selectTopSeminar = jQuery(".caspardo-seminarfinder-top-seminars");
        CaspardoSeminarFinderV2.HTMLElement_selectAllSeminar = jQuery(".caspardo-seminarfinder-all-seminars");
        CaspardoSeminarFinderV2.HTMLElement_selectCategories = jQuery(".caspardo-seminarfinder-catagories-seminars");
        CaspardoSeminarFinderV2.HTMLElement_selectFunctions = jQuery(".caspardo-seminarfinder-functions");
        CaspardoSeminarFinderV2.HTMLElement_selectKWs = jQuery(".caspardo-seminarfinder-kws");

        // Add the events
        this.initEvent(caspardoSeminarFinderJSON);



    }

    /**
    * Init the events of the CASPARDO Seminar Finder 
    * @param caspardoSeminarFinderJSON  CASPARDO Seminar Finder Data
    */
    public initEvent(caspardoSeminarFinderJSON: any) {

        // Get Startdate
        var startDateSeminare = new Date();
        startDateSeminare.setDate(startDateSeminare.getDate());

        // Init the Datepicker: DateTo
        var options = {
            format: CaspardoSeminarFinderV2.dateValueFormat,
            todayHighlight: true,
            autoclose: true,
            language: 'de',
            startDate: startDateSeminare
        };
        (<any>CaspardoSeminarFinderV2.HTMLElement_dateFrom).datepicker(options);
        // Assign onChangeDate Event
        (<any>CaspardoSeminarFinderV2.HTMLElement_dateFrom).datepicker().on('changeDate', function (e) {
            var element = $(this);
            let filter_seminardate_from: string = <string>jQuery(element).val();

            // var data_checkbox_item = element.attr("data-checkbox-item").valueOf();
            var data_component_id = element.attr("data-component-id").valueOf();

            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);
            CaspardoSeminarFinderV2.search("seminartheme", finderQuery);


        });;

        // Init the Datepicker: DateTo (Default is the same startdate)
        (<any>CaspardoSeminarFinderV2.HTMLElement_dateTo).datepicker(options);
        // Assign onChangeDate Event
        (<any>CaspardoSeminarFinderV2.HTMLElement_dateTo).datepicker().on('changeDate', function (e) {
            var element = $(this);
            // Get the current date
            let filter_seminardate_to: string = <string>jQuery(element).val();

            var data_component_id = element.attr("data-component-id").valueOf();
            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);
            if (CaspardoSeminarFinderV2.refreshFilter)
                CaspardoSeminarFinderV2.search("seminartheme", finderQuery);

        });;

        // Search Button Click
        jQuery("#caspardo_seminarfinder_btn, .caspardo_seminarfinder_btn_mobile").click((evt) => {
            //let element = evt.target;
            let filter_query: string = "";

            var data_component_id: string = <string>evt.target.getAttribute("data-component-id")
            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);

            let seminartitlehash: Array<1> = finderQuery.seminartitlehash;
            if (seminartitlehash) {
                if (seminartitlehash != []) {
                    CaspardoSeminarFinderV2.allseminarhash = "";

                    let listSeminars = CaspardoSeminarFinderQueryV2.seminarsList;
                    let data = Object.keys(listSeminars);

                    for (let index = 0; index < data.length; index++) {
                        if (data[index] === 'category' || data[index] == 'functions') {
                            for (let category = 0; category <= listSeminars[data[index]].length; category++) {
                                if (listSeminars[data[index]][category] != undefined)
                                    CaspardoSeminarFinderV2.allseminarhash += "&category=" + listSeminars[data[index]][category];
                            }
                        }
                        if (data[index] == 'seminar') {
                            for (let seminar = 0; seminar <= listSeminars[data[index]].length; seminar++) {
                                if (listSeminars[data[index]][seminar] != undefined) {
                                    CaspardoSeminarFinderV2.allseminarhash += "&seminartitlehash=" + listSeminars[data[index]][seminar];
                                }
                            }
                        }
                    }
                    filter_query += CaspardoSeminarFinderV2.allseminarhash;
                }
            }

            let city: string = finderQuery.city;
            if (city) {
                if (city != "")
                    filter_query += "&city=" + encodeURI(city);
            }

            let date_from: string = finderQuery.date_from;
            if (date_from) {
                if (date_from != "")
                    filter_query += "&from=" + date_from;
            }

            let date_to: string = finderQuery.date_to;
            if (date_to) {
                if (date_to != "")
                    filter_query += "&to=" + date_to;
            }

            let target: string = CaspardoSeminarFinderV2.config.searchengine_url_target + "?query=" + filter_query;
            window.location.assign(target);
        });


        // Load Seminar Data
        var jqxhr = jQuery.getJSON(caspardoConfig.seminarfinder.default_data_source, function () {
        })
            .done(function (data) {
                CaspardoSeminarFinderV2.seminardata = data;
                CaspardoSeminarFinderV2.generateTab(caspardoConfig.seminarfinder.tabs);
                CaspardoSeminarFinderV2.setSearchBtnName(data.seminar_appointment_count);
                CaspardoSeminarFinderV2.generateSeminars(data);
                CaspardoSeminarFinderV2.generateCities(data.cities[0]);
                CaspardoSeminarFinderV2.generateCalendarWeeks(null);
            })
            .fail(function () {
                //console.log("error");
            })
            .always(function () {
                //console.log("complete");
            });

        $(document).mouseup(function (e: any) {
            var container = $(".multiselect_seminars_list.caspardo_tab_seminars");
            // if the target of the click isn't the container... nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
                // $('.caspardo_tab_seminars').hide();
            }
        });
    }

    /**
     * Sets the seminar serach button name.
     * @param hits  Number of appointment hits.
     */
    public static setSearchBtnName(hits: number) {
        jQuery("#caspardo_seminarfinder_btn, .caspardo_seminarfinder_btn_mobile").html(hits + " Seminartermine anzeigen");
        return false;
    }

    /**
    * generate the tabs for seminar filter V2.
    * @param tabList  List of tabs from configutarion.
    */
    public static generateTab(tabList: any) {
        let tabTemplateHTML: string = '';
        for (let item = 0; item < tabList.length; item++) {
            let tabNameRow: string = caspardoConfig.seminarfinder.html_seminar_tabs_templatev2;
            let tabSearchBox: string = caspardoConfig.seminarfinder.html_tab_search_box_rowv2;
            const tab = tabList[item];
            tabNameRow = tabNameRow.replace(new RegExp('{href}', 'g'), tab.href);
            tabNameRow = tabNameRow.replace(new RegExp('{id}', 'g'), tab.id);
            tabNameRow = tabNameRow.replace(new RegExp('{data_view}', 'g'), tab.data_view);
            tabNameRow = tabNameRow.replace(new RegExp('{name}', 'g'), tab.name);
            tabNameRow = tabNameRow.replace(new RegExp('{span_id}', 'g'), tab.span_id);
            tabTemplateHTML += tabNameRow;
            tabSearchBox = tabSearchBox.replace(new RegExp('{type}', 'g'), tab.type);
            tabSearchBox = tabSearchBox.replace(new RegExp('{placeholder}', 'g'), tab.placeholder);
            tabSearchBox = tabSearchBox.replace(new RegExp('{id}', 'g'), tab.id);
            if (tab.href == 'topseminars')
                $('#caspardo_seminar_search_box').html(tabSearchBox);
            if (tab.href == 'categories')
                $('#caspardo_categories_search_box').html(tabSearchBox);
            if (tab.href == 'functions')
                $('#caspardo_functions_search_box').html(tabSearchBox);
        }
        $('#caspardo_tab_seminarsfilter_head').html(tabTemplateHTML);

    }

    /**
    * Gets the frist and last date of a week.
    * @param weekNumber  weeknumber in year
    * @param year year
    * @returns   Returns JSONObject {firstRealDate:Date,firstWeekDate:Date,lastWeekDate:Date, value:string, calendarWeek:number, year:number}
    */
    public static getFirstLastDateOfWeek(weekNumber: number, year: number) {
        var res: any = {
            firstRealDate: null,
            firstWeekDate: null,
            lastWeekDate: null,
            value: null,
            calendarWeek: weekNumber,
            year: year
        }
        var begin = moment();
        begin.year(year).isoWeek(weekNumber).startOf('isoWeek');
        begin.locale("de");
        var end = moment();
        end.year(year).isoWeek(weekNumber).endOf('isoWeek');
        end.locale("de");
        res.firstRealDate = begin.toDate();
        res.firstWeekDate = begin.format("DD.MM.YYYY");
        res.lastWeekDate = end.format("DD.MM.YYYY");
        res.value = begin.format("DD.MM.YYYY") + "-" + end.format("DD.MM.YYYY");
        return res;
    }

    /**
    * Generates the seminar HTML-Select Element
    * @param seminar  CASPARDO Seminar Object
    */
    public static replaceSeminarRow(seminar: any) {
        let row: string = caspardoConfig.seminarfinder.html_seminar_select_rowv2;
        row = row.replace(new RegExp('{id}', 'g'), seminar.seminar_search_hash);
        row = row.replace(new RegExp('{topseminar}', 'g'), seminar.topseminar);
        row = row.replace(new RegExp('{name}', 'g'), seminar.name);
        return row;
    }

    /**
    * Generates the category HTML-Select Element
    * @param category  CASPARDO Category Object
    */
    public static replaceCategoryRow(category: any, catagory_subsection: any) {
        let row: string = caspardoConfig.seminarfinder.html_categories_select_rowv2;
        row = row.replace(new RegExp('{id}', 'g'), category.id);
        row = row.replace(new RegExp('{name}', 'g'), category.name);
        if (catagory_subsection.length) {
            let category_sub_section: string = '';
            for (var j = 0; j < catagory_subsection.length; j++) {
                category_sub_section += `<li><input data-checkbox-item="seminar_themes" 
                        type="checkbox" id="${catagory_subsection[j].id}" name="${catagory_subsection[j].name}" value="${catagory_subsection[j].id}"> 
                        <span>${catagory_subsection[j].name}</span></li>`;
            }
            row = row.replace(new RegExp('{display_subsection}', 'g'), 'show');
            row = row.replace(new RegExp('{category_subrubriken}', 'g'), category_sub_section);
        } else {
            row = row.replace(new RegExp('{display_subsection}', 'g'), 'hide');
        }
        return row;
    }

    /**
    * Generates the seminar HTML-Select Element
    * @param caspardoSeminarFinderJSON  CASPARDO Seminar Finder Data
    */
    public static generateSeminars(caspardoSeminarFinderJSON: any) {

        // Init the first option row
        let resHTML: string = caspardoConfig.seminarfinder.html_seminar_select_default;

        // Init the resultingSeminarHTMLs
        let resTopSeminarHTML: string = "";
        let resSeminarHTML: string = "";
        let resCategoriesHTML: string = "";
        let resFunctionsHTML: string = "";

        // Init the fillupline
        let fillUpLine: string = "";

        // Top Headings for the Top Seminare, All Seminare, Categories
        let nameGroupTopSeminar = fillUpLine + CaspardoSeminarFinderV2.HTMLElement_selectTopSeminar.html() + fillUpLine;
        let nameSeminare = fillUpLine + CaspardoSeminarFinderV2.HTMLElement_selectAllSeminar.html() + fillUpLine;
        let nameCategories = fillUpLine + CaspardoSeminarFinderV2.HTMLElement_selectCategories.html() + fillUpLine;
        let nameFunctions = fillUpLine + CaspardoSeminarFinderV2.HTMLElement_selectFunctions.html() + fillUpLine;

        // Build the Top-Seminares
        resTopSeminarHTML += nameGroupTopSeminar
        // Build the All-Seminares
        resSeminarHTML += nameSeminare
        // Build the Categories
        resCategoriesHTML += nameCategories
        // Build the Funtions
        resFunctionsHTML += nameFunctions

        // Sort by name
        if (caspardoSeminarFinderJSON.seminars[0]) {
            caspardoSeminarFinderJSON.seminars[0].sort(function (a, b) { return (a.name < b.name) ? -1 : 1 });
            // Loop through the seminars
            for (var i = 0; i < caspardoSeminarFinderJSON.seminars[0].length; i++)
                if (caspardoSeminarFinderJSON.seminars[0][i].name != "") {
                    if (caspardoSeminarFinderJSON.seminars[0][i].topseminar === "true")
                        resTopSeminarHTML += CaspardoSeminarFinderV2.replaceSeminarRow(caspardoSeminarFinderJSON.seminars[0][i]);
                    resSeminarHTML += CaspardoSeminarFinderV2.replaceSeminarRow(caspardoSeminarFinderJSON.seminars[0][i]);
                }
        }

        // Category seminars in tree view
        if (caspardoSeminarFinderJSON.categories[0]) {
            let catagoryTabData: any = caspardoConfig.seminarfinder.tabs
            for (var i = 0; i < caspardoSeminarFinderJSON.categories[0].length; i++) {
                let catagoryName = caspardoSeminarFinderJSON.categories[0][i].name;
                // For Categories, white_list = [] and black_list = ['some-item'],
                if (catagoryTabData[1].whitelist_category.length == 0 && catagoryTabData[1].blacklist_category.length != 0)
                    if (catagoryName != "" && !(catagoryTabData[1].blacklist_category.includes(catagoryName))) {
                        let subSectionData = caspardoSeminarFinderJSON.categories[0][i];
                        resCategoriesHTML += CaspardoSeminarFinderV2.replaceCategoryRow(subSectionData, subSectionData.subrubriken);
                    }
                // For Functions, white_list = ['some-item'] and black_list = [],
                if (catagoryTabData[2].whitelist_category.length != 0 && catagoryTabData[2].blacklist_category.length == 0)
                    if (catagoryName != "" && catagoryTabData[2].whitelist_category.includes(catagoryName)) {
                        let subSectionData = caspardoSeminarFinderJSON.categories[0][i];
                        resFunctionsHTML += CaspardoSeminarFinderV2.replaceCategoryRow(subSectionData, subSectionData.subrubriken);
                    }
            }
        }

        // Display tabs and results - for multiselect 
        CaspardoSeminarFinderV2.initAllEvents();
        CaspardoSeminarFinderV2.getTotalSelectedSeminars();

        // Insert the seminars into the html-elements
        CaspardoSeminarFinderV2.HTMLElement_selectTopSeminar.html(resTopSeminarHTML);
        CaspardoSeminarFinderV2.HTMLElement_selectAllSeminar.html(resSeminarHTML);
        CaspardoSeminarFinderV2.HTMLElement_selectCategories.html(resCategoriesHTML);
        CaspardoSeminarFinderV2.HTMLElement_selectFunctions.html(resFunctionsHTML);

        // Get all checked seminars and check all seminars and categories checked
        $('#seminar-selected-top-all input[type=checkbox]').click(function () {
            if (event.target['checked'] == true)
                $(this).parents('li').children('input[type=checkbox]').prop('checked', true);
            $(this).parent().find('input[type=checkbox]').prop('checked', event.target['checked']);
            CaspardoSeminarFinderV2.selected_seminars = [];
            // array total selected seminars
            $.each($("#seminar-selected-top-all input[name]:checked"), function () {
                CaspardoSeminarFinderV2.selected_seminars.push($(this).val());
            });
            let seminarArray = [];
            // remove duplicate seminars
            $.each(CaspardoSeminarFinderV2.selected_seminars, function (i, el) {
                if ($.inArray(el, seminarArray) === -1) seminarArray.push(el);
            });
            CaspardoSeminarFinderV2.selected_seminars = [];
            CaspardoSeminarFinderV2.selected_seminars = seminarArray;

        });

        // Get all checked seminars and check all seminars and categories checked
        $('#caspardo-seminarfinder-catagories-seminars input[type=checkbox]').click(function () {
            if (event.target['checked'] == true) {
                $(this).parents('li').children('input[type=checkbox]').prop('checked', true);
            }
            $(this).parent().find('input[type=checkbox]').prop('checked', event.target['checked']);
            CaspardoSeminarFinderV2.selected_categories = [];
            // array total selected categories
            $.each($("#caspardo-seminarfinder-catagories-seminars input[name]:checked"), function () {
                CaspardoSeminarFinderV2.selected_categories.push($(this).val());
            });
            let categoryArray = [];
            // remove duplicate categories
            $.each(CaspardoSeminarFinderV2.selected_categories, function (i, el) {
                if ($.inArray(el, categoryArray) === -1) categoryArray.push(el);
            });
            CaspardoSeminarFinderV2.selected_categories = [];
            CaspardoSeminarFinderV2.selected_categories = categoryArray;
        });

        // Get all checked functions
        $('#caspardo-seminarfinder-functions input[type=checkbox]').click(function () {
            if (event.target['checked'] == true) {
                $(this).parents('li').children('input[type=checkbox]').prop('checked', true);
            }
            $(this).parent().find('input[type=checkbox]').prop('checked', event.target['checked']);
            CaspardoSeminarFinderV2.selected_functions = [];
            // array total selected functions
            $.each($("#caspardo-seminarfinder-functions input[name]:checked"), function () {
                CaspardoSeminarFinderV2.selected_functions.push($(this).val());
            });
            let functionsArray = [];
            // remove duplicate functions
            $.each(CaspardoSeminarFinderV2.selected_functions, function (i, el) {
                if ($.inArray(el, functionsArray) === -1) functionsArray.push(el);
            });
            CaspardoSeminarFinderV2.selected_functions = [];
            CaspardoSeminarFinderV2.selected_functions = functionsArray;
        });

        // Reset for all-seminars
        $('#seminarfilter_topseminars_reset').click(function () {
            CaspardoSeminarFinderV2.counterTopAll = 0;
            CaspardoSeminarFinderV2.selected_seminars = [];
            $('#seminarfilter_topseminars_reset').hide();
            $('#caspardo-top-seminar-selected').html(`${CaspardoSeminarFinderV2.counterTopAll}`);
            $('#seminar-selected-top-all').find('input:checkbox').prop('checked', false);
            CaspardoSeminarFinderV2.getTotalSelectedSeminars();
        });

        // Reset for catagories
        $('#seminarfilter_catagories_reset').click(function () {
            CaspardoSeminarFinderV2.counterCategories = 0;
            CaspardoSeminarFinderV2.selected_categories = [];
            $('#seminarfilter_catagories_reset').hide();
            $('#caspardo-categories-selected').html(`${CaspardoSeminarFinderV2.counterCategories}`);
            $('#caspardo-seminarfinder-catagories-seminars').find('input:checkbox').prop('checked', false);
            CaspardoSeminarFinderV2.getTotalSelectedSeminars();
        });

        // Reset for functions
        $('#seminarfilter_functions_reset').click(function () {
            CaspardoSeminarFinderV2.counterfunctions = 0;
            CaspardoSeminarFinderV2.selected_functions = [];
            $('#seminarfilter_functions_reset').hide();
            $('#caspardo-functions-selected').html(`${CaspardoSeminarFinderV2.counterfunctions}`);
            $('#caspardo-seminarfinder-functions').find('input:checkbox').prop('checked', false);
            CaspardoSeminarFinderV2.getTotalSelectedSeminars();
        });

        // Register the events for the HTML-Top-Seminar 
        CaspardoSeminarFinderV2.HTMLElement_selectTopSeminar.on('change', function (event) {
            // Set the global tabType
            CaspardoSeminarFinderV2.tabType = "top-seminar";
            // Assign the event-html-element
            var element = $(this);

            // Condition for the number of top seminar selected in tab name
            (event.target['checked'] == true) ? CaspardoSeminarFinderV2.counterTopAll++ : CaspardoSeminarFinderV2.counterTopAll--;
            (CaspardoSeminarFinderV2.counterTopAll >= 0) ? $('#caspardo-top-seminar-selected').html(`${CaspardoSeminarFinderV2.counterTopAll}`) : '';
            (CaspardoSeminarFinderV2.counterTopAll == 0) ? $('#seminarfilter_topseminars_reset').hide() : $('#seminarfilter_topseminars_reset').show();
            CaspardoSeminarFinderQueryV2.seminarsList['seminar'] = CaspardoSeminarFinderV2.selected_seminars;

            // Get the data-component-id + execute the search-result-refesh
            var data_component_id = element.attr("data-component-id").valueOf();
            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);
            CaspardoSeminarFinderV2.search("seminartheme", finderQuery);
            CaspardoSeminarFinderV2.getTotalSelectedSeminars();
        });

        // Register the events for the HTML-All-Seminar 
        CaspardoSeminarFinderV2.HTMLElement_selectAllSeminar.on('change', function (event) {
            // Set the global tabType
            CaspardoSeminarFinderV2.tabType = "all-seminar";

            // Assign the event-html-element
            var element = $(this);
            // Condition for the number of top seminar selected in tab name
            (event.target['checked'] == true) ? CaspardoSeminarFinderV2.counterTopAll++ : CaspardoSeminarFinderV2.counterTopAll--;
            (CaspardoSeminarFinderV2.counterTopAll >= 0) ? $('#caspardo-top-seminar-selected').html(`${CaspardoSeminarFinderV2.counterTopAll}`) : '';
            (CaspardoSeminarFinderV2.counterTopAll == 0) ? $('#seminarfilter_topseminars_reset').hide() : $('#seminarfilter_topseminars_reset').show();
            CaspardoSeminarFinderQueryV2.seminarsList['seminar'] = CaspardoSeminarFinderV2.selected_seminars;

            // Get the data-component-id + execute the search-result-refesh
            var data_component_id = element.attr("data-component-id").valueOf();
            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);
            CaspardoSeminarFinderV2.search("seminartheme", finderQuery);
            CaspardoSeminarFinderV2.getTotalSelectedSeminars();
        });

        // Register the events for the HTML-Category-Seminar 
        CaspardoSeminarFinderV2.HTMLElement_selectCategories.on('change', function () {
            // Set the global tabType
            CaspardoSeminarFinderV2.tabType = "categories";

            // Assign the event-html-element
            var element = $(this);
            let catagoriesArray = [];
            $.each($("#caspardo-seminarfinder-catagories-seminars input[name]:checked"), function () {
                catagoriesArray.push($(this).val());
            });

            (event.target['checked'] == true) ? CaspardoSeminarFinderV2.counterCategories++ : CaspardoSeminarFinderV2.counterCategories--;
            if (CaspardoSeminarFinderV2.counterCategories >= 0) {
                CaspardoSeminarFinderV2.counterCategories = catagoriesArray.length;
                $('#caspardo-categories-selected').html(`${CaspardoSeminarFinderV2.counterCategories}`);
            }
            (CaspardoSeminarFinderV2.counterCategories == 0) ? $('#seminarfilter_catagories_reset').hide() : $('#seminarfilter_catagories_reset').show();
            CaspardoSeminarFinderQueryV2.seminarsList['category'] = CaspardoSeminarFinderV2.selected_categories;

            // Get the data-component-id + execute the search-result-refesh
            var data_component_id = element.attr("data-component-id").valueOf();
            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);
            CaspardoSeminarFinderV2.search("seminartheme", finderQuery);
            CaspardoSeminarFinderV2.getTotalSelectedSeminars();
        });

        // Register the events for the HTML-funtions-seminars, 
        CaspardoSeminarFinderV2.HTMLElement_selectFunctions.on('change', function () {
            // Set the global tabType
            CaspardoSeminarFinderV2.tabType = "categories";

            // Assign the event-html-element
            var element = $(this);
            let functionsArray = [];
            $.each($("#caspardo-seminarfinder-functions input[name]:checked"), function () {
                functionsArray.push($(this).val());
            });

            (event.target['checked'] == true) ? CaspardoSeminarFinderV2.counterfunctions++ : CaspardoSeminarFinderV2.counterfunctions--;
            if (CaspardoSeminarFinderV2.counterfunctions >= 0) {
                CaspardoSeminarFinderV2.counterfunctions = functionsArray.length;
                $('#caspardo-functions-selected').html(`${CaspardoSeminarFinderV2.counterfunctions}`);
            }
            (CaspardoSeminarFinderV2.counterfunctions == 0) ? $('#seminarfilter_functions_reset').hide() : $('#seminarfilter_functions_reset').show();
            CaspardoSeminarFinderQueryV2.seminarsList['functions'] = CaspardoSeminarFinderV2.selected_functions;

            // Get the data-component-id + execute the search-result-refesh
            var data_component_id = element.attr("data-component-id").valueOf();
            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);
            CaspardoSeminarFinderV2.search("seminartheme", finderQuery);
            CaspardoSeminarFinderV2.getTotalSelectedSeminars();
        });
    }

    /**
    * Gte total number of item selected in the list of seminars, category, and functions:
    * CASPARDO Seminar Filter V2
    */
    public static getTotalSelectedSeminars() {
        let total_seminars = CaspardoSeminarFinderV2.counterTopAll;
        let total_category = CaspardoSeminarFinderV2.counterCategories;
        let total_functions = CaspardoSeminarFinderV2.counterfunctions;
        // Get sum of total selected item in list
        CaspardoSeminarFinderV2.totalSelectedItems = total_seminars + total_category + total_functions;
        //  if total selected item is zero
        if (CaspardoSeminarFinderV2.totalSelectedItems == 0)
            $('.selected_options_arrow, #caspardo_seminar_finder_v2_selected_options').hide();
        else {
            $('#caspardo_seminar_finder_v2_selected_options').html(`${CaspardoSeminarFinderV2.totalSelectedItems}`);
            $('.selected_options_arrow, #caspardo_seminar_finder_v2_selected_options').show();
        }
    }

    /**
    * Generates the HTML-Selected Tab
    * CASPARDO Seminar Filter V2
    */
    public static initAllEvents() {
        $('.multiselect_seminars_list').hide();
        $('.caspardo-tab-item-selected').hide();
        $('#topseminars').hide();
        $('#functions').hide();
        $('#categories').hide();
        $('#seminarfilter_topseminars_reset').hide();
        $('#seminarfilter_catagories_reset').hide();
        $('#seminarfilter_functions_reset').hide();

        // click on show seminar then display
        $('#caspardo-seminarfilter-v2-button').on('click', function () {
            $('.caspardo-tab-item-selected').toggle();
            $('#topseminars').toggle();
        });

        // on click ok button then close tabs 
        $('.seminarfilter-ok-button').on('click', function () {
            $('#caspardo-seminarfilter-v2-button').click();
        });

        // click and mouseover event for top seminar tab,
        $('#caspardo_tab_top_seminars').on('mouseover click', function () {
            $('#functions').hide();
            $('#categories').hide();
            $('#topseminars').show();
        });
        // click and mouseover event for all seminar tab,
        $('#caspardo_tab_functions').on('mouseover click', function () {
            $('#functions').show();
            $('#topseminars').hide();
            $('#categories').hide();
        });
        // click and mouseover event for categories tab,
        $('#caspardo_tab_categories').on('mouseover click', function () {
            $('#categories').show();
            $('#functions').hide();
            $('#topseminars').hide();
        });

        //  Set scroll panel height dynamically
        let scrollPanelHeight = caspardoConfig.seminarfinder.scrollpanelheight;
        $('.caspardo-scroll-panel-height').css({
            "overflow-y": "scroll",
            "height": `${scrollPanelHeight}px`
        });

    }

    /**
    * Generates the HTML-City-Select Element
    * @param caspardoSeminarCityJSONArr  CASPARDO Seminar Finder Array ("city1","city2",...)
    */
    public static generateCities(caspardoSeminarCityJSONArr: any) {
        // Init the first option row
        let resHTML: string = caspardoConfig.seminarfinder.html_city_select_header;
        // Sort by name
        caspardoSeminarCityJSONArr.sort(function (a, b) { return a < b ? -1 : 1 });
        // Loop thorgh the cities
        for (var i = 0; i < caspardoSeminarCityJSONArr.length; i++) {
            // Get the option html row
            let row: string = caspardoConfig.seminarfinder.html_city_select_row;
            // Replace the values
            resHTML += row.replace(new RegExp('{name}', 'g'), caspardoSeminarCityJSONArr[i]);
        }
        // Set the html to the div id=caspardo-seminarfinder-cities
        CaspardoSeminarFinderV2.HTMLElement_selectCity.html(resHTML);
        // Init the events
        CaspardoSeminarFinderV2.HTMLElement_selectCity.on('change', function () {
            var element = $(this);
            var data_component_id = element.attr("data-component-id").valueOf();

            CaspardoSeminarFinderV2.resetSeminarCity();

            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);
            CaspardoSeminarFinderV2.search("city", finderQuery);
        });
    }


    /**
    * Generates the calendar weeks HTML-Select Element
    * @param caspardoSeminarFinderJSON  CASPARDO Seminar Finder Data
    */
    public static generateCalendarWeeks(jsonArrCalendarWeeks: any) {
        // Init with today
        var startDateSeminare = new Date();
        var weeknumber = moment(startDateSeminare).isoWeek();
        var weekcount = moment().isoWeeksInYear();
        var year = moment().year();
        // Init the first option
        let resHTML: string = caspardoConfig.seminarfinder.html_weeknumber_select_header;
        if (jsonArrCalendarWeeks === null) {
            // Generate the default calendar weeks
            for (var i = weeknumber; i <= weekcount; i++) {
                let row: string = caspardoConfig.seminarfinder.html_weeknumber_select_row;
                resHTML += row.replace(new RegExp('\{name\}', 'g'), i + "-" + year);
            }
        } else {
            for (var z = 0; z < jsonArrCalendarWeeks.length; z++) {
                let row: string = caspardoConfig.seminarfinder.html_weeknumber_select_row;
                resHTML += row.replace(new RegExp('\{name\}', 'g'), jsonArrCalendarWeeks[z]);
            }
        }
        // Set the html to the id=caspardo-seminarfinder-kws 
        CaspardoSeminarFinderV2.HTMLElement_selectKWs.html(resHTML);
        // Append the events
        CaspardoSeminarFinderV2.HTMLElement_selectKWs.on('change', function () {

            var element = $(this);
            var data_component_id = element.attr("data-component-id").valueOf();

            let year_kw: string = (<any>this).value;

            let finderQuery = new CaspardoSeminarFinderQueryV2(data_component_id);

            if (year_kw != "") {
                let calVals: any = year_kw.split("-");
                var res = CaspardoSeminarFinderV2.getFirstLastDateOfWeek(calVals[0], calVals[1]);
                finderQuery.date_from = res.firstWeekDate;
                finderQuery.date_to = res.lastWeekDate;
                CaspardoSeminarFinderV2.refreshFilter = false;
                // (<any>CaspardoSeminarFinderV2.HTMLElement_dateFrom).val(res.firstWeekDate).datepicker("update");
                // (<any>CaspardoSeminarFinderV2.HTMLElement_dateTo).val(res.lastWeekDate).datepicker("update");

                (<any>CaspardoSeminarFinderV2.HTMLElement_dateFrom).val(res.firstWeekDate);
                (<any>CaspardoSeminarFinderV2.HTMLElement_dateTo).val(res.lastWeekDate);


            }
            else {
                (<any>CaspardoSeminarFinderV2.HTMLElement_dateFrom).val("");//.datepicker("update");
                (<any>CaspardoSeminarFinderV2.HTMLElement_dateTo).val("");//.datepicker("update");
                finderQuery.date_from = "";
                finderQuery.date_to = "";
            }
            CaspardoSeminarFinderV2.refreshFilter = true;
            CaspardoSeminarFinderV2.search("cws", finderQuery);

        });
    }

    public static resetSeminarCity() {
        // (<any>CaspardoSeminarFinderV2.HTMLElement_dateTo.val("")).datepicker("update");
        // (<any>CaspardoSeminarFinderV2.HTMLElement_dateFrom.val("")).datepicker("update");
        // CaspardoSeminarFinderV2.HTMLElement_selectKWs.val("");
    }


    public static resetSeminarTheme() {
        // (<any>CaspardoSeminarFinderV2.HTMLElement_dateTo.val("")).datepicker("update");
        // (<any>CaspardoSeminarFinderV2.HTMLElement_dateFrom.val("")).datepicker("update");
        // CaspardoSeminarFinderV2.HTMLElement_selectCity.val("");
        // CaspardoSeminarFinderV2.HTMLElement_selectCity.val("");
        // CaspardoSeminarFinderV2.HTMLElement_selectKWs.val("");
    }

    /**
     * Search for appointments.
     * @param type  "seminartheme" | "city" defines the search type
     */
    public static search(type: string, finderQuery: CaspardoSeminarFinderQueryV2) {

        let seminartitlehash: Array<1> = finderQuery.seminartitlehash;
        let city: string = finderQuery.city;
        let date_from: string = finderQuery.date_from;
        let date_to: string = finderQuery.date_to;

        let filterquery: string = "";
        if (!date_from)
            date_from = "";
        if (!date_to)
            date_to = "";
        if (!city)
            city = "";
        if (!seminartitlehash)
            seminartitlehash = [];
        let addDate: boolean = (date_from != "") && (date_to != "");
        if (addDate)
            filterquery += "&to=" + date_to + "&from=" + date_from;
        else if (date_from != "")
            filterquery += "&from=" + date_from + "&to=31.12.2025";

        if (seminartitlehash != []) {
            CaspardoSeminarFinderV2.allseminarhash = "";

            let listSeminars = CaspardoSeminarFinderQueryV2.seminarsList;
            let data = Object.keys(listSeminars);
            for (let index = 0; index < data.length; index++) {
                if (data[index] == 'category' || data[index] == 'functions') {
                    for (let category = 0; category <= listSeminars[data[index]].length; category++) {
                        if (listSeminars[data[index]][category] != undefined) {
                            CaspardoSeminarFinderV2.allseminarhash += "&category=" + listSeminars[data[index]][category];
                        }
                    }
                }

                if (data[index] == 'seminar') {
                    for (let seminar = 0; seminar <= listSeminars[data[index]].length; seminar++) {
                        if (listSeminars[data[index]][seminar] != undefined) {
                            CaspardoSeminarFinderV2.allseminarhash += "&seminartitlehash=" + listSeminars[data[index]][seminar];
                        }
                    }
                }

            }
            filterquery += CaspardoSeminarFinderV2.allseminarhash;
        }
        if (city != "")
            filterquery += "&city=" + encodeURI(city);
        if (filterquery === "") {

            CaspardoSeminarFinderV2.setSearchBtnName(CaspardoSeminarFinderV2.seminardata.seminar_appointment_count);
        }
        else {
            let apiURL: string = CaspardoSeminarFinderV2.config.searchengine_url + "/servlet/findIt?query=&tab=seminare&type=seminar&searchengineid=" + caspardoConfig.searchengine_id + "&output=json&pagetype=2&page=1&language=" + CaspardoAPI.defaultLanguage + filterquery;
            console.log(apiURL);
            var jqxhr = jQuery.getJSON(apiURL, function () {
                //console.log("success");
            })
                .done(function (data) {
                    let counter = data.searchresult.header.hits;
                    CaspardoSeminarFinderV2.setSearchBtnName(counter);
                    let doIt: boolean = false;
                    if ((type === "seminartheme") && (doIt)) {
                        for (var i = 0; i < data.searchresult.header.filters.filter.length; i++) {
                            let filter_name: string = data.searchresult.header.filters.filter[i].name;
                            // City Filter
                            if (filter_name === "city") {
                                var cityArr = new Array();
                                if (data.searchresult.header.hits > 0) {
                                    if (!(data.searchresult.header.filters.filter[i].value instanceof Array)) {
                                        var tempArray = [];
                                        tempArray.push(data.searchresult.header.filters.filter[i].value);
                                        data.searchresult.header.filters.filter[i].value = tempArray;
                                    }
                                    if (data.searchresult.header.filters.filter[i].value.length) {
                                        for (var j = 0; j < data.searchresult.header.filters.filter[i].value.length; j++) {
                                            if (data.searchresult.header.filters.filter[i].value[j].content) {
                                                let city_name: string = data.searchresult.header.filters.filter[i].value[j].content;
                                                console.log(city_name);
                                                var length = cityArr.push(city_name);
                                            }
                                        }
                                    }
                                }
                                CaspardoSeminarFinderV2.generateCities(cityArr);
                            }
                            // Weeknumber Filter
                            if (filter_name === "weeknumbers") {
                                var weeknumberArr = new Array();
                                if (data.searchresult.header.hits > 0) {
                                    if (!(data.searchresult.header.filters.filter[i].value instanceof Array)) {
                                        var tempArray = [];
                                        tempArray.push(data.searchresult.header.filters.filter[i].value);
                                        data.searchresult.header.filters.filter[i].value = tempArray;
                                    }
                                    if (data.searchresult.header.filters.filter[i].value.length) {
                                        for (var j = 0; j < data.searchresult.header.filters.filter[i].value.length; j++) {
                                            let weeknumber: string = data.searchresult.header.filters.filter[i].value[j].content;
                                            console.log("weeknumber:" + weeknumber);
                                            var length = weeknumberArr.push(weeknumber);
                                        }
                                    }
                                }
                                CaspardoSeminarFinderV2.generateCalendarWeeks(weeknumberArr);
                            }
                        }
                        //resHTML += row.replace(new RegExp('\{name\}', 'g'), i + "-" + year);
                    }
                    if ((type === "city") && (doIt)) {
                        for (var i = 0; i < data.searchresult.header.filters.filter.length; i++) {
                            let filter_name: string = data.searchresult.header.filters.filter[i].name;
                            // Weeknumber Filter
                            if (filter_name === "weeknumbers") {
                                var weeknumberArr = new Array();
                                if (data.searchresult.header.hits > 0) {
                                    if (!(data.searchresult.header.filters.filter[i].value instanceof Array)) {
                                        var tempArray = [];
                                        tempArray.push(data.searchresult.header.filters.filter[i].value);
                                        data.searchresult.header.filters.filter[i].value = tempArray;
                                    }
                                    if (data.searchresult.header.filters.filter[i].value.length) {
                                        for (var j = 0; j < data.searchresult.header.filters.filter[i].value.length; j++) {
                                            let weeknumber: string = data.searchresult.header.filters.filter[i].value[j].content;
                                            console.log("weeknumber:" + weeknumber);
                                            var length = weeknumberArr.push(weeknumber);
                                        }
                                    }
                                }
                                CaspardoSeminarFinderV2.generateCalendarWeeks(weeknumberArr);
                            }
                        }
                    }
                })
                .fail(function () {
                    //console.log("error");
                })
                .always(function () {
                    //console.log("complete");
                });
        }
        return false;
    }
}