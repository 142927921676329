import { CaspardoAPI } from "../common/CaspardoAPI";
import { CaspardoSelectedFilter } from "../template/CaspardoSelectedFilter";
import { CaspardoTemplate } from "../template/CaspardoTemplate";
import { CaspardoLogger } from "../common/CaspardoLogger";
import { Logger } from "../common/Logger"
import ClickEvent = JQuery.ClickEvent;
import MouseUpEvent = JQuery.MouseUpEvent;
var configurations = require('../caspardoconfig.json');
import { IN18 } from "../IN18Translation";

export class CaspardoAutocomplete {
    timeout = null;

    searchInputEl = jQuery("#caspardo_searchfield_new");

    public static resultsAreOpen = false;
    public static keyNavSelectedRow = null;
    /**
     * Layout names:
     * Set it in the configuration file - caspardo.json
     * For IFB it will be - www_ifb_de
     * For Nabtesco it will be - www_nabtesco_de
     * */
    public static searchengineType = configurations.searchengine_type;


    /**
    * Constructor Autocomplete
    */
    constructor() {
    }

    /**
    * doDelayedSearch
    * @param keyCode  Character for the event (Char 13)
    */
    public doDelayedSearch(keyCode: number) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(function () {
            CaspardoAutocomplete.executeAutocomplete(keyCode); //this is your existing function
        }, CaspardoAPI.config.searchslot.typing_delay);
    }

    /**
    * Inits the events.
    */
    public initEvent() {
        const self = this;
        const searchSlotEl = document.querySelector('#caspardo_searchslot');
        const searchInputEl = searchSlotEl.querySelector('#caspardo_searchfield_new');


        $("#caspardo_searchfield_new_1").focus(function () {
            if ($("#caspardo_searchfield_new").val() !== "") {
                $("#caspardo_suggestions_heading_arrow").css("display", "block");
            }
        });

        $("#caspardo_searchfield_new_1").focusout(function () {
            $("#caspardo_suggestions_heading_arrow").css("display", "none");
        });

        if (CaspardoAutocomplete.searchengineType === "seminar") {

            $("#autocomplete_dropdown_button").removeClass('hide');
            // jQuery('#caspardo_searchfield_new').keyup((evt) => {
            //     this.doDelayedSearch(evt.keyCode);
            // });
            // jQuery("#caspardo_suggestions").hide();
            // jQuery("#caspardo_searchfield_new").blur(function () {
            //     setTimeout(function () {
            //         jQuery("#caspardo_suggestions").hide();
            //     }, 250);
            // });
            CaspardoAutocomplete.getInitEventListners(self, searchSlotEl, searchInputEl);
            this.searchInputEl.on('focus', CaspardoAutocomplete.showSearchResults);
        }
        if (CaspardoAutocomplete.searchengineType === "shop") {

            $("#autocomplete_dropdown_button").addClass('hide');
            // Handles search
            CaspardoAutocomplete.getInitEventListners(self, searchSlotEl, searchInputEl);
            // Show results box on focus
            this.searchInputEl.on('focus', CaspardoAutocomplete.showSearchResults);
        }

        // $(function () {
        //     $("#caspardo_searchfield_new").click(function (e) {
        //         $(this).parents('.cstm-keystone-search').addClass('expand');
        //         e.stopPropagation()
        //     });
        //     $(document).click(function (e) {
        //         if ($(e.target).is(".cstm-keystone-search") === false) {
        //             $(".cstm-keystone-search").removeClass("expand");
        //         }
        //     });
        // });

        if (configurations.layout === "www_caspardo_io") {
            CaspardoTemplate.registerEvents();
        }

        // $(".ctm-close-icon").click(function (evt) {
        //     console.log('value: ', value);
        // });

        // let value = jQuery("#caspardo_searchfield_new").val();
        // if (value === "") {
        //     console.log('value:-"" ', value);
        //     $(".ctm-close-icon").hide();
        // } else {
        //     console.log('value:-"some" ', value);
        //     $(".ctm-close-icon").show();
        // }

    }

    public static getInitEventListners(self, searchSlotEl, searchInputEl) {

        window.addEventListener('input', function (e: KeyboardEvent) {
            const inputEl = <HTMLInputElement>e.target;

            if (<HTMLInputElement>e.target !== searchInputEl) return;

            // Do search
            if (inputEl.value.length) {
                CaspardoAutocomplete.setLoadingStatus(true);
                clearTimeout(self.timeout);
                self.timeout = setTimeout(CaspardoAutocomplete.executeAutocomplete, CaspardoAPI.config.searchslot.typing_delay);

                // Search field is empty
            } else {
                clearTimeout(self.timeout);
                CaspardoAutocomplete.setLoadingStatus(false);
                CaspardoAutocomplete.hideSearchResults();
                jQuery("#caspardo_suggestions").html('');
            }
        });

        // Handles keyboard navigation
        window.addEventListener('keydown', function (e: KeyboardEvent) {
            if (<HTMLInputElement>e.target !== searchInputEl) return;

            if (CaspardoAutocomplete.resultsAreOpen && e.key === 'Escape') {
                CaspardoAutocomplete.hideSearchResults();

            } else if (CaspardoAutocomplete.resultsAreOpen && e.target === self.searchInputEl[0]) {
                if (CaspardoAutocomplete.keyNavSelectedRow && e.key === 'Enter') {

                    e.preventDefault();
                    CaspardoAutocomplete.keyNavCommit();

                } else if (e.key === 'ArrowDown') {
                    CaspardoAutocomplete.keyNavGo(1)
                    e.preventDefault();

                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    CaspardoAutocomplete.keyNavGo(-1);

                }
            }
        })

        // Hide results box when click outside of slot
        document.addEventListener('click', CaspardoAutocomplete.hideSearchResults)
        searchSlotEl.addEventListener('click', function (evt) {
            // evt.preventDefault();
            // evt.stopPropagation();
            $('#caspardo_suggestions').css('display', 'block');
        }, true)

        // Select results on hover
        searchSlotEl.addEventListener('mouseover', function (e) {
            const target = e.target as Element
            const resultRow = target.closest('.search-result-row');
            if (resultRow) {
                CaspardoAutocomplete.keyNavUnselect();
                CaspardoAutocomplete.keyNavSelectRowEl(resultRow);
            }
        })
    }

    public static setStatusClass(className: string, deleteClass?) {
        const slotEl = document.querySelector('#caspardo_searchslot');
        if (deleteClass) {
            slotEl.classList.remove(className);
        } else {
            slotEl.classList.add(className);
        }
    }
    public static showSearchResults() {
        CaspardoAutocomplete.setStatusClass('caspardo-search__row--results-visible');
        CaspardoAutocomplete.resultsAreOpen = true;
    }
    public static hideSearchResults() {
        CaspardoAutocomplete.setStatusClass('caspardo-search__row--results-visible', true);
        CaspardoAutocomplete.resultsAreOpen = false;
        CaspardoAutocomplete.keyNavUnselect();
    }

    // Go!
    public static keyNavCommit() {
        const current = <HTMLAnchorElement>document.querySelector('.search-result-row.selected');
        if (current && current.hasAttribute('href')) {
            current.click();
        }
    }

    // Navigate up or down. addIndedx can be a negative or positive number.
    public static keyNavGo(addIndex) {
        const results = Array.prototype.slice.call(document.querySelectorAll('.search-result-row'));
        const currSelEl = CaspardoAutocomplete.keyNavSelectedRow;

        let newEl;
        if (!currSelEl) {
            newEl = addIndex > 0 ? results[0] : results[results.length - 1];
        } else {
            newEl = CaspardoAutocomplete.rotateIndex(results, currSelEl, addIndex);
        }

        CaspardoAutocomplete.keyNavUnselect();
        if (newEl) CaspardoAutocomplete.keyNavSelectRowEl(newEl);
    }

    public static rotateIndex(arr, el: Element, indexAdd: Number) {
        const currI = arr.indexOf(el);
        if (currI === -1) return false;

        let newI = currI + indexAdd;

        // Unselect if outside range
        if (newI <= 0 || newI >= arr.length) {
            return false;
        }

        return arr[newI];
    }

    public static keyNavUnselect() {
        const current = document.querySelector('.search-result-row.selected');
        if (current) current.classList.remove('selected');
        CaspardoAutocomplete.keyNavSelectedRow = false;
    }

    public static keyNavSelectRowEl(rowEl: Element) {
        rowEl.classList.add('selected');
        CaspardoAutocomplete.keyNavSelectedRow = rowEl;
    }

    public static setLoadingStatus(isLoading: boolean) {
        const slotEl = document.querySelector('#caspardo_searchslot');
        if (!isLoading) {
            slotEl.classList.remove('caspardo-search__row--is-loading');
        } else {
            slotEl.classList.add('caspardo-search__row--is-loading');
        }
    }

    public static executeAutocomplete(keyCode: number) {
        let query: string;
        if (CaspardoAutocomplete.searchengineType === "seminar") {
            if (keyCode === 13) {
                query = <string>jQuery("#caspardo_searchfield_new").val();

                let isWebcode: boolean = false;
                let indexOfC: number = query.toLowerCase().indexOf("c");
                if (indexOfC > 0) {
                    isWebcode = (query.length === (indexOfC + 1));
                }

                let target: string = "";
                let tab: string = CaspardoSelectedFilter.search_tab_type;
                target = CaspardoTemplate.config.searchengine_url_target + "?query=" + encodeURI(query) + "&tab=" + tab;
                // wecode!

                if (isWebcode)
                    target = target = CaspardoTemplate.config.searchengine_url_target + "?query=" + "&tab=seminar&webcode=" + query;

                window.location.assign(target);
            } else {
                query = <string>jQuery("#caspardo_searchfield_new").val();
                if (query) {
                    if (query.match(/\d+/g) != null && configurations.autocomplete_numbersToRomanNumerals) {
                        let listNum = query.split(" ");
                        if (listNum.length > 1) {
                            for (let item = 0; item < listNum.length; item++) {
                                if (!listNum[item].match(/[a-z]/i)) {
                                    query = query.replace(listNum[item].toString(), CaspardoAutocomplete.toRomanNumbers(listNum[item]))
                                }
                            }
                        }
                        jQuery("#caspardo_searchfield_new").val(query);
                    }
                    CaspardoAutocomplete.getSuggestions(query);
                }
            }
        }
        if (CaspardoAutocomplete.searchengineType === "shop") {
            query = <string>jQuery("#caspardo_searchfield_new").val();
            if (keyCode === 13) {
                if (query.match(/\d+/g) != null && configurations.autocomplete_numbersToRomanNumerals) {
                    let listNum = query.split(" ");
                    if (listNum.length > 1) {
                        for (let item = 0; item < listNum.length; item++) {
                            if (!listNum[item].match(/[a-z]/i)) {
                                query = query.replace(listNum[item].toString(), CaspardoAutocomplete.toRomanNumbers(listNum[item]))
                            }
                        }
                    }
                    jQuery("#caspardo_searchfield_new").val(query);

                }
                let target: string = "";
                let tab: string = CaspardoSelectedFilter.search_tab_type;
                target = CaspardoTemplate.config.searchengine_url_target + "?query=" + encodeURI(query) + "&tab=" + tab;
                window.location.assign(target);
            } else {
                CaspardoAutocomplete.getSuggestions(query);
            }

            CaspardoAutocomplete.setLoadingStatus(false);
        }
    }

    public static getSuggestions(query: string) {
     
        let api_call: string = CaspardoAPI.config.searchengine_url + "/servlet/GetSuggestions?query=" + encodeURI(query) + "&searchengineid=" + CaspardoAPI.api_sid + "&output=json&language=" + CaspardoAPI.defaultLanguage;
        var jqxhr = jQuery.getJSON(api_call, function (data) {
            let datasourceCounter:number=0;
            
            let autocomplete_res_html_order = CaspardoAPI.config.autocomplete_data;
            let html_array = [];

            let autoCompleteContentType = CaspardoSelectedFilter.search_tab_autocomplete_contenttype;
            var displaySuggestionDefault = configurations.autocomplete_default_items ? configurations.autocomplete_default_items : 5;
            var displaySuggestedProducts = configurations.autocomplete_products ? configurations.autocomplete_products : 5;

            // Keyword Section
            if (data.autocomplete.keywords) {
                if (data.autocomplete.keywords.keyworditem) {
                    // if (data.autocomplete.keywords.hits>0)
                    // {
                    if (!(data.autocomplete.keywords.keyworditem instanceof Array)) {
                        var tempArray = [];
                        tempArray.push(data.autocomplete.keywords.keyworditem);
                        data.autocomplete.keywords.keyworditem = tempArray;
                    }
                  
                    let keywordRow = CaspardoAPI.config.searchslot.html_suggestion_keyword_row;
                    let html_data: string = '';
                  
                    html_data += CaspardoAPI.config.searchslot.html_suggestion_keyword_head;
                    html_data = html_data.replace(new RegExp('{name}', 'g'), IN18.IN18TranslateNameByKey("caspardo-suggestions-head-keywords"));


                    let count = 1;
                    for (let keyword of data.autocomplete.keywords.keyworditem) {
                        if (count <= displaySuggestionDefault) {
                            // CaspardoLogger.log(keyword.keyword + " " + keyword.keywordhighlight); // 1, "string", false
                            const uri = CaspardoTemplate.config.searchengine_url_target + "?query=" + encodeURI(keyword.queryword)+"&lang="+CaspardoAPI.defaultLanguage;
                            let insertRow = keywordRow.replace(new RegExp('{url}', 'g'), uri);
                            insertRow = insertRow.replace(new RegExp('{text}', 'g'), keyword.highlighting);
                           
                            html_data += insertRow;
                            count++;
                        } else {
                            break
                        }
                    }
                    if (html_data!="")
                        datasourceCounter++;
                    html_array[autocomplete_res_html_order.indexOf('keywords')] = html_data;
               // }
              }
            }




            // Seminar Keyword Section
            if (autoCompleteContentType == 'all' || autoCompleteContentType === 'seminar' || autoCompleteContentType === 'content') {
                if (data.autocomplete.seminarkeywords.keywords && data.autocomplete.seminarkeywords.keywords.hits) {
                    if (!(data.autocomplete.seminarkeywords.keywords.item instanceof Array)) {
                        var tempArray = [];
                        tempArray.push(data.autocomplete.seminarkeywords.keywords.item);
                        data.autocomplete.seminarkeywords.keywords.item = tempArray;
                    }
                    let keywordRow = CaspardoAPI.config.searchslot.html_suggestion_keyword_row;
                    let html_data: string = '';
                     html_data += CaspardoAPI.config.searchslot.html_suggestion_keyword_head;
                    let count = 1;
                    for (let keyword of data.autocomplete.seminarkeywords.keywords.item) {
                        if (count <= displaySuggestionDefault) {
                            // CaspardoLogger.log(keyword.keyword + " " + keyword.keywordhighlight); // 1, "string", false
                            const uri = CaspardoTemplate.config.searchengine_url_target + "?query=" + encodeURI(keyword.keyword)+"&lang="+CaspardoAPI.defaultLanguage;
                            let insertRow = keywordRow.replace(new RegExp('{url}', 'g'), uri);
                            insertRow = insertRow.replace(new RegExp('{text}', 'g'), keyword.keywordhighlight);
                            html_data += insertRow;
                            count++;
                        } else {
                            break
                        }
                    }
                    if (html_data!="")
                        datasourceCounter++;
                    html_array[autocomplete_res_html_order.indexOf('seminarkeywords')] = html_data;
                }
            }

            // Seminar Section
            if ((autoCompleteContentType == 'seminar' || autoCompleteContentType == 'all')) {
                if (data.autocomplete.seminars.seminaritem) {
                    if (!(data.autocomplete.seminars.seminaritem instanceof Array)) {
                        var tempArray = [];
                        tempArray.push(data.autocomplete.seminars.seminaritem);
                        data.autocomplete.seminars.seminaritem = tempArray;
                    }
                    let html_data: string = '';
                    html_data += CaspardoAPI.config.searchslot.html_suggestion_seminar_head;
                    let count = 1;
                    for (let seminar of data.autocomplete.seminars.seminaritem) {
                        if (count <= displaySuggestionDefault) {
                            const url = CaspardoTemplate.config.searchengine_url_target + "?query=&seminartitlehash=" + encodeURI(seminar.titlehash)+"&lang="+CaspardoAPI.defaultLanguage;
                            let insertRow = CaspardoAPI.config.searchslot.html_suggestion_seminar_row;
                            insertRow = insertRow.replace(new RegExp('{url}', 'g'), url);
                            insertRow = insertRow.replace(new RegExp('{text}', 'g'), seminar.highlighting);
                            html_data += insertRow;
                            count++;
                        } else {
                            break
                        }
                    }
                    if (html_data!="")
                        datasourceCounter++;
                    html_array[autocomplete_res_html_order.indexOf('seminars')] = html_data;
                }
            }

            // TODO: Autocomplete Content All needs to be integrated as an new option. [display:allcontent|domaincontent]
            // // Content All Section
            // if (autoCompleteContentType == 'content' || autoCompleteContentType == 'all') {
            //     if (data.autocomplete.contentall.item) {
            //         if (!(data.autocomplete.contentall.item instanceof Array)) {
            //             var tempArray = [];
            //             tempArray.push(data.autocomplete.contentall.item);
            //             data.autocomplete.contentall.item = tempArray;
            //         }
            //         let html_data: string = '';
            //         html_data += CaspardoAPI.config.searchslot.html_suggestion_content_head;
            //         let count = 1;
            //         for (let content of data.autocomplete.contentall.item) {
            //             if (count <= displaySuggestionDefault) {
            //                 let insertRow = CaspardoAPI.config.searchslot.html_suggestion_content_row;
            //                 insertRow = insertRow.replace(new RegExp('{url}', 'g'), content.url);
            //                 insertRow = insertRow.replace(new RegExp('{text}', 'g'), content.title);
            //                 html_data += insertRow;
            //                 count++;
            //             } else {
            //                 break
            //             }
            //         }
            //         if (html_data!="")
            //             datasourceCounter++;
            //         html_array[autocomplete_res_html_order.indexOf('content')] = html_data;
            //     }
            // }

            // Content Datasource Section
            if (autoCompleteContentType == 'content' || autoCompleteContentType == 'all') {
                if (data.autocomplete.content.domain) {
                    
                    if (!(data.autocomplete.content.domain instanceof Array)) {
                        var tempArray = [];
                        tempArray.push(data.autocomplete.content.domain);
                        data.autocomplete.content.domain = tempArray;
                    }

                    let html_data: string = '';
                    // Loop through the content-domains
                    for (let datasource of data.autocomplete.content.domain) {
                       
                        // console.log("Datasource:" + datasource.name);
                        // Datasource Keywords
                        let displayKeywords = false;
                        if ((datasource.keywords) && (displayKeywords)) {
                            // console.log("Keywords:");
                            if (!(datasource.keywords.keyword instanceof Array)) {
                                var tempArray = [];
                                tempArray.push(datasource.keywords.keyword);
                                datasource.keywords.keyword = tempArray;
                            }
                            for (let keyword of datasource.keywords.keyword) {
                                // console.log(keyword);
                            }
                        }

                        // Processing the datasource items
                        if (datasource.item) {

                            // Prepare the items
                            if (!(datasource.item instanceof Array)) {
                                var tempArray = [];
                                tempArray.push(datasource.item);
                                datasource.item = tempArray;
                            }
                            
                            // Init the content head
                            html_data += CaspardoAPI.config.searchslot.html_suggestion_content_head;
                            html_data = html_data.replace(new RegExp('{datasource_name}', 'g'), IN18.IN18TranslateNameByKey(datasource.name));

                            let count = 1;

                            for (let item of datasource.item) {

                                if (count <= displaySuggestionDefault) {
                                    let itemURL:string=item.url;
                                    let insertRow:string="";

                                    // Handling: Youtube + Content
                                    if (itemURL.indexOf("youtube")>=0)
                                    {
                                        // Get the youtube-id
                                        let posDivider:number=itemURL.indexOf(":")+1;
                                        let youtubeID:string=itemURL.substr(posDivider,(itemURL.length-posDivider));
                                        // Init the youtube-row
                                        insertRow = CaspardoAPI.config.searchslot.html_suggestion_youtube_row;
                                        // Replacment
                                        insertRow = insertRow.replace(new RegExp('{id}', 'g'), youtubeID);
                                    } else
                                    {
                                         // Init the content-row
                                        insertRow = CaspardoAPI.config.searchslot.html_suggestion_content_row;
                                        // Replacement
                                        insertRow = insertRow.replace(new RegExp('{url}', 'g'), item.url);
                                    }

                                    // general replacment
                                    insertRow = insertRow.replace(new RegExp('{title}', 'g'), item.title);

                                    html_data += insertRow;
                                    count++;
                                } else {
                                    break
                                }

                            }
                        }
                        if (html_data!="")
                            datasourceCounter++;
                        html_array[autocomplete_res_html_order.indexOf('datasources')] = html_data;
                    }

                }
            }



            // Product Section
            if (autoCompleteContentType == 'products' || autoCompleteContentType == 'all') {
                if (data.autocomplete.products.productnameitem) {
                    //CaspardoLogger.log(data.autocomplete.products.productnameitem);
                    if (!(data.autocomplete.products.productnameitem instanceof Array)) {
                        var tempArray = [];
                        tempArray.push(data.autocomplete.products.productnameitem);
                        data.autocomplete.products.productnameitem = tempArray;
                    }
                    let html_data: string = '';
                    let productHead=CaspardoAPI.config.searchslot.html_suggestion_product_head;
                    productHead = productHead.replace(new RegExp('{name}', 'g'), IN18.IN18TranslateNameByKey("caspardo-suggestions-head-product"));
                    html_data += productHead;

                    let product_count = 1;
                    for (let product of data.autocomplete.products.productnameitem) {
                        if (product_count <= displaySuggestedProducts) {
                            let insertRow = CaspardoAPI.config.searchslot.html_suggestion_product_row;
                            insertRow = insertRow.replace(new RegExp('{url}', 'g'), product.producturltobasket);
                            insertRow = insertRow.replace(new RegExp('{text}', 'g'), product.productnamehighlight);
                            insertRow = insertRow.replace(new RegExp('{product_thumbnail}', 'g'), product.productthumbnail);
                           
                            html_data += insertRow;
                            product_count++;
                        } else {
                            break
                        }
                    }
                    if (html_data!="")
                        datasourceCounter++;
                    html_array[autocomplete_res_html_order.indexOf('shop')] = html_data;
                }
            }

            if (datasourceCounter == 0) {
                jQuery("#caspardo_suggestions").html('<p>Keine Ergebnisse gefunden.</p>');
            }
            else {
                // Loop through the html-autocomplete-elements
                let resHTML:string = "";
                for (let autocomplete_htmlElement of html_array) {
                    if (autocomplete_htmlElement)
                        resHTML += autocomplete_htmlElement;
                }
                // Insert the result
                jQuery("#caspardo_suggestions").html(resHTML);
                CaspardoAutocomplete.showSearchResults();
            }

        })
            .done(function (data) {
            })
            .fail(function () {
                CaspardoLogger.log("error");
            })
            .always(function () {
                CaspardoAutocomplete.setLoadingStatus(false);
                //CaspardoLogger.log("complete");
            });
    }

    public static toRomanNumbers(num) {
        var romanSeries = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1
        },
            roman = '';
        for (var item in romanSeries) {
            while (num >= romanSeries[item]) {
                roman += item;
                num -= romanSeries[item];
            }
        }
        return roman;
    }
}
